export default class StantFormat
  @converteMoedaFloat = (valor) ->
    if valor == ''
      valor = 0
    else
      valor = valor.replace(/\./g, '')
      valor = valor.replace(',', '.')
      valor = valor.replace('R$', '')
      valor = parseFloat(valor)
    valor

  @converteFloatMoeda: (valor) ->
    inteiro = null
    decimal = null
    c = null
    j = null
    aux = new Array
    valor = '' + valor
    c = valor.indexOf('.', 0)
#encontrou o ponto na string
    if c > 0
      #separa as partes em inteiro e decimal
      inteiro = valor.substring(0, c)
      decimal = valor.substring(c + 1, valor.length)
    else
      inteiro = valor
#pega a parte inteiro de 3 em 3 partes
    j = inteiro.length
    c = 0
    while j > 0
      aux[c] = inteiro.substring(j - 3, j)
      j -= 3
      c++
#percorre a string acrescentando os pontos
    inteiro = ''
    c = aux.length - 1
    while c >= 0
      inteiro += aux[c] + '.'
      c--
#retirando o ultimo ponto e finalizando a parte inteiro
    inteiro = inteiro.substring(0, inteiro.length - 1)
    
    if isNaN(decimal) || decimal == null
      decimal = '00'
    else
      decimal = '' + decimal
      if decimal.length == 1
        decimal = decimal + '0'
    valor = 'R$ ' + inteiro + ',' + decimal
    valor

  @convertCurrencyToFloat: (value) ->
    removed_currency = value
    .replace(/[^0-9,-]+/g, '')
    .replace(',', '.')

    return parseFloat(removed_currency)

  window.StantFormat = StantFormat
