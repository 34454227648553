export default class StepChartBarTooltip
  constructor: (@div_chart) ->
    @previous_point = null
    @previous_label = null
    @tooltip_name   = 'step_tooltip'

  apply_tooltip: ->
    add_plothover_listener.call(@)

  # --- Private methods ---

  add_plothover_listener = ->
    self = @
    $(window).bind "plothover", (event, pos, item) ->
      if item
        if (self.previous_label != item.series.label) || (self.previous_point != item.dataIndex)
          tooltip_label = build_tooltip_label.call(self, item)
          show_tooltip(tooltip_label)
      else
        remove_tooltip.call(self)
        self.previous_point = null

  build_tooltip_label = (item) ->
    @previous_point = item.dataIndex
    @previous_label = item.series.label
    remove_tooltip()

    content     = build_tooltip_content(item)
    css_options = build_tooltip_css(item)

    $("<div id='#{@tooltip_name}'> #{content} </div>").css(css_options)


  build_tooltip_content = (item) ->
    x_datapoint = item.datapoint[1]
    tick_label  = item.series.yaxis.ticks[x_datapoint].label
    data_value  = item.datapoint[0]
    "<strong>#{data_value}%</strong>"

  build_tooltip_css = (item) ->
    x     = item.pageX
    y     = item.pageY
    color = item.series.color
    {
      position: 'absolute'
      display: 'none'
      top: y - 8
      left: x - 50
      'font-size': '12px'
      'color': '#ffffff'
      'border-radius': '5px'
      'font-family': 'Verdana, sans-serif'
      opacity: 0.9
    }

  show_tooltip = (tooltip_label) ->
    tooltip_label.appendTo('body').fadeIn 200

  remove_tooltip = ->
    $("##{@tooltip_name}").remove()

