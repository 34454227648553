export default class ExpandCircleIcons
  constructor: (title, label_class) ->
    @title       = title
    @label_class = label_class
    reset_hover(".#{@label_class}")

  add_listeners: () ->
    add_expand_listener.call(@)
    add_click_listener.call(@)

  # Private Methods

  add_expand_listener = ->
    title_label = '<span class="btn-label-in-measure-cell">' + @title + '</span>'

    $('.' + @label_class).hover ->
        $(this).parent().addClass('col-md-4')
        $(this).parent().removeClass('col-md-2')
        $(this).html(title_label)
      , ->
        reset_hover(this)

   add_click_listener = ->
     $('.' + @label_class).click ->
       $(".btn-label-in-measure-cell").hide();

   reset_hover = (selector) ->
      $(selector).parent().removeClass("col-md-4")
      $(selector).parent().addClass('col-md-2')
      $(selector).empty()