import StantMultiselectBox from "../../../utils/stant_multiselect_box.coffee"
import translations from "../../../translations"
import * as I18n from "../../../services/i18n"

export default class MultisiteSection
  handle_events: ->
    @adictional_fields_element = $('#js_additional_fields')

    handler_onchange_multisite_selector.call(@)
    configure_multiselect_users()

  handler_onchange_multisite_selector = ->
    self = @

    $('#checkbox-importance-critical')
      .find('input[type="checkbox"]')
      .prop("disabled", true)
      .end()
      .find('.switch-inner')
      .addClass('disabledswitch')

    importance_div = $('.js-importance-div')
    next_button_step_1 = $("#btn-avancar-step-1")
    previous_button_step_2 = $("#btn-voltar-step-2")

    if $('#checkbox-construction-site-type').is(':checked')
      show_multisite_construction_site_nav_step()
      remove_row_form_to_standard_construction_site()
      hide_additional_fields_from_multisite.call(self) 
    else
      importance_div.hide()
      hide_multisite_construction_site_nav_step()

    $('.check-multisiteconstructionsite')
      .off('checkbox-construction-site-type')
      .on 'change.checkbox-construction-site-type', '#checkbox-construction-site-type', ->
        remove_row_form_to_standard_construction_site()
        hide_additional_fields_from_multisite.call(self)

        if $(@).prop('checked')
          hide_additional_fields_from_multisite.call(self)
          importance_div.show()
          show_multisite_construction_site_nav_step()
          next_button_step_1.add(previous_button_step_2).attr("step", "1.2")
        else
          importance_div.hide()
          next_button_step_1.attr("step", "2")
          previous_button_step_2.attr("step", "1")
          hide_multisite_construction_site_nav_step()
          show_additional_fields_from_multisite.call(self)

    $('.check-multisiteconstructionsite').off('checkbox-importance').on 'change.checkbox-importance', '#checkbox-importance', ->
      checkbox = $(@)
      if !checkbox.prop('checked')
        checkbox.attr('data-confirm', I18n.t(translations(), 'obras.form.confirm_critical'))


  remove_row_form_to_standard_construction_site = () ->
    $('#standard_construction_site_fields, #multisite_construction_site_fields').toggle()
    $('#multisite_construction_site_fields select.select-with-select2').select2({allowClear: true})

  show_multisite_construction_site_nav_step = () ->
    $('#multisite_construction_site_nav_step').show()

  hide_additional_fields_from_multisite = () ->
    @adictional_fields_element.hide()

  show_additional_fields_from_multisite = () ->
    @adictional_fields_element.show()

  hide_multisite_construction_site_nav_step = () ->
    $('#multisite_construction_site_nav_step').hide()

  configure_multiselect_users = () ->
    selectable_header_users  = I18n.t(translations(), 'obras.form.users_ids')
    selection_header_users   = I18n.t(translations(), 'multi_select.selected_items', { item: I18n.t(translations(), 'obras.form.users_ids')})
    custom_multi_select_users = new StantMultiselectBox('construction_site_people_ids', 'construction-site-multi-select', selectable_header_users, selection_header_users)
    custom_multi_select_users.add_listener()