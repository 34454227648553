import 'datatables/media/js/jquery.dataTables'

export default class DataTable
  add: ->
    $('table.custom-datatable').DataTable {
      "columnDefs": [
        { "orderable": 1, "targets": [0,1,3,8] },
        { "orderData": 5,     "targets": 4 },
        { "orderData": 7,     "targets": 6 },
      ],
      destroy: true,
      paging: false,
      searching: false,
      info: false
    }

    $('table.custom-datatable-multisites').DataTable {
      "columnDefs": [
        { "orderable": 1, "targets": [1,2,4,6,7] },
        { "orderData": 4,     "targets": 3 },
        { "orderData": 6,     "targets": 5 },
      ],
      destroy: true,
      paging: false,
      searching: false,
      info: false
    }

    $('table.custom-datatable-multisites').css 'width','100%'

    $('.dataTables_wrapper').removeClass('container-fluid')