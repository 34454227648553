import StantMultiSelectLevels from"../../utils/stant_multiselect_levels.coffee"
import PlanningFormBuilder from "./planning_form_builder"
import StantMasks from "../../utils/stant_masks.coffee"
import Error from "../../utils/error.coffee"
import StantDatepicker from "../../utils/stant_datepicker.coffee"
import translations from "../../translations"
import * as I18n from "../../services/i18n"

export default class Plannings
  @add_listeners: ->
    new StantMultiSelectLevels('places-select-multiselect', 'data-local_id').add_listener()

    @plannings = $('.places-form').data('plannings')
    @step_service_value = $('.places-form').data('step-service-value')

    if @plannings && @plannings.length > 0
      build_edit_form.call(@, @plannings)
    else
      build_new_form.call(@)

  is_multisite = () ->
    return @step_service_value != ''

  build_edit_form = (plannings) ->
    self = @
    generate_planning_rows(plannings, false)
    $('.total_value').maskMoney('mask')
    find_service_detail.call(@)

  build_new_form = ->
    self = @
    if is_multisite.call(self)
      find_service_detail.call(@)
    else
      services_select_listener.call(@)

  find_service_detail = () ->
    self = @
    $(".places-list").show()
    service_id = $('#plannings_servicos_id').val()

    return if service_id == undefined

    url = "/servicos/#{service_id}"
    service_value = 0

    $.ajax url,
      dataType: 'json',
      success: (data, textStatus, jqXHR) ->
        places_select_listener.call(self, data.value, data.measuring_unit, data.service_type)
      ,statusCode:
        403: ->
          $("#add-places").off().on 'click', ->
            new Error.show_alert_flash_msg(I18n.t(translations(), 'flash.not_authorized'))

  generate_planning_rows = (plannings, is_new) ->
    html = ''
    
    for item in plannings
      html += new PlanningFormBuilder().build_form(item, is_new)

    $('.places-form').append(html)

    $('.place-row').each ->
      set_total_value($(this))

    total_value_listeners()
    remaining_quantity_listener()
    StantDatepicker.init()
    new StantMasks().init()

  remaining_quantity_listener = () ->
    $('.place-row').on 'change, keyup', '.quantidade', ->
      remaining_quantity = get_remaining_quantity_value()
      set_remaining_quantity_into_text(remaining_quantity)

  set_remaining_quantity_into_text = (remaining_quantity_value) ->
    $("#remaining-quantity").text(remaining_quantity_value)

  get_remaining_quantity_value = () ->
    total_actual_plannings_quantity_sum = 0.0
    quantity_inputs = $(".quantidade")
    step_total_quantity = parseFloat($('#total-quantity').text())
    $.each quantity_inputs, (i, val) ->
      if(val.value != '' && val.value != null)
        float_value = parseFloat(val.value.replace(/\./g, '').replace(',', '.'))
        total_actual_plannings_quantity_sum = total_actual_plannings_quantity_sum + float_value
    remaining_quantity = step_total_quantity - total_actual_plannings_quantity_sum
    if (remaining_quantity != null)
      return remaining_quantity.toFixed(2)
    else
      return 0

  services_select_listener = () ->
    self = @
    $('#service-select').off().on 'change', ->
      Error.clear_global_errors();

      $('.places-list').show()
      service_id = $('#service-select').find('option:selected').val()

      return if service_id == undefined

      url = "/servicos/#{service_id}"
      $.ajax url,
        dataType: 'json',
        success: (data, textStatus, jqXHR) ->
          places_select_listener.call(self, data.value, data.measuring_unit, data.service_type)
          change_service_info_to_added_places(data.value, data.measuring_unit)
        statusCode:
          403: ->
            $("#add-places").off().on 'click', ->
              new Error.show_alert_flash_msg(I18n.t(translations(), 'flash.not_authorized'))


  places_select_listener = (service_value, measuring_unit, service_type) ->
    self = @
    $("#add-places").off().on 'click', ->
      Error.clear_global_errors()

      selected_places = $('.places-select-multiselect option:selected').map(->
        {id: @value, name: @text}
      ).get()

      plannings = []
      ids = 0
      count = selected_places.length
      if count == 0
        new Error('planning_places_select', I18n.t(translations(), 'atividades.form.error_select_place')).show();

      if is_multisite.call(self)
        service_value = self.step_service_value

      while ids < count
        planning =
          id:                 Date.now() + ids
          valor:              service_value
          dth_fim:            ''
          place_id:           selected_places[ids].id
          dth_inicio:         ''
          quantidade:         if service_type == "standard" then '1,00' else '0,00'
          measuring_unit:     measuring_unit
          place_description:  selected_places[ids].name
          contract_value:     ''
          contract_quantity:  ''

        plannings.push planning
        ids++

      generate_planning_rows(plannings, true)
      remove_new_place_listener()
      reload_places_select()

  remove_new_place_listener = ->
    $('.remove-new-place').off().on 'click', ->
      Error.clear_global_errors()

      $(this).parents('.place-row').fadeOut ->
        $(this).remove()

  reload_places_select = ->
    new StantMultiSelectLevels('places-select-multiselect', 'data-local_id').reset()

  total_value_listeners = ->
    $('.place-row').on 'change, keyup', '.quantidade', ->
      set_total_value($(this).parents('.place-row'))
      $(this).focus()

    $('.place-row').on 'change, keyup', '.valor', ->
      set_total_value($(this).parents('.place-row'))
      $(this).focus()

  set_total_value = (place_row) ->
    quantity = place_row.find('.quantidade').maskMoney('unmasked')[0]
    value = place_row.find('.valor').maskMoney('unmasked')[0]

    place_row.find('.total_value').val((quantity * value).toFixed(2))
    $('.total_value').maskMoney('mask')

  change_service_info_to_added_places = (service_value, service_measuring_unit) ->
    old_service_value = sessionStorage.getItem('service_value')

    $('.valor').each ->
      if $(this).val() == old_service_value
        $(this).val(service_value)

    sessionStorage.setItem('service_value', service_value)

    $('.measuring-unit').text(service_measuring_unit)
