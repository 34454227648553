export default class StepGroupsOrder
  handler_events: ->
    handle_click_save_order_button.call(@)
    StepGroupsOrder.handle_change_order_from_sortable_list()
    StepGroupsOrder.remove_sortable_list_opener()
  handle_click_save_order_button = ->
    that = @
    $('#js_update_step_group').on 'click', ->
      $(this).prop("disabled",true)
      locals_array = $('#js_sortable_list').sortableListsToArray()
      new_orders = []
      for item in locals_array
        parent = if item.parentId == undefined then null else item.parentId
        new_orders.push(
          id: item.id
          group_parent_id: parent
          order: item.order
          name: item.value)
      valid_id = new_orders[0].id
      $.ajax
        type: 'PUT'
        data: { atividade_step_group: { new_orders: new_orders } }
        url: "#{$(@).data('action')}/#{valid_id}.js"
        success: (data) ->

  @handle_change_order_from_sortable_list: ->
    $('#js_sortable_list >.nestable-item .dd-handle').off().on 'mousedown', ->
      $('#js_save_local_order').show()
      $('#js_update_step_group').show()

  @remove_sortable_list_opener: ->
    $('.js-remove-sortable-listener-opener .sortableListsOpener').remove()

window.StepGroupsOrder = StepGroupsOrder