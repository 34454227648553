import "javascripts/jquery-mask/jquery.mask.min";
import "javascripts/jquery-input-mask/jquery.inputmask"
import "javascripts/jquery-input-mask/jquery.inputmask.extensions"
import "javascripts/jquery-input-mask/jquery.inputmask.numeric.extensions"
import "javascripts/jquery-input-mask/jquery.inputmask.date.extensions"
import "jquery-maskmoney/src/jquery.maskMoney"
import StantMasks from "../../../utils/stant_masks.coffee"
import StantMultiselect from "../../../utils/stant_multiselect.coffee"
import AjaxLoader from "../../../utils/ajax_loader.coffee"
import Error from "../../../utils/error.coffee"
import translations from "../../../translations"
import * as I18n from "../../../services/i18n"

$(document).on('turbolinks:load', function(){
  clearLocalStorage();
  enableInput();
  validateImageDimensions();

  const selectProofBodies = '#traceability_traceability_proof_bodies_'
  const container = '.bodyofproof-container'
  let element_item, toggleElementClass;
  new StantMultiselect('traceabilities-multiselect').add_listener();

  $('#new_traceability_traceability').on('submit', function() {
    new AjaxLoader().show();
    return $('.btn-submit').addClass('is-disabled');
  });

  $('.traceability-decimal-mask').maskMoney({allowNegative: false, precision: 1, thousands:'.', decimal:','}); 

  $('.traceability-decimal-mask').each(function(e) {    
    $(this).maskMoney('mask', $(this).val());
  })  

  $('.slump-expected-input').on('keydown', function(event) {
    const key = event.key;
    const arrowKeys = ['ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp']

    if (
      (arrowKeys.includes(key)) ||
      (event.ctrlKey === true || event.altKey === true) ||
      (key === 'Backspace' || key === 'Tab')
    ) {
      return;
    }

    if (!(/^[0-9]$/.test(key))) {
      event.preventDefault();
    }
  })
  
  toggleElementClass = function() {
    $(".list-new-item").toggleClass('hide');
    return $(".list-add-item").toggleClass('hide');
  };

  element_item = $('#traceability_element_name');
  $('.add-item').on('click', function() {
    element_item.val('');
    return toggleElementClass();
  });

  $('.remove-item').on('click', function() {
    element_item.val('');
    return toggleElementClass();
  });

  $('input:checkbox').each(function() {
    if ($(this).prop('checked')) {
      $(`#input-checkbox-${$(this).val()}`).prop('disabled', false);
    } 
  });

  $('.save-item').off().on('click', function() {
    let item_name;
    Error.clear_global_errors();
    item_name = element_item.val();
    return $.ajax({
      type: 'post',
      url: '/elements',
      dataType: "json",
      data: {
        traceability_element: {
          name: item_name
        }
      },
      success: function(element) {
        let li;
        li = `<li class='list-item'> 
                <div class='row'> <div class='col-md-12 list-item-container'> 
                  <div class='col-md-5'> <div class='checkbox checkbox-primary list-item-name'> 
                    <input type='checkbox' name='traceability_traceability[elements_ids][]' id='checkbox-${element.id}' value='${element.id}'/> 
                    <label for='checkbox-${element.id}'>${element.name}</label> </div> </div> <div class='col-md-7'> 
                <div class='input-group'>
                  <input type='text' name='traceability_traceability[concrete_volumes][]' id='input-checkbox-${element.id}' class='list-item-input form-control mask-decimal' placeholder='0,00' disabled='disabled'/> 
                  <span class='input-group-addon'>m<sup>3</sup></span> </div> </div> </div> </div> 
              </li>`;

        $(".listview").append(li);
        toggleElementClass();
        enableInput();
        new StantMasks().init();
        return $('.list-item-placeholder').remove();
      },
      error: function(data) {
        let idClass, msg;
        idClass = "list-new-item";
        msg = data.responseJSON['name'][0];
        return new Error(idClass, msg).show();
      }
    });
  });

  $(`${selectProofBodies} option:selected`).each(function() {
    appendProofBody($(this).val(), container)	
  });

  $(selectProofBodies).change(function() {
    clearProofBodies(); 
    $(`${selectProofBodies} option:selected`).each(function() {
      appendProofBody($(this).val(), container)	
    });
  });

  $(document).on('change', '.proof-body-class', function(event) {
    const value = $(this).val();
    $(`#input-checkbox-${value}`).attr('disabled', !this.checked)
  });

  $(document).on('change', '.body-of-proof-quant', function() {
    setLocalStorage($(this).attr('id'), $(this).val());
  }); 
});

const enableInput = function() {
  $("input:checkbox").off().on('change', function() {
    $(`#input-${this.id}`).attr('disabled', !this.checked);
  });
}

const validateImageDimensions = function() {
  return $('.traceability-register .image-upload').on('change', function(e) {
    let image_input, reader;
    Error.clear_global_errors();
    image_input = e.currentTarget;
    reader = new FileReader;

    if (this.files && this.files[0]) {
      reader.onload = function() {
        let image;
        image = new Image;
        image.onload = function() {
          let error_message, max_height, max_width;
          max_width = parseInt(5000, 10);
          max_height = parseInt(5000, 10);
          if (image.width > max_width || image.height > max_height) {
            error_message = I18n.t(translations(), 'activemodel.errors.models.validators/traceabilities/traceability_validator.attributes.design_image.wrong_image_dimensions', {
              max_width: 5000,
              max_height: 5000
            });
            return new Error('traceability_traceability_design_image', error_message).show();
          }
        };
        return image.src = reader.result;
      };
      return reader.readAsDataURL(this.files[0]);
    }
  });
};

const appendProofBody = function(age, container) {
  
  let value = getProofBodyValue(age);

  const html = `<div class="cell checkbox checkbox-primary proof-body-cell">
              <input type="hidden" name="traceability_traceability[traceabilities_compression_tests][][age]" value="${age}">
              <span class="proof-value" for="checkbox-${ age }">${appendProofBodyPeriod(age)}</span>
              <input type="text" id="proof-value-${age}" value="${ value }" name="traceability_traceability[traceabilities_compression_tests][][body_of_proof_quantity]" class="form-control input body-of-proof-quant" placeholder="0">
             </div>`;

  $(container).append(html);
}

const getProofBodyValue = function(age) {
  let ages_values =  $('.bodyofproof-container').data('values');
  
  if (!ages_values) {
   return getLocalStorage(age);
  }

  let value = ages_values[age] == undefined ? getLocalStorage(age) : ages_values[age]

  return `${ value }`;
}

const clearProofBodies = function() {
  $('.proof-body-cell').each(function() {
    $(this).remove();
  })
}

const appendProofBodyPeriod = function(age) {
 const hours = ['12', '16', '24', '56'];
 let period = I18n.t(translations(), 'traceabilities.traceabilities.form.days')

  if (hours.indexOf(age.toString()) != -1) {
    period = I18n.t(translations(), 'traceabilities.traceabilities.form.hours');
  }

  return `${age} ${period}`
}

const setLocalStorage = function(key, value) {
  localStorage.setItem(key, value);
}

const getLocalStorage = function(key) {
  const value = localStorage.getItem(`proof-value-${key}`);
  return value == null ? 0 : value
}

const clearLocalStorage = function() {
  localStorage.clear();
}
