import StantMultiselect from "../../../utils/stant_multiselect.coffee"
import AjaxLoader from "../../../utils/ajax_loader.coffee"

$(document).on 'turbolinks:load', ->
  new StantMultiselect('report-non-conformity-multiselect').add_listener()

  $('#show-filters').on 'click', (e) ->
    e.preventDefault()
    $('div.other-filters').toggleClass('hide');
    $('#more-filter-icon').toggleClass('hide');
    $('#less-filter-icon').toggleClass('hide');

  $('.checkbox-all-construction-occurrence, .checkbox-all-construction-fvs').on 'click', ->
    $(".#{@.dataset.period}").toggleClass 'is-disabled'
    $(".#{@.dataset.period}.is-disabled .mask-date-field").val('')

  $('#request_form_fvs, #request_form_occurrence').on 'submit', ->
    new AjaxLoader().show()
    $('.btn-submit').addClass 'is-disabled'

  $('a[data-toggle="tab"]').on 'shown.bs.tab', (e) ->
    $('a[data-toggle="tab"]').removeClass('active')
    $(@).addClass('active')
    
    $('.pdf-button').hide()

    if e.target.id == 'fvs-tab'
      $('.fvs-pdf-button').show()
    else
      $('.occurrence-pdf-button').show()
