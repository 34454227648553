import { Controller } from "stimulus"
import translations from "../../translations"
import * as I18n from "../../services/i18n"

export default class extends Controller {
  static targets = [
    'jsCommentTemplate',
    'jsCommentsContainer',
    'jsCommentField',
    'jsCommentForm',
    'jsCommentCountIndicator'
  ]
  
  initialize() {
    setTimeout(() => {
      $(document).on('show.bs.modal', '#js_new_preplanning_comment_modal', () => {
        Error.clear_global_errors()

        this.mount_comments()
        this.jsCommentFieldTarget.focus()
      })
    }, 1)
  }

  mount_comments () {
    const element = $(this.element)
    const commentsList = element.data('comments')
    
    $(this.jsCommentsContainerTarget).html('')
    $('.comment-preplanningform').attr('action', element.data('saveCommentUrl'))
    
    if(!commentsList || !commentsList.length) return null

    let commentHtml = ''

    commentsList.forEach((comment) => {
      commentHtml += this.build_comment(this.jsCommentTemplateTarget, comment)
    })

    $(this.jsCommentsContainerTarget).html(commentHtml)
  }

  handle_validate_fields (event) {
    const commentField = $(this.jsCommentFieldTarget)

    Error.clear_global_errors()

    if(!$(commentField).val().length){
      event.preventDefault()
      this.add_error_to_input(commentField)
    }
  }

  handle_add_new_comment_item (event) {
    const [response] = event.detail
    const comment = this.build_comment(this.jsCommentTemplateTarget, response)
    
    $(this.jsCommentsContainerTarget).prepend(comment)
    
    $(this.jsCommentFieldTarget).val('')
    this.jsCommentFieldTarget.style.border = ''

    $(this.jsCommentCountIndicatorTarget).html('0')
  }

  handle_request_error (event) {
    const [response] = event.detail

    Error.show_alert_flash_msg(response.error_message)
  }

  handle_user_typing () {
    if (this.jsCommentFieldTarget.value.length) {
      this.jsCommentFieldTarget.style.border = '1px solid green'
      Error.clear_global_errors()
      $(this.jsCommentCountIndicatorTarget)
        .html(this.jsCommentFieldTarget.value.length)
    } else {
      $(this.jsCommentCountIndicatorTarget)
        .html(this.jsCommentFieldTarget.value.length)

      this.add_error_to_input($(this.jsCommentFieldTarget))
    }
  }

  handle_keydown_comment_field (event) {
    if (event.key === 'Enter'){
      event.preventDefault()
      
      Rails.fire(this.jsCommentFormTarget, 'submit')
    }
  }

  add_error_to_input (field) {
    const message = I18n.t(translations(), 'errors.messages.blank')
    const html = `<p class='p-error'>${message}</p>`
    
    field.parent().append(html)
    field.addClass("error-input")
  }

  build_comment (template, data) {
    let commentHtml = template.innerHTML.replace('%{person_name}', data.person_name)
    commentHtml = commentHtml.replace('%{description}', data.description)
    commentHtml = commentHtml.replace(/%{created_at}/g, data.created_at)
    
    return commentHtml
  }
}