import L from 'leaflet'

$(document).on('turbolinks:load', function () {
  let fields = $('.map-div');

  if (fields.length > 0) {

    fields.each(function(field, index) {
      let values = String($(this).data('value')).split(',');
      let divId = `map-${$(this).data('id')}`;
      let container = L.DomUtil.get(divId);

      if(container != null){
        container._leaflet_id = null;
      }

      let latitude = values[0]
      let longitude = values[1]

      try {
        var map = L.map(divId, { zoomControl: false }).setView({lon: longitude, lat: latitude}, 16);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
        }).addTo(map)

        delete L.Icon.Default.prototype._getIconUrl;

        L.Icon.Default.mergeOptions({
          iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
          iconUrl: require('leaflet/dist/images/marker-icon.png'),
          shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        });

        L.control.scale({ metric: false, imperial: false }).addTo(map);
        L.marker({lon: longitude, lat: latitude}).addTo(map);    
      } catch(e) {
        $(`#map-${$(this).data('id')}`).remove();
      }
    });
  }
});
