import Error from "../../utils/error.coffee"
import AjaxLoader from "../../utils/ajax_loader.coffee"
import translations from "../../translations"
import * as I18n from "../../services/i18n"

export default class PlacesCreation
  selected_file = false

  handler_events: ->
    handle_construction_site_select_before_company()
    handler_company_select_change()
    handler_consturction_site_select_change()
    handle_change_input_file_name()
    handle_create_button_enable()

  handle_create_button_enable = ->
    construction_site = $('.js_construction_site_select').find('option:selected').val()

    if construction_site != '' && selected_file
      $('.js-place-save').attr('disabled', false)
    else
      $('.js-place-save').attr('disabled', 'disabled')
  
  handle_construction_site_select_before_company = ->
    $('.js_construction_site_select').find('.select2-choice').on "mousedown", () ->
      if $('.js_company_select').find('option:selected').val() == ''
        $('.no-company-selected').show()
  
  handle_change_input_file_name = ->
    file_input = $('.btn-upload-places-file-input')
    file_label = file_input.next()

    file_input.on 'change', (e) ->
      file_name = e.target.value.split( "\\" ).pop();

      if file_name == ''
        file_label.find('span').html(I18n.t(translations(), 'places.form.upload_model'))
        selected_file = false
      else
        file_label.find('span').html(truncate(file_name, 30))
        selected_file = true
      
      handle_create_button_enable()

  truncate = (source, size) ->
    if source.length > size
      return source.slice(0, size - 1) + "…"
    else
      return source

  show_errors = (errors) ->
    new AjaxLoader('step-group-save').hide()
    Error.clear_global_errors()
    fail_msg = I18n.t(translations(), 'flash.actions.create.alert',
                              resource_name: I18n.t(translations(), 'activerecord.models.step_group.one'))
    Error.show_alert_flash_msg(fail_msg)

    for key of errors
      idClass= "atividade_step_group_#{key}"
      msg = errors[key][0]
      new Error(idClass, msg).show()

  handler_company_select_change = ->
    $('.js_company_select').on 'change', (e, company_id_for_select) ->
      option = $(@).find('option:selected')
      $('.no-company-selected').hide()

      unless option.val()
        $('.construction_site_id abbr').hide()
        change_options_from_company_selection([], company_id_for_select, true)
        return

      config_load_select_show()
      $('.construction_site_id abbr').show() if option.get(0)

      $.ajax {
        type: 'GET',
        dataType: 'json',
        data: { id: option.val() },
        url: '/places/search.json',
        success: (construction_sites) -> change_options_from_company_selection(construction_sites, company_id_for_select, option.val())
      }

  handler_consturction_site_select_change = ->
    $('.js_construction_site_select').on 'change', () ->
      handle_create_button_enable()

  change_options_from_company_selection = (construction_sites, construction_site_id_for_select, keep_disabled) ->
    config_load_select_hide(keep_disabled)
    default_option = I18n.t(translations(), 'helpers.select.placeholder')
    html = "<option value=''> #{default_option} </option>"
    for construction_site in construction_sites
      html += "<option value=#{construction_site.id}> #{construction_site.titulo} </option>"

    $('.js_construction_site_select').last().empty().append(html)
    $('.js_construction_site_select').last().val(construction_site_id_for_select) if construction_site_id_for_select

  config_load_select_show = ->
    time_for_resize_width_run_on_browser = 200
    $('.js_construction_site_select').attr('disabled', 'disabled')
    setTimeout ( ->
      new AjaxLoader().show()
    ), time_for_resize_width_run_on_browser

  config_load_select_hide = (keep_disabled) ->
    time_for_smoth_animation = 300
    setTimeout ( ->
      new AjaxLoader().hide()
      $('.js_construction_site_select').attr('disabled', if !parseInt(keep_disabled) then 'disabled' else false)
    ), time_for_smoth_animation

  #------------------helpers ----#
  clear_input = ->
    $('#atividade_step_group_name').val('')
