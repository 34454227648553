import Chart from "chart.js";
import * as echarts from 'echarts';
import * as I18n from "../../../services/i18n";
import translations from "../../../translations";

import {
  formatLabel,
  isHeight,
  resizeChart,
  handleScrollAndDisplay,
  handleScale,
} from "./helpers/stepProgressHelpers";

export default class StepProgressChart {
  static render() {
    const chartCanvas = $("#js-steps-chart");
    const chartContainer = $(".steps-chart-container");
    const chartScales = $(".scales-chart");
    const toogleBtn = $("#toogle-btn");

    if (chartCanvas.length !== 0) {
      const labels = chartCanvas.data("steps-response-labels").reverse();
      const data = chartCanvas.data("steps-response-series").reverse();

      const echartsDom = document.getElementById('js-steps-chart');

      const echart = echarts.init(echartsDom);

      const buildOptions  = (type) => {
        return {
          xAxis: {
            type: type === 'horizontal' ? 'value' : 'category',
            data: labels,

            axisLabel: {
              interval: 0,
              rotate: 30 //If the label names are too long you can manage this by rotating the label.
            }
          },
          tooltip: {
            trigger: "item",
            formatter: function (params) {
              return `${params.name}<br />
              Progresso: ${params.value}%`;
            }
          },
          yAxis: {
            type: type === 'vertical' ? 'value' : 'category',
            data: labels,
            axisLabel: {
              interval: 0
            }
          },
          series: [
            {
              data: data,
              color: 'red',
              type: 'bar'
            }
          ]
        }
      }

      let chartType = 'horizontal';

      echart.setOption(buildOptions(chartType));

      toogleBtn.on("click", function () {
        chartType = chartType === 'horizontal' ? 'vertical' : 'horizontal';

        echart.setOption(buildOptions(chartType));
      });

    }
  }
}
