window.FvsServiceVerificationShowSummary = class FvsServiceVerificationShowSummary

  @show_summary = (hash_total) ->
    $('#fvs_service_total_fill').text hash_total.siff_total
    $('#fvs_service_doing').text hash_total.siff_doing
    $('#fvs_service_done').text hash_total.siff_done
    $('#fvs_service_approved').text hash_total.siff_approved
    $('#fvs_service_total_inapplicale').text hash_total.inspection_not_applicable
    $('#fvs_service_total_disapproved').text hash_total.inspection_disapproved
    $('#fvs_service_total_approved').text hash_total.inspection_approved
    $('#fvs_service_total_not_inspectable').text hash_total.not_inspected

    $("#fvs_service_total_items").text hash_total.inspection_not_applicable + hash_total.inspection_disapproved + hash_total.inspection_approved + hash_total.not_inspected
    $('#fvs_service_total_errors').text hash_total.inspection_errors
    $('#fvs_service_total_residues').text(hash_total.residue.toFixed(1) + " m³")

