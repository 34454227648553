import Error from "../../utils/error.coffee"
import AjaxLoader from "../../utils/ajax_loader.coffee"
import translations from "../../translations"
import * as I18n from "../../services/i18n"

export default class ServicesCreation
  selected_file = false

  handler_events: ->
    handler_company_select_change()
    handle_create_button_enable()
    handle_change_input_file_name()
    handle_click_create_button()

  handler_company_select_change = ->
    $('.services_company_select').on 'change', () ->
      handle_create_button_enable()
  
  handle_change_input_file_name = ->
    file_input = $('.btn-upload-services-file-input')
    file_label = file_input.next()

    file_input.on 'change', (e) ->
      file_name = e.target.value.split( "\\" ).pop();

      if file_name == ''
        file_label.find('span').html(I18n.t(translations(), 'places.form.upload_model'))
        selected_file = false
      else
        file_label.find('span').html(truncate(file_name, 30))
        selected_file = true
      
      handle_create_button_enable()

  handle_create_button_enable = ->
    company = $('.services_company_select').find('option:selected').val()
    if company != '' && selected_file
      $('.js-service-save').attr('disabled', false)
    else      
      $('.js-service-save').attr('disabled', 'disabled')
  
  handle_click_create_button = ->
    $(document).on 'click', '.js-service-save', (e) ->
      time_for_resize_width_run_on_browser = 200
      setTimeout ( ->
        new AjaxLoader().show()
      ), time_for_resize_width_run_on_browser
    

  truncate = (source, size) ->
    if source.length > size
      return source.slice(0, size - 1) + "…"
    else
      return source