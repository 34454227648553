import DataTable from './datatable.coffee'
import Steps from "./steps.coffee"
import MultisitesSteps from "./multisites/multisites_steps.coffee"
import PlanningValidation from "./multisites/planning_validation.coffee"
import PlanningSiffs from "./multisites/planning_siffs.coffee"
import EditPrePlanning from "./multisites/edit_pre_planning.coffee"
import PlanningHistory from "./multisites/planning_history.coffee"
import StepHistory from "./step_history.coffee"
import BuildSteps from "../../../utils/build_steps.coffee"
import StepGroupsCreation from "./step_group_creation.coffee"

$(document).on 'turbolinks:load', ->
  new Steps.add_listeners()
  new MultisitesSteps().handler_events()
  new PlanningValidation().handler_events()
  new EditPrePlanning().handler_events()
  new PlanningHistory().handler_events()
  new PlanningSiffs().handler_events()
  new StepHistory().handler_events()
  new BuildSteps('js-step', []).handler_events()
  new StepGroupsCreation().handler_events()
  new DataTable().add()
