export default class FilterFinanceAndFisicalExcel {
  static handler_events () {
    this.handle_on_click_generate_button()
    this.handle_on_change_year_select()
  }

  static handle_on_click_generate_button () {
    $('#construction_sites_report').on('click', (e) => {
      e.stopPropagation()
    })
  }

  static handle_on_change_year_select () {
    $('#construction_sites_report_select').on('change', (e) => {
      const select_changed = $(e.target)
      const link_name = select_changed.data('linkName')
      const selected_year = select_changed.val()
      
      if(selected_year) {
        window.location = `${link_name}?year=${selected_year}`
      }
    })
  }
}