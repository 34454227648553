import translations from "../../../translations"
import * as I18n from "../../../services/i18n"


export function switch_component(){
  return `
  <div class="col-md-3" id="switch-checkbox-dashboard-start-date">
    <label class="label" for="security_options_${I18n.t(translations(), 'empresas.edit_security_options.construction_site_date_filter.start_date')}">
      ${I18n.t(translations(), 'empresas.edit_security_options.construction_site_date_filter.start_date')}
    </label>
    <div class="switch">
      <input name="security_options[start_date][value]" type="hidden" value="0" autocomplete="new-password"><input class="switch-checkbox form-control" id="switch-checkbox-dashboard-14" type="checkbox" value="1" checked="checked" name="security_options[start_date][value]" autocomplete="new-password">
     <label for="switch-checkbox-dashboard-14" class="switch-label switch-checkbox-dashboard-14">
        <span class="switch-inner"></span>
        <span class="switch-circle"></span>
      </label>
    </div>
  </div>
  `
} 