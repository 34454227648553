export default class FvsServiceVerificationSummarySessionStorage
  item = 'fvs_service_verification_summary'
  summary = (hash_total) ->
    total_errors: hash_total.total_errors || 0
    total_inapplicale: hash_total.total_inapplicale || 0
    total_disapproved: hash_total.total_disapproved || 0
    total_approved: hash_total.total_approved || 0
    total_not_inspectable: hash_total.total_not_inspectable || 0
    total_residues: hash_total.total_residues || 0
    total_situation: hash_total.total_situation || {0:0, 1:0, 2:0}

  @save_summary: (hash_total) ->
    sessionStorage.setItem item, JSON.stringify summary hash_total

  @get_summary: ->
    result =  JSON.parse sessionStorage.getItem item
    return summary {} unless result
    result

  @clear_summary: ->
    sessionStorage.removeItem(item)

window.FvsServiceVerificationSummarySessionStorage = FvsServiceVerificationSummarySessionStorage