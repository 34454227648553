import CountFieldCharacters from '../../utils/count_field_characters'
import VerifiedMethodUpdater from './services/verified_method_updater'
import inspectionCardHandler from './inspection_card_handler'
import { requestUpdateModal, defineFlashMessage } from './utils/helpers'
import StantMultiselect from"../../utils/stant_multiselect.coffee"

import swal from 'sweetalert2'
import translations from 'translations'
import * as I18n from 'services/i18n'

$(document).on("turbolinks:load", () => {
  new inspectionCardHandler().init()
  new StantMultiselect('fvs-multiselect').add_listener()
  new StantMultiselect('fvs-manageable-services-multiselect', true, true).add_listener()

  new CountFieldCharacters().handler_events(
    '.js-inspection-item-response-field',
    '.js-inspection-item-response-field-count',
    '.js-inspection-item-response-field-indicator',
    true
  );

  // TODO: Extract to handler
  $(document).on('change', '.inspection-form .severity-level-selector .selector', (event) => {
    const selectedSeverity = event.target.value
    $(event.target).siblings('.draw').html(buildSeverityLevelDraw(selectedSeverity))
  })

  // TODO: Extract to handler
  $(document).on('click', '.update-verified-method', (event) => {
    const { action } = event.target.dataset

    showConfirmationDialog(action).then(willUpdate => {
      if (willUpdate.value) {
        const token = document.querySelector('input[name="request[user_token]"]').value
        const verifiedMethodUpdater = new VerifiedMethodUpdater(token)
        new AjaxLoader().show();
        verifiedMethodUpdater.update(event.target)
          .then(response => {
            const itemAndMethodIndex = $(event.target).parent().attr('data-method-index')
            requestUpdateModal(itemAndMethodIndex)
            new StantFlashMessages(defineFlashMessage('success', action)).render_floating();
          })
          .catch(error => {
            new StantFlashMessages(defineFlashMessage('error', action)).render_floating();
          })
      }
    })
  })

  $(document).on('click', '.new-inspection-button', (event) => {
    const button = $(event.target)
    const modalTarget = $(button.data('modalTarget'))
    const methodDescription = button.data('methodDescription')
    const methodGuid = button.data('methodGuid')
    const inspectionParentGuid = button.data('inspectionParentGuid')
    const dataMethodIndex = button.parent().data('methodIndex')
    const severityLevel = $('.inspection-form .severity-level-selector .selector')

    const $form = modalTarget.find('.inspection-form')
    const formElement = modalTarget.find('.inspection-form')[0]
    const dynamicForm = modalTarget.find('.inspection-dynamic-form')

    severityLevel.siblings('.draw').html(buildSeverityLevelDraw(1))

    if(formElement) formElement.reset()
    dynamicForm.find('input:text, input[type=number], textarea').val('')
    dynamicForm.find('.selection-question.select-form').select2("val", "")
    dynamicForm.find('.multiple-question').multiSelect("deselect_all")

    const placeholder = `${I18n.t(translations(), 'fvs_service_verification.index.default_select_text')}`
    dynamicForm.find('.ms-options-wrap button').text(placeholder)


    modalTarget.find('.inspection-images-preview').empty()
    modalTarget.find('.signature-image-container').empty()

    modalTarget.find('.images-info, .images-info-signature').addClass('hide')
    modalTarget.find('.images-info-no-signature').removeClass('hide')

    modalTarget.find('.modal-title h3').text(methodDescription)
    modalTarget.find(`#${modalTarget.attr('id')}-verified-method-guid`).val(methodGuid)
    modalTarget.find(`#${modalTarget.attr('id')}-verified-method-guid`).val(methodGuid)
    modalTarget.find(`#${modalTarget.attr('id')}-inspection-parent-guid`).val(inspectionParentGuid)
    $($form).attr('data-method-index', dataMethodIndex)

    initValidateGeolocalization(modalTarget)

    modalTarget.modal('show')
  })
})

function buildSeverityLevelDraw(selectedSeverity) {
  const colors = { 1: 'yellow', 2: 'yellow', 3: 'orange', 4: 'orange', 5: 'red' }

  let draw = ''
  Object.entries(colors).forEach((_, index) => {
    let color = index + 1 > selectedSeverity ? 'grey' : colors[selectedSeverity]
    draw += `<span class='nivel-circle circle-${color}'></span>&nbsp;`
  })

  return draw
}

const showConfirmationDialog = (action) => {
  const messagesBasepath = 'fvs_preenchimentos.components.item_details_modal.table.actions_dropdown'

  return swal({
    title: I18n.t(translations(), `${messagesBasepath}.${action}_confirm_title`),
    html: I18n.t(translations(), `${messagesBasepath}.${action}_confirm`),
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: I18n.t(translations(), 'form.confirm_yes'),
    cancelButtonText: I18n.t(translations(), 'form.confirm_no'),
  })
}

const initValidateGeolocalization = (modalTarget) => {
  const GeolocalizationInputs = $(
    modalTarget
    .find('[data-dynamic-field-type="geolocalization"]')
    .find('input')
  )

  GeolocalizationInputs.on('keyup', function(){
    //Regex to filter letters and only accept numbers, '-' and '.'
    const lettersFilter = /[^\d.-]/

    this.value = this.value.replace(lettersFilter, '')
  })
}
