import AjaxLoader from "../../../utils/ajax_loader.coffee"
import translations from "../../../translations"
import * as I18n from "../../../services/i18n"

export default class Steps
  @add_listeners: ->
    $('.approve-plannings').hide()
    $('.approve-plannings-checkbox-div').hide()
    $('.btn.new-step').off().on 'click', ->
      Error.clear_global_errors()
      $('#js_create_group').hide()
      form = $('#form_step_lightbox form')[0]
      clean_inputs()
      form.action = "/plannings/steps"
      form.method = "POST"
      $('#js_input_method_container').html('')
      modal_title = $('#form_step_lightbox .modal-title-small')[0]
      modal_title.textContent = I18n.t(translations(), 'plannings.steps.form.title')

    $('.edit-step').off().on 'click', ->
      Error.clear_global_errors()
      form = $('#form_step_lightbox form')[0]
      clean_inputs()
      step_data = $(@).data('step')
      modal_title = $('#form_step_lightbox .modal-title-small')[0]
      modal_title.textContent = I18n.t(translations(), 'plannings.steps.form.edit_title')
      form.action = "/plannings/steps/#{step_data.id}"
      form.method = "POST"
      $('#js_input_method_container').html('<input type="hidden" name="_method" value="PUT">')
      fill_fields(step_data)
      select_right_option(step_data)
      toggle_create_micro()

    $('.js-btn-approve-step-plannings').off().on 'click', ->
      button_id = $(this)[0].id
      step_id = button_id.slice(button_id.lastIndexOf("-") + 1)
      $('#js-approve-plannings-div-' + step_id).toggle()
      $('.js-approve-plannings-checkbox-div-' + step_id).toggle()
      $('#js-select-all-plannings-checkbox-' + step_id).on 'change', ->
        checked = $(this).prop('checked')
        text = if checked then I18n.t(translations(), 'plannings.steps.index.unselect_all_plannings') \
          else I18n.t(translations(), 'plannings.steps.index.select_all_plannings')
        $('#js-select-all-plannings-text-' + step_id).text(text)
        $('.js-approve-plannings-checkbox-div-' + step_id + ' input[type="checkbox"]').prop('checked', checked)

    fill_fields = (step_data) ->
      $('#js_planning_step_name').val(step_data.name)
      $('#js_planning_step_start_at').val(step_data.start_at)
      $('#js_planning_step_finish_at').val(step_data.finish_at)
      $('#js_planning_step_observation').val(step_data.observation)

    select_right_option = (step_data) ->
      $('#js_planning_step_responsible_company_id').val(step_data.responsible_company_id)
      $('.js_macro_select').val(step_data.macro_id)
      $('.js_macro_select').trigger 'change', [step_data.group_id]

    toggle_create_micro = ->
      $('#js_create_group').hide() unless $('.js_macro_select').val()
      $('#js_create_group').show() if $('.js_macro_select').val()

    $('#js_atividade_step_form').on 'ajax:send', ->
      $('button.js-step-save').attr('disabled', true)
      new AjaxLoader('loader-spinner').hide()
      new AjaxLoader('step-save').show()

    $('#js_atividade_step_form').on 'ajax:complete', (event) ->
      [xhr, status] = event.detail
      unless xhr.responseText.substring(0, 15) == 'window.location'
        $('button.js-step-save').attr('disabled', false)
        new AjaxLoader('step-save').hide()

  @save_step: (id) ->
    Error.clear_global_errors()

    $("#step-form-#{id} #atividade_step_name").prop('disabled', true)
    toggle_elements(id)

  toggle_elements = (id) ->
    $("#step-form-#{id} #atividade_step_name").focus()
    $("#step-options-#{id}").toggle()
    $("#step-form-options-#{id}").toggle()

  clean_inputs = ->
    $('#js_planning_step_name').val('')
    $('#js_planning_step_start_at').val('')
    $('#js_planning_step_finish_at').val('')
    $('#js_planning_step_observation').val('')
    $('.js_macro_select').val('')
    $('.js_group_select').val('')
    $('#js_planning_step_responsible_company_id').val('')
