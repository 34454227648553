import PermissionTrBuilder from "./permission_tr_builder.coffee"
import StantMultiselect from "../../utils/stant_multiselect.coffee"

export default class DocumentsDataPermissions
  @removeNewUserPermissions: (element) ->
    if element.length > 0
      selectUserEmail = $('select.user_email')
      form = selectUserEmail.parents('form')
      userInput = element.find('.user_id')
      userId = userInput.val()
      userValue = userInput.parent().text()

      element.remove()
      selectUserEmail.find('option[data-removed="true"]').remove()
      selectUserEmail.prepend('<option value="' + userId + '">' + userValue + '</option>')
      selectUserEmail.multiselect('reload')
      new StantMultiselect('user_email', false).add_listener()

  @update_sharing_email_list: (form) ->
    multiselect = form.find('.user_email')
    multiselect.multiselect('reload')
    new StantMultiselect('user_email', false).add_listener()
    msOptionsWrap = form.find('.ms-options-wrap')
    msOptions = msOptionsWrap.find('.ms-options li')
    selectedUsersValues = form.find(".selected-users tr[id^='permission-'] .user_id")
    valuesSelected = []

    for value in selectedUsersValues
      valuesSelected.push(value.value)

    for option in msOptions
      msOptionEmail = option.closest('li')
      input = $(option).find('input')
      inputValue = input.attr('value')
      optionMultiselect = multiselect.find("option[value='#{inputValue}']")
      if !(inputValue in valuesSelected)
        input.prop('checked', true).click()
        $(msOptionEmail).removeClass('selected')
        $(msOptionEmail).show()
        optionMultiselect.removeAttr('data-removed', 'true')
      else
        optionMultiselect.attr('data-removed', 'true')
        $(msOptionEmail).addClass('selected')
        $(msOptionEmail).hide()

    msOptionsWrap.trigger('initialize')

  @add_listeners: ->
    share_modal_opener()

    select_all_listener()

    user_search_listener()

  user_search_listener = ->
    selectUserEmail = $('.user_email')
    selectUserEmail.off().on 'keypress', ->
      $('.dropdown-results').parent().removeClass 'open'
    typingTimer = 0;
    doneTypingInterval = 500;
    selectUserEmail.on 'keyup', ->
      parent = $(this).parents('.data-permission')
      parent.find('.users-dropdown').html('')
      search = $(this).val()
      clearTimeout(typingTimer);
      if search.length >= 3
        typingTimer = setTimeout((->
          search_users(parent, search)
          return
        ), doneTypingInterval)

    selectUserEmail.off('.change_user_email').on 'change.change_user_email', ->
      current = $(this)
      form = current.parents('form')
      msOptionsWrap = form.find('.ms-options-wrap')
      selectedEl = msOptionsWrap.find('.selected:not(:hidden)').find('input')
      selectedUsersEl = form.find('.selected-users')

      elementId = selectedEl.val()
      elementLogin = selectedEl.parent().text()

      if selectedUsersEl.find('.user_id[value="'+elementId+'"]').length == 0
        tr_html = new PermissionTrBuilder().build_tr('', {id: elementId, email: elementLogin}, true)
        selectedUsersEl.find('tr:first-child').after(tr_html)

      selectUserEmail.find('option[value="'+elementId+'"]').attr('data-removed', 'true')
      selectedEl.parents('li').hide()


    $('.selected-users').off('.remove_new').on 'click.remove_new', '.remove-new:not([data-confirm])', ->
      DocumentsDataPermissions.removeNewUserPermissions($(this).parents('tr'))

  search_users = (parent, search) ->
    $.ajax
      type: 'POST'
      url: "/usuarios/search.js"
      data: { search: { email_or_login: search } }
      success: (result) ->
        success_search(parent, result)

  success_search = (parent, result) ->
    ids = []
    for it in parent.find('.selected-users tr .user_id')
      ids.push parseInt($(it).val())

    for item in result
      id = item.id
      continue unless ids.indexOf(id) == -1
      email = unless item.email == '' then item.email else item.login

      item_html = "<li><a href='javascript:void(0)' class='btn user-result' id='#{id}'>#{email}</a></li>"
      parent.find('.users-dropdown').append(item_html)

    parent.find('.dropdown-results').dropdown('toggle')

    select_all_listener()

    user_result_listener(parent)

  select_all_listener = ->
    $('.select-all').off().on 'change', ->
      permission = $(this).attr('id')

      if $(this).find('input').is(':checked')
        elements = $('.selected-users').find(".td-#{permission}")
        for el in elements
          $(el).prop('checked', true) unless $(el).is(':disabled')
      else
        elements = $('.selected-users').find(".td-#{permission}")
        for el in elements
          $(el).prop('checked', false) unless $(el).is(':disabled')

  user_result_listener = (parent) ->
    $('.user-result').off().on 'click', ->
      id = $(this).attr('id')
      email = $(this).text()

      tr_html = new PermissionTrBuilder().build_tr(parent.attr('id'), {id: id, email: email}, true)

      parent.find('.selected-users tr').first().after(tr_html)
      parent.find('.user_email').val('')

      $('.remove-new').on 'click', ->
        if $(this).hasClass('remove-new')
          $(this).parents('tr').remove()

  share_modal_opener = ->
    $('.open-data-permission').on 'click', ->
      id = $(this).data('datable')
      permissions = $("#data-permission-lightbox-#{id}").data('permissions')

      DocumentsDataPermissions.update_sharing_email_list( $("#data-permission-lightbox-#{id}"))

      html = ""
      for item in permissions
        html += new PermissionTrBuilder().build_tr(id, item)

      $("#data-permission-lightbox-#{id}").find('.selected-users').append((html))
      $("#data-permission-lightbox-#{id}").modal('show')

window.DocumentsDataPermissions = DocumentsDataPermissions