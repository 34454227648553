import OccurrenceCreate from './occurrence_create.coffee'
import ImageUploadBase from './image_upload_base'
import CountFieldCharacters from './count_field_characters'
import OccurrenceFileUploadBase from './document_upload_base'
import { set_description_with_suggested, set_description_with_suggested_page_render } from './helpers'

$(document).on('turbolinks:load', function () {
  new CountFieldCharacters().handler_events();
  new OccurrenceCreate().handler_events();

  new OccurrenceFileUploadBase(
    ".js-occurrence-photos-dropzone",
    '.js-dropzone-preview-container-occurrence',
    '.js-dropzone-document-preview-container-occurrence',
    '#js-upload-button-occurrence',
    '#js-dropzone-preview-template-occurrence',
    'occurrence[files][]'
  ).call()

  new OccurrenceFileUploadBase(
    ".js-occurrence-action-photos-dropzone",
    '.js-dropzone-preview-container-action',
    '.js-dropzone-document-preview-container-action',
    '#js-upload-button-action',
    '#js-dropzone-preview-template-occurrence',
    'occurrence_action[files][]'
  ).call()

  set_description_with_suggested_page_render();

  $('#js_occurrence_type_id').on('change', function () {
    let suggested_severity_level = $('option:selected', this).attr('data-suggested_severity_level');
    let js_occurrence_severity_level_select = $('#js_occurrence_severity_level option:selected');
    let suggested_description = $('option:selected', this).attr('data-suggested_description');
    let js_occurrence_description_input = $('#js_occurrence_description');

    set_description_with_suggested(suggested_description, js_occurrence_description_input);
  })
})
