import RenderPlannings from "./render_plannings.coffee"
import ContractsHelpers from "./contracts_helpers.coffee"
import AjaxLoader from "../../utils/ajax_loader.coffee"
import StantMasks from "../../utils/stant_masks.coffee"
import StantFormat from "../../utils/stant_formt.coffee"
import FixedCostContract from "./fixed_cost_contract.coffee"
import translations from "../../translations"
import * as I18n from "../../services/i18n"

export default class ServiceContract
  constructor: ->
    @fixed_costs = new FixedCostContract()
    @start_handlers()
    load_selected_plannings()
    @click_in_service()

  get_plannings: (current_service) ->
    that = @
    plannings = current_service.data('plannings')
    selected_plannings = current_service.data('plannings-selecteds') || {}
    if plannings
      @render plannings
    else
      $.ajax {
        type: "GET",
        dataType: "json",
        data: {service_id: current_service.data('id'), step_id: current_service.data('step-id')},
        url: "/contracts/get_plannings_by_service",
        success: (data) ->
          $.merge(data.plannings, selected_plannings)
          current_service.data('plannings',data.plannings)
          that.render data.plannings
      }

  render: (plannings) ->
    render_plannings = new RenderPlannings(plannings)
    render_plannings.show_planning_cell()
    @start_handlers()


  start_handlers: () ->
    @click_in_planning_cell()
    @fixed_costs.start_handlers()
    @on_submit_form()
    company_search()
    companys_search()
    $('[data-toggle="popover"]').popover()
    trigger_mask_money()
    addNumberMask()

  addNumberMask = ->
    $('.numbermask').on 'keydown', (e) ->
      if !((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 36 && e.keyCode < 41) || e.keyCode == 8 || e.keyCode == 188 || e.keyCode == 190)
        return false

  on_submit_form: () ->
    that = @
    form = $('#contract-form')[0]
    $('#submit-contract-form').off('click.submit-contract-form').on 'click.submit-contract-form',  (e) ->
      plannings     = $('#plannings-selected').serializeArray()
      company       = $('#company-section-form').serializeArray()
      contracts_helpers = new ContractsHelpers(new FormData(form))
      contracts_helpers.process_form_data(plannings)
      contracts_helpers.process_form_data(company)
      contracts_helpers.process_form_data(that.fixed_costs.values())
      $.ajax {
        url: form.action,
        data: contracts_helpers.form_data,
        processData: false,
        contentType: false,
        type: form.method,
        beforeSend: ->
          new AjaxLoader().show()
          document.getElementById('submit-contract-form').disabled = true
        success: ->
          new AjaxLoader().hide()
          document.getElementById('submit-contract-form').disabled = false
      }

  click_in_service: () ->
    that = @

    $('.js-contracts-lists').off('.steps_services').on 'click.steps_services', '.js-cc-service-list', ->
      wrapper_tag = $(this).parents('.js-contracts-lists')
      accordion_group = $(this).parents('.accordion-group')
      siblings_accordion_group = accordion_group.siblings()

      selected_class = 'cc-service-list-selected'
      highlight_class = 'highlight' 
      children_cc_service_lists = $(this).parent().find('.js-cc-service-list')

      accordion_group.find('.js-cc-service-list').not(children_cc_service_lists).addClass(highlight_class)
  
      wrapper_tag.find('.accordion-group')
                 .not(accordion_group).add(siblings_accordion_group)
                 .find('.'+highlight_class)
                 .removeClass('highlight')

      children_cc_service_lists.removeClass('highlight')

      wrapper_tag.find('.'+selected_class).removeClass(selected_class)
      
      $(this).addClass(selected_class)     
      
      if wrapper_tag.attr('id') == 'steps_services'
        that.get_plannings($(this))

  click_in_planning_cell: () ->
    that = @
    $('.cc-planning-cell.father-cell').off().click ->
      selected = $(this)
      if selected.hasClass('cc-planning-selected')

      else
        selected.addClass('cc-planning-selected')
        selected_planning = selected.clone()
        that.add_selected_planning(selected_planning, selected)
      $('[data-toggle="popover"]').popover()

  add_selected_planning: (selected_planning, selected) ->
    add_close_icon_to_selected_planning(selected_planning)
    configure_selected_planning(selected_planning)
    increment_total()
    add_contract_inputs(selected_planning)

  trigger_mask_money = ->
    $('.cc-form-control').trigger('mask.maskMoney')

  add_close_icon_to_selected_planning = ( selected_planning) ->
    place_li = get_place_li(selected_planning)
    close_icon = $('<i class="icon icon-cancel cc-cell-delete-x"/>')
    place_li.append(close_icon)
    add_remove_action_handler(close_icon)

  configure_selected_planning = (selected_planning) ->
    selected_planning.removeClass('father-cell')
    selected_planning.addClass('child-cell')
    $('#cc-list-to-add').append(selected_planning)

  add_contract_inputs = (selected_planning) ->
    place_li = get_place_li(selected_planning)
    unitary  = Number(selected_planning.data('unitary')).toFixed(2) || 0
    quantity = Number(selected_planning.data('quantity')).toFixed(2) || 0
    input_contract_value     = $('<input>', {val: unitary, name: "construction_site_progress_evaluation_contract_progress_evaluation_contract[plannings][#{selected_planning.data('planning')}][contract_value]", class: "cc-form-control mask-money input_contract_value"} )
    input_contract_quantity  = $('<input>', {type: 'number', val: quantity, name: "construction_site_progress_evaluation_contract_progress_evaluation_contract[plannings][#{selected_planning.data('planning')}][contract_quantity]", class: "cc-form-control numbermask input_contract_quantity", min: 0, step: 0.01} )
    
    input_contract_value.on 'change', ->
      if (input_contract_value.val() == '')
        input_contract_value.val(0)

    input_contract_quantity.on 'change', ->
      if (input_contract_quantity.val() == '' || input_contract_quantity.val() < 0 )
        input_contract_quantity.val(0)

    input_contract_value.add(input_contract_quantity).on 'keyup', ->
      update_selected_calcs(selected_planning, input_contract_value.val(), input_contract_quantity.val())
      increment_total()

    li_unit_value = $('<li>', { class: 'unit-value' })
                  .append($('<label>').html(I18n.t('contracts.modal.selected_section.unit_value')))
                  .find('label')
                    .after(input_contract_value)
                    .end()

    li_quantity  = $('<li>', { class: 'quantity' })
                    .append($('<label>').html(I18n.t('contracts.modal.selected_section.quantity')))
                    .find('label')
                      .after(input_contract_quantity)
                      .end()

    place_li.after(li_quantity)
    place_li.after(li_unit_value)
    new StantMasks().set_money_mask(input_contract_value, true)
    input_contract_value.focus().blur()

  update_selected_calcs = (selected_planning, contract_value, contract_quantity) ->
    contract_value = Number(contract_value.replace('R$', '').replace(/\./g, '').replace(',', '.')).toFixed(2) || 0
    contract_quantity = Number(contract_quantity).toFixed(2) || 0
    newValue = contract_value * contract_quantity
    unit = selected_planning.attr('data-unit')
    newTotal = contract_quantity * contract_value
    total_place = ' ' + String(contract_quantity) + ' ' + unit + ' a ' + String(contract_value)
    selected_planning\
      .attr('data-total', newTotal) \
      .find('.popover_selected') \
      .attr('data-content', total_place) \
      .text(StantFormat.converteFloatMoeda(Number(newTotal).toFixed(2)))

  get_place_li = (selected_planning) ->
    selected_planning.find('.cc-planning-place')

  add_remove_action_handler = (close_icon_reference) ->
    close_icon_reference.off().click ->
      div = $(this).closest('div')
      planning_id = div.data('planning')
      $('.father-cell[data-planning='+planning_id+']').removeClass('cc-planning-selected')
      div.remove()
      increment_total()

  increment_total = ->
    total_result = get_total_sum() || 0
    total_formated = StantFormat.converteFloatMoeda(Number(total_result).toFixed(2))
    $('#cc-total-selected').text(total_formated)

  get_total_of_selecteds = ->
    $(".cc-planning-selected.child-cell").map( ->
      return Number($(this).attr('data-total'))
     ).get();

  get_total_sum = ->
    get_total_of_selecteds().reduce ((a, b) -> a + b), 0

  load_selected_plannings = () ->
    selected_plannings = get_selected_plannings()
    cells =  new RenderPlannings({}).show_selecteds_plannings(selected_plannings)
    $.each(cells, (index, cell) ->
      cell = $(cell)
      add_close_icon_to_selected_planning(cell)
      configure_selected_planning(cell)
      add_contract_inputs(cell)
      return
    )
    increment_total()

  get_selected_plannings = () ->
    $(".cc-service-list").map( ->
      return $(this).data('plannings-selecteds')
     ).get();

  company_search = () ->
    $('#company-cnpj-contract').off().keyup ->
      me = $(this)
      count_cnpj_caracters =me.val().replace(/\D/g, '').length
      if(count_cnpj_caracters >= 14)
        get_company(me)

      $(this).inputmask('99.999.999/9999-99')

  get_company = (input) ->
    cnpj = input.val()
    $.ajax {
      type: "GET",
      dataType: "script",
      data: { cnpj: cnpj },
      url: "/contracts/search_company",
      success: ->
    }

  companys_search = () ->
    $('#search_company_filter_button').off().click ->
      get_companys($('#company_filter_search_type :selected'), $('#search_value'))

  get_companys = (search_type_input, search_value_input) ->
    search_type = search_type_input.val()
    search_value = search_value_input.val()
    $.ajax {
      type: "GET",
      dataType: "script",
      data: { search_type: search_type, search_value: search_value},
      url: "/contracts/search_companies",
      success: ->
    }

  @set_company_list_onclick_function: (html_id, cnpj) ->
    $('#'+html_id).off().click ->
      $('#company-cnpj-contract').val(cnpj)
      get_company($('#company-cnpj-contract'))
      $('#company-cnpj-contract').inputmask('99.999.999/9999-99')
      $('#search_company_modal').modal 'hide'

window.ServiceContract = ServiceContract