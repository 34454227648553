import OccurrenceActionCreate from './occurrence_action_create.coffee'
import DataTable from './datatable.coffee'
import OccurrenceFileUploadBase from '../document_upload_base'

$(document).on 'turbolinks:load', ->
  new OccurrenceActionCreate().handler_events()
  new DataTable().add()

  new OccurrenceFileUploadBase(
    '.js-occurrence-action-photos-dropzone-modal',
    '.js-dropzone-preview-container-occurrence-action-modal',
    '.js-dropzone-document-preview-container-action'
    '#js-action-upload-button-action',
    '#js-dropzone-preview-template-occurrence-action-modal',
    'occurrence_action[files][]'
  ).call()
