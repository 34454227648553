export default class BuildSteps
  constructor: (@prefix_class, @labels) ->
    @container = build_container_class.call(@)
    hide_all_components_and_show_first.call(@)

  handler_events: ->
    handle_click_on_tabs.call(@)

  init: ->
    html = build_list.call(@)
    $(@container).append(html)

  build_list = ->
    html = """
    <div class='tabs-container'>
      <ul class="menu">
        #{build_items.call(@)}
      </ul>
    </div>
    """

    return html 

  build_items = ->
    html = ""
    active_class = 'active'
    tab_number = 0
    for label in @labels
      html += """
        <li class='item #{active_class}'>
          <a class='link' data-tab="#tab-#{tab_number++}">#{label}</a>
        </li>
      """
      active_class = ''

    return html

  handle_click_on_tabs = ->
    tabs_class = build_tabs_class.call(@)
    container_class = build_container_class.call(@)
    $("#{container_class} .menu a").on 'click', () ->
      $(@).parent().addClass("active")
      $(@).parent().siblings().removeClass("active")
      tab = $(@).data("tab")
      $(tabs_class).not(tab).hide()
      $("#{tabs_class}#{tab}").show()
      $('.tab-non-exist').removeClass("hide")

  hide_all_components_and_show_first = ->
    el_class = build_tabs_class.call(@)
    $(el_class).hide()
    $("#{el_class}#tab-0").show()

  #---- helpers ---------
  build_container_class = ->
    ".#{@prefix_class}-tabs"

  build_tabs_class = ->
    ".#{@prefix_class}-component"
