import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';

export const getAttributeValue = (form, resource, attribute) => {
  const attributeName = `[name="${resource}[${attribute}]"]`
  const element = form.querySelector(attributeName)

  if(!element) return '-'

  return element.value
}

export const getFormattedDate = (form, resource, attributeName) => {
  let dateStr = getAttributeValue(form, resource, attributeName)
  let currentHour = moment().format("HH:mm:ssZ")
  if(dateStr.length === 0) return null
  dateStr = moment(dateStr + currentHour, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ssZ')

  return new Date(dateStr).toISOString()
}

export const getElementAttributeValue = (element, property) => {
  return element.dataset[property]
}

export const getDynamicFormQuestionsElements = (form) => {
  const attributeName = '[data-dynamic-field-type]'
  const questions = form.querySelectorAll(attributeName)

  return Array.from(questions)
}

export const getGeocalizationValue = (GeocalizationElement) => {
  const [ latituteElement, longitudeElement ] = GeocalizationElement.querySelectorAll('input')
  
  return `${latituteElement.value}, ${longitudeElement.value}`
}

export const getQuestionElementValue = (questionElement) => {
  const fieldType = getElementAttributeValue(questionElement, 'dynamicFieldType')

  const questionsFieldTypes = {
    text: () => questionElement.value,
    numeric: () => questionElement.value,
    signature: () => getElementAttributeValue(questionElement, 'value'),
    geolocalization: () => getGeocalizationValue(questionElement)
  }

  if(!(fieldType in questionsFieldTypes)) return ''

  return questionsFieldTypes[fieldType]()
}

export const getPossibleValuesGuids = (questionElement) => {
  const selectedOptions = questionElement.selectedOptions
  if(!selectedOptions) return []

  return Array.from(selectedOptions).map((selectedOption) => selectedOption.value)
}

export const getGuid = (questionElement, action) => {
  const fieldType = getElementAttributeValue(questionElement, 'dynamicFieldType')

  if(fieldType === 'signature' && action === 'update') {
    return getElementAttributeValue(questionElement, 'dynamicValueGuid')
  }

  return uuidv4()
}

export const getDynamicFormParams = (form) => {
  const questionsElements = getDynamicFormQuestionsElements(form)
  const { action } = form.dataset

  const params = questionsElements.map((questionElement) => {
    return {
      guid: getGuid(questionElement, action),
      dynamic_field_guid: getElementAttributeValue(questionElement, 'dynamicFieldGuid'),
      value: getQuestionElementValue(questionElement),
      possible_values_guids: getPossibleValuesGuids(questionElement)
    }
  })

  return params
}