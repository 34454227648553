import { switch_component } from './utils/html_components'
class SecurityOptions {
	handler_events() {
		this.handle_expire_password_checkbox_change();
		this.handle_type_of_visualization();
	}

	handle_expire_password_checkbox_change() {
		$(document).on("change", "#js-checkbox-expire-days", e => {
			$("#js-input-expire-days").prop("disabled", !e.target.checked);
		});
	}

	handle_type_of_visualization(){
		$(document).on('change', '#js-construction-site-options-1', e => {

			let selected = $('#js-construction-site-options-1 option:selected').val()
			let start_date_switch = $('#switch-checkbox-dashboard-14').length
			let switch_element = $('#switch-checkbox-dashboard-start-date')
			let filter_conatiner = $('#date-filter-checkbox-container')

			if(start_date_switch && selected == 0){
				switch_element.hide()
			}else if(!start_date_switch) {
				filter_conatiner.prepend(switch_component())
			}else if(start_date_switch && selected == 1){
				switch_element.show()
			}
		})
	}
}

$(document).on("turbolinks:load", () => {
	new SecurityOptions().handler_events();

	let currpage = window.location.href;
	let lasturl = sessionStorage.getItem("last_url");
	if(!lasturl || !lasturl.length || currpage !== lasturl ){
		sessionStorage.setItem("last_url", currpage);
	}else{
		let backBtn = $('.voltar-form');
		backBtn.hide();
		$(document).on('click', '#step02', (e) => {
			backBtn.show();
			backBtn.on('click', () => { backBtn.hide() })
			$('#step01').on('click', () => { backBtn.hide() })
		})
	}
});
