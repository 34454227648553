import { httpMethodByAction, buildHeaders } from '../utils/http'
import { getAttributeValue } from '../utils/params'

export default class InspectionDestroyer {
  constructor(token) {
    this.headers = buildHeaders(token)
    this.resourceUrl = '/api/v1/verified_methods/inspection_data/'
  }

  destroy(form) {
    const { resource } = form.dataset
    const guid = getAttributeValue(form, resource, 'guid')

    return new Promise((resolve, reject) => {
      fetch(`${this.resourceUrl}/${guid}`, {
        method: httpMethodByAction('destroy'),
        headers: this.headers
      }).then((result) => {
        if(result.ok) resolve()
        else reject(result.status)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
