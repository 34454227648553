import L from 'leaflet'

$(document).on('turbolinks:load', function () {
  $('.js-delayed-indicator-popover').hover(
  function() {
    $('.restricted-popover').popover('show')
  }, function() {
    $('.restricted-popover').popover('hide')
  })

  $(".checkbox-coordenates").click(function() {
    if($('#checkbox_coordenates').is(':checked')){
      $(`.coordenates`).addClass('hide')
    } else {
      $(`.coordenates`).removeClass('hide')
    }
  });

  function updateMap() {
    let latitude  = $('#latitude_id').val();
    let longitude = $('#longitude_id').val();

    if(latitude && longitude) {
      let container = L.DomUtil.get('map');
      if(container != null){
        container._leaflet_id = null;
      }
      makeMap(latitude, longitude);
    }
  };

  $("#cs_address_guide").on('click', function(){
    let latitude  = $('#latitude_id').val();
    let longitude = $('#longitude_id').val();
    if(latitude && longitude)
      makeMap($('#latitude_id').val(), $('#longitude_id').val())
  })


  $("#latitude_id, #longitude_id, #map").on('input click change', updateMap);

  function makeMap(latitude, longitude) {
    var map = L.map('map').setView({lon: longitude, lat: latitude}, 16);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
    }).addTo(map);

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });

    L.control.scale().addTo(map);
    L.marker({lon: longitude, lat: latitude}).addTo(map);
  };
});

