import translations from "../../translations"
import * as I18n from "../../services/i18n"
export default class ConfigurePrePanningComments {
  static handler_events () {
    this.handle_comment_button_click()
  }

  static handle_comment_button_click () {
    $('.js-preplanning-open-comments').on('click', (e) => {
      const link = $(e.currentTarget)
      const loader = link.data('disableLoader')
      const get_comments_url = link.data('getCommentsUrl')
      const oldLinkHtml = link.html()

      link.html(loader)
      this.fetch_comments_list_and_pass_data_to_component(get_comments_url)
      link.html(oldLinkHtml)
      
      $('[data-controller="pre-plannings--comments"]')
        .data('saveCommentUrl', link.data('saveCommentUrl'))
    })
  }
  
  static fetch_comments_list_and_pass_data_to_component (url) {
    $.getJSON(url, (comments) => {
      $('[data-controller="pre-plannings--comments"]')
        .data('comments', comments)
  
      $('#js_new_preplanning_comment_modal').modal('show')
    }).fail((xhr, error, code) => {
      const message = I18n.t(translations(), 'unauthorized.construction_site.finish')

      if(code === 'Forbidden') {
        Error.show_alert_flash_msg(message)
      }
    })
  }
}