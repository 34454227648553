//With new turbolinks 5.1.1
//When happen some error inside js all js getting broken
//See issue https://github.com/turbolinks/turbolinks/issues/277

var jsErrorHasOccurred = false;

window.onerror = function() {
  jsErrorHasOccurred = true
}

window.onpopstate = function(){
  jsErrorHasOccurred = true
}

document.addEventListener("turbolinks:before-visit", function() {
  if (jsErrorHasOccurred == true) {
    var url = event.data.url;
    
    event.preventDefault(); // Cancel the turbolinks request
    window.location.href = url; // Do a regular page visit to clear the JS console
  }
});