import "../rails_confirm"
import "../init.coffee"
import "../fix_turbolinks_handler_error"
import Rails from 'rails-ujs'
import Turbolinks from "turbolinks"
import pace from "javascripts/pace-js/pace.js"
import "../fix_rails_disable_with"

Turbolinks.start()
if (!window._rails_loaded) {
  Rails.start()
}
pace.start()

import "javascripts/jquery-nestable/jquery.nestable.min"
import "javascripts/jquery-collapsible/jquery.collapsible.min"
import "javascripts/jquery-quick-search/jquery-quick-search"
import "javascripts/bootstrap-treeview/bootstrap-treeview"
import "javascripts/jquery.remotipart/jquery.remotipart"
import "moment"
import "moment/locale/pt-br"
import "javascripts/bootstrap-datetimepicker/bootstrap-datetimepicker.min"
import "javascripts/cocoon/cocoon"
import "perfect-scrollbar/dist/js/perfect-scrollbar.jquery"
import "../utils/input_file_upload.coffee"
import "../collapse.js.coffee"
import "../enable_datafim"
import "../script.min.coffee"
import "../tags.js.coffee"
import "../request_interceptor.coffee"

import "../pages/arpa/profiles/form.coffee"
import "../pages/cargos/form.coffee"
import "../pages/companies/index.coffee"
import "../pages/company_reports/form"
import "../pages/companies/security_options"
import "../pages/obras/locals/index.coffee"
import "../pages/documents/manager/index.coffee"
import "../pages/data_access/data_access_helper.coffee"
import "../pages/data_access/new_group.coffee"
import "../pages/entities/index.coffee"
import "../pages/obras/obras/index.coffee"
import "../pages/obras/obras/details.coffee"
import "../pages/servicos/update_service.coffee"
import "../pages/servicos/index.coffee"
import "../pages/servicos/form"
import "../pages/servicos/build_action_buttons"
import "../pages/step_groups/index.coffee"
import "../pages/obras/obras/form.coffee"
import "../pages/obras/obras/new"
import "../pages/occurrences/index.coffee"
import "../pages/occurrences/details.coffee"
import "../pages/occurrences/new"
import "../pages/occurrences/occurrence_actions/details.coffee"
import "../pages/occurrence_actions/details.coffee"
import "../pages/occurrence_types/new"
import "../pages/pre_plannings/new.coffee"
import "../pages/problemas_encontrados/problems_found.coffee"
import "../pages/pessoas/form.coffee"
import "../pages/people/construction_site_people/edit"
import "../pages/usuarios/form.coffee"
import "../pages/usuarios/perfil.coffee"
import "../pages/reports/fvs_service_verifications/main_card_listener.coffee"
import "../pages/reports/fvs_service_verifications/infinite_cards_fvs_service_verification.coffee"
import "../pages/reports/costs_and_trends/index"
import "../pages/reports/non_confomities/index.coffee"
import "../pages/reports/schedules/gantt_report"
import "../pages/reports/schedules/index.coffee"
import "../pages/reports/services_schedule/index.coffee"
import "../pages/reports/fvs_service_verifications/index"
import "../pages/reports/steps/external_index.coffee"
import "../pages/reports/traceabilities/index.coffee"
import "../pages/reports/reports_generator/index"
import "../pages/reports/reports_generator/search"
import "../pages/reports/reports_generator/form"
import "../pages/reports/reports_generator/geolocalization"
import "../pages/reports/excel_analytics/form"
import "../pages/reports/excel_analytics/index.coffee"
import "../pages/reports/occurrences/index.coffee"
import "../pages/inspection_data_images/index.coffee"
import "../pages/entitities/index.coffee"
import "../pages/construction_status/index.coffee"
import "../pages/contract-progress-evaluations/index"
import "../pages/contract-progress-evaluations/new"
import "../pages/contracts/new"
import "../pages/contracts/index.coffee"
import "../pages/atividades/form.coffee"
import "../pages/atividades/index.coffee"
import "../pages/plannings/steps/form.coffee"
import "../pages/plannings/fixed_costs/index.coffee"
import "../pages/plannings/fixed_costs/fixed_cost_total"
import "../pages/dashboard/report.coffee"
import "../pages/dashboard/show.coffee"
import "../pages/dashboard/step_progress_report.coffee"
import "../pages/traceabilities/traceabilities/new"
import "../pages/traceabilities/traceabilities/index"
import "../pages/traceabilities/traceabilities/edit"
import "../pages/traceabilities/trucks/index.coffee"
import 'pages/fvs_preenchimentos/index'
import "../pages/places/form"
import "../layouts/header"
import "../pages/admin/reports/reports.coffee"

import "../pages/data_access/data_access_helper.coffee"
import "../pages/data_access/new_group.coffee"
import "../pages/data_access/new_permission.coffee"
import "../pages/reports/fvs_service_verifications/fvs_service_verification_show_summary.coffee"
import "../pages/reports/fvs_service_verifications/fvs_service_verification_summary_sessiona_storage.coffee"
import "../pages/reports/fvs_service_verifications/get_items_listener.coffee"
import "../pages/reports/fvs_service_verifications/infinite_cards_fvs_service_verification.coffee"
import "../pages/reports/fvs_service_verifications/main_card_listener.coffee"

import "../utils/stant_flash_messages.coffee"
import "../utils/stant_table_data_maker.coffee"

import "bootstrap/dist/js/bootstrap"
import "jschr-bootstrap-modal/js/bootstrap-modalmanager"
import "jschr-bootstrap-modal/js/bootstrap-modal"
import "jquery.flot/jquery.flot"
import "quill/dist/quill"

import InspectionFormHandler from 'pages/fvs_preenchimentos/inspection_form_handler'
window.InspectionFormHandler = InspectionFormHandler

import StantDatepicker from "utils/stant_datepicker.coffee"
window.StantDatepicker = StantDatepicker

import List from "javascripts/listjs/list"
window.$ = $
window.List = List
window.Rails = Rails

//Stimulus Instalation
import { Application } from "stimulus"
const application = Application.start()

// Is necessary manually registry all new controllers bellow
// see https://stimulusjs.org/reference/controllers#registration
// Use convention module_name--controller_name ex a file on pages/pre_plannings/comment_controller.js
// The name should be pre-planning--comment

import PrePlanningsCommentController from '../pages/pre_plannings/comment_controller'

application.register("pre-plannings--comments", PrePlanningsCommentController)
