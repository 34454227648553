import AjaxLoader from "../../../utils/ajax_loader.coffee"

window.GetItemsListener = class GetItemsListener

  constructor: (fvs_request) ->
    @fvs_request    = fvs_request
    @clicked_service= $('.click-service')

  add_listener: ->
    self = @

    @clicked_service.off().on 'click', ->
      service = $(this).data('service')
      table   = $('#fvs-collapse-'+service)
      service_name = $('#fvs-heading-'+service).find('.panel-title').html();

      call_ajax.call(self, service, service_name) if table_expanded(table)

  call_ajax = (service, service_name) ->
    new AjaxLoader().show()
    $.ajax
      type: 'get'
      url: '/non_conformities/search_fvs_items/'+service
      data: {
        fvs_request: @fvs_request,
        service_name: service_name }

  table_expanded = (table) ->
    return !table.hasClass('in')
