import "select2"
import translations from "../../translations"
import * as I18n from "../../services/i18n"
import StantMultiselect from "../../utils/stant_multiselect.coffee"

$(document).on "turbolinks:load", ->
  new StantMultiselect('users-multiselect').add_listener()

  $('select.pessoa-select2-search').select2
    formatNoMatches: I18n.t(translations(), 'users.index.search_result_empty')

  $('#user_pessoa_id').on 'change', ->
    person_id = $(this).val()

    $.ajax(
      type: 'post'
      url: '/pessoas/search'
      dataType: "json"
      data:
        id: person_id
      success: (person) ->
        $('#user_email').val person.email
    )

  $('#btn-user-login-lock').on 'click', ->
    btnUserLoginLock = $('#btn-user-login-lock')
    inputUserLogin = $('#user_email')

    if btnUserLoginLock.hasClass('btn-input-lock-icon') 
      inputUserLogin.removeClass('disabled')
      inputUserLogin.removeAttr('disabled')
      btnUserLoginLock.removeClass('btn-input-lock-icon')
      btnUserLoginLock.addClass('btn-input-unlock-icon')
    else 
      inputUserLogin.addClass('disabled')
      inputUserLogin.attr('disabled', 'disabled')
      btnUserLoginLock.removeClass('btn-input-unlock-icon')
      btnUserLoginLock.addClass('btn-input-lock-icon')

  $('#btn-user-password-lock').on 'click', ->
    btnUserPasswordLock = $('#btn-user-password-lock')
    inputUserPassword = $('#update_password')    
    inputUserPasswordConfirmation = $('#update_password_confirmation')    

    if btnUserPasswordLock.hasClass('btn-input-lock-icon') 
      inputUserPassword.removeClass('disabled')
      inputUserPassword.removeAttr('disabled')
      inputUserPasswordConfirmation.removeClass('disabled')
      inputUserPasswordConfirmation.removeAttr('disabled')
      btnUserPasswordLock.removeClass('btn-input-lock-icon')
      btnUserPasswordLock.addClass('btn-input-unlock-icon')
    else 
      inputUserPassword.addClass('disabled')
      inputUserPassword.attr('disabled', 'disabled')
      inputUserPassword.val('')
      inputUserPasswordConfirmation.addClass('disabled')
      inputUserPasswordConfirmation.attr('disabled', 'disabled')
      inputUserPasswordConfirmation.val('')
      btnUserPasswordLock.removeClass('btn-input-unlock-icon')
      btnUserPasswordLock.addClass('btn-input-lock-icon')

