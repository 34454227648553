export const httpMethodByAction = (action) => {
  const methods = {
    new: 'POST',
    update: 'PUT',
    destroy: 'DELETE'
  }

  return methods[action]
}

export const prepareBody = (params) => {
  const urlParams = new URLSearchParams();

  for(const [key, value] of Object.entries(params)) 
    urlParams.append(key, value)

  return urlParams
}

export const buildHeaders = (token) => {
  return new Headers({
    'Content-Type' : 'application/json',
    'Accept' : 'application/json',
    'Authorization': `Bearer ${token}`,
    'Device-Id': '000'
  })
}
