import StepChartBarTooltip from "./step_chart_bar_tooltip.coffee"
import translations from "../../../translations"
import * as I18n from "../../../services/i18n"

export default class StepChartBar
  constructor: (@div_chart, @steps_response) ->
    @ticks    = []
    @dataset  = []
    build_ticks_and_dataset.call(@)

  draw_chart: ->
    options = chart_options.call(@)
    window.ploted = $.plot @div_chart, @dataset, options
    new StepChartBarTooltip(@div_chart).apply_tooltip()


  # ---- Private methods ----

  build_ticks_and_dataset = ->
    self = @
    label = ''

    if self.steps_response
      if self.steps_response.length == 0
        label = I18n.t(translations(), 'steps.external_index.chart_bar.records_not_enough')
        self.steps_response.push {name: '', total_executed: 0}

      self.steps_response.forEach (element, index, array) ->
        data =
          label: label
          data: [ [
            element.total_executed
            index
          ] ]
          color: '#ef4423'
        tick = [
          index
          element.name
        ]

        self.dataset.push data
        self.ticks.push tick
    return

  min_axis_size = ->
    if @dataset.length <= 2 then return -2

  chart_options = ->
    series:
      bars:
        show: true
        fillColor: '#ef4423'
        horizontal: true
    bars:
      align: "center"
      barWidth: 0.3
    xaxis:
      tickFormatter: (v, axis) ->
        "#{v}%"
      min: 0
      max: 100
    yaxis:
      tickLength: 0 # hide gridlines
      ticks: @ticks
      min: min_axis_size.call(@)
    grid:
      hoverable: true
      borderWidth: 1
      borderColor:'#f0f0f0'
    legend:
      margin: [30, 0]
      backgroundOpacity: 0
      noColumns: 0
      position: 'nw'
      labelFormatter: (label, series) ->
        "<h1>#{label}</h1>"
