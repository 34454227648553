import * as Dropzone from "javascripts/dropzone/dropzone"
import translations from "../translations"
import * as I18n from "../services/i18n"

export default class StantDropzone
  constructor: (@element_id, @dropzone_modal_id, @preview_template) ->

  add_listener: ->
    self = @

    $("##{self.element_id}").addClass('dropzone')

    previewNode = $("##{self.preview_template}")
    previewNode.id = ''
    previewTemplate = previewNode.parent().html()
    previewNode.parent().remove("##{self.preview_template}")

    $("##{self.element_id}").dropzone
      createImageThumbnails: false,
      previewTemplate: previewTemplate,
      previewsContainer: "#dropzone-preview",
      clickable: "#upload_link",
      saveUploads: '#save-uploads'
      autoDiscover: false,
      dictFileTooBig: I18n.t(translations(), 'documents.documents.index.file_too_big')
      dictCancelUploadConfirmation: I18n.t(translations(), 'documents.documents.index.cancel_upload_confirmation')
      dictFailOnSend: I18n.t(translations(), 'documents.documents.index.upload_fail')

      addedfile: (file) ->
        $("#dropzone_lightbox").modal('show')

        if @previewsContainer
          file.previewElement = Dropzone.createElement @options.previewTemplate.trim()

          $(@previewsContainer).prepend($(file.previewElement))
          node.textContent = file.name for node in file.previewElement.querySelectorAll("[data-dz-name]")
          node.innerHTML = @filesize file.size for node in file.previewElement.querySelectorAll("[data-dz-size]")
          $(node).parent().find('.file-percent').text("0%")

      sending: (file) ->
        $('#current-uploads').show()
        $('#current-uploads').text I18n.t(translations(), 'documents.documents.index.in_progress_uploads')
        $(".flash-messages .close").click()

      uploadprogress: (file, progress, bytesSent) ->
        if file.previewElement
          for node in file.previewElement.querySelectorAll("[data-dz-uploadprogress]")
            $(node).parent().find('.file-percent').text("#{progress.toFixed(0)}%")

            if node.nodeName is 'PROGRESS'
              node.value = progress
            else
              node.style.width = "#{progress}%"

      complete: (file) ->
        $('#current-uploads').text I18n.t(translations(), 'documents.documents.index.finished_uploads')

      success: (file, response) ->
        $(file.previewElement).find('.progress').removeClass('progress-bar-primary')
        $(file.previewElement).find('.progress').addClass('progress-bar-success')
        $(file.previewElement).find('.check span').addClass 'icon icon-checked'

        $.getScript(response.reload_url)

      error: (file, response) ->
        $(file.previewElement).find('.progress').removeClass('progress-bar-primary')
        $(file.previewElement).find('.progress').addClass('progress-bar-danger')
        $(file.previewElement).find('.file-percent').text('0%')
        $(file.previewElement).find('.check span').addClass 'icon icon-cancel'
        show_alert_flash_msg(@.options.dictFailOnSend, self)

  show_alert_flash_msg = (msg ,self) ->
    html = '<div class="alert-box error animated bounceInRight"> ' +
              '<p> ' + msg +
                '<a href="#" class="close" data-dismiss="alert" aria-label="close">' +
                '  &times;' +
                '</a>' +
              '</p>' +
           '</div>'
    $("##{self.dropzone_modal_id} .flash").html html
    $(".flash-messages").html html
