import Error from "../../utils/error.coffee"
import AjaxLoader from "../../utils/ajax_loader.coffee"
import translations from "../../translations"
import * as I18n from "../../services/i18n"

export default class PlanningsCreationWithXslx
  selected_file = false

  handler_events: ->
    handler_company_select_change()
    handler_construction_site_select_change()
    handle_change_input_file_name()
    handle_create_button_enable()

  config_load_select_show = ->
    time_for_resize_width_run_on_browser = 200
    $('#js_plannings_with_xlsx_construction_site').attr('disabled', 'disabled')
    setTimeout ( ->
      new AjaxLoader().show()
    ), time_for_resize_width_run_on_browser

  config_load_select_hide = (keep_disabled) ->
    time_for_smoth_animation = 300
    setTimeout ( ->
      new AjaxLoader().hide()
      $('#js_plannings_with_xlsx_construction_site').attr('disabled', if !parseInt(keep_disabled) then 'disabled' else false)
    ), time_for_smoth_animation

  change_options_from_company_selection = (construction_sites, construction_site_id_for_select, keep_disabled) ->
    config_load_select_hide(keep_disabled)
    default_option = I18n.t(translations(), 'helpers.select.placeholder')
    html = "<option value=''> #{default_option} </option>"
    for construction_site in construction_sites
      html += "<option value=#{construction_site.id}> #{construction_site.titulo} </option>"

    $('#js_plannings_with_xlsx_construction_site').last().empty().append(html)
    $('#js_plannings_with_xlsx_construction_site').last().val(construction_site_id_for_select) if construction_site_id_for_select

  truncate = (source, size) ->
    if source.length > size
      return source.slice(0, size - 1) + "…"
    else
      return source

  handler_company_select_change = ->
    $('#js_plannings_with_xlsx_company_select').on 'change', (e, company_id_for_select) ->
      option = $(@).find('option:selected')

      unless option.val()
        $('.construction_site_id abbr').hide()
        change_options_from_company_selection([], company_id_for_select, true)
        return

      config_load_select_show()
      $('.construction_site_id abbr').show() if option.get(0)

      $.ajax {
        type: 'GET',
        dataType: 'json',
        data: { construction_site_id: option.val() },
        url: '/atividades/get_construction_sites_by_company.json',
        success: (construction_sites) -> change_options_from_company_selection(construction_sites, company_id_for_select, option.val())
      }

  handler_construction_site_select_change = ->
    $('#js_plannings_with_xlsx_construction_site').on 'change', () ->
      handle_create_button_enable()
      handle_download_upload_button_enable()

  handle_change_input_file_name = ->
    file_input = $('.hidden-input-file')
    file_label = file_input.next()

    file_input.on 'change', (e) ->
      file_name = e.target.value.split( "\\" ).pop();
      file_error = $('.upload-plannings-file-box > p')
      file_error.html('')
      file_error.addClass('hide')
      allowedExtensions = /(\.xlsx)$/i;
             
      if file_name == ''
        file_label.find('span').html(I18n.t(translations(), 'create_with_xlsx.form.upload_xlsx_file'))
        selected_file = false
      else 
        if !allowedExtensions.exec(file_name)
          file_error.html(I18n.t(translations(), 'create_with_xlsx.messages.upload_file_invalid_format')) 
          file_error.removeClass('hide') 
          selected_file = false
        else
          selected_file = true

        file_label.find('span').html(truncate(file_name, 25))
        
      handle_create_button_enable()

  handle_create_button_enable = ->
    construction_site = $('#js_plannings_with_xlsx_construction_site').find('option:selected').val()

    if construction_site != '' && selected_file
      $('.js-plannings-with-xlsx-save').attr('disabled', false)
    else
      $('.js-plannings-with-xlsx-save').attr('disabled', 'disabled')
  
  handle_download_upload_button_enable = ->
    construction_site = $('#js_plannings_with_xlsx_construction_site').find('option:selected').val()
    download_base_file_button = $('.btn-download-base-file')
    upload_file_button_input = $('.btn-upload-plannings-file-input')
    upload_file_button_label = $('.btn-upload-plannings-file-label')

    if construction_site != ''
      download_base_file_url = "#{download_base_file_button.attr('data-ref')}?construction_site_id=#{construction_site}"
      download_base_file_button.attr('href', download_base_file_url)
      download_base_file_button.removeClass('disabled')
      upload_file_button_label.removeClass('disabled')
      upload_file_button_input.attr('disabled', false)
    else
      download_base_file_button.attr('href', '')
      download_base_file_button.addClass('disabled')
      upload_file_button_label.addClass('disabled')
      upload_file_button_input.attr('disabled', 'disabled')

