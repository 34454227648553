import "javascripts/jquery-sortable-lists/jquery-sortable-lists.min"

export default class StantSortableLists
  constructor: (@element_list, @stant_sortable) ->
    self = @
    @options =
      opener:
        active: true
        as: 'html'
        close: '<button class="btn btn-link">-</button>'
        open: '<button class="btn btn-link">+</button>'
      ignoreClass: 'clickable'
      listsClass: 'dd-item'
      placeholderCss: { 'border':'1px dashed #b6bcbf' }
      hintCss: {'border':'1px dashed #ec7234'}

      onChange: (element) ->
        self.stant_sortable.on_sortable_change(self.element_list, element)

      complete: (element) ->
        self.stant_sortable.on_sortable_complete(element)

      isAllowed: (currEl, hint, target) ->
        self.stant_sortable.on_is_allowed(currEl, hint, target)

  add_listener: () ->
    $('.sortableListsOpener').remove();
    $(@element_list).off().sortableLists(@options)
    $('.js-remove-sortable-listener-opener .sortableListsOpener').remove();
