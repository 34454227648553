import translations from 'translations'
import * as I18n from 'services/i18n'

function formatValue(inputValue, type) {
  if (type === "hour") {
    return inputValue === "0" || inputValue === "" ? "00" : inputValue;
  } else if (type === "min") {
    return inputValue <= 9 ? `0${inputValue}` : inputValue;
  }
}

export function timeValue(containerReworkTime) {
  const hour = containerReworkTime.find(".hour");
  const min = containerReworkTime.find(".min");

  return {
    hour: formatValue(hour.val(), "hour"),
    min: formatValue(min.val(), "min"),
  };
}

export function loadingProgress(percetageProgress) {
  const loadingElement = $('.pace.pace-inactive')

  $('.pace .pace-progress').css('width', '0%')

  loadingElement.addClass('pace-active')
  loadingElement.removeClass('pace-inactive')

  $('.pace .pace-progress').css('width', `${percetageProgress}%`)
  
  if(percetageProgress >= 100) {
    setTimeout(() => {
      $('.pace.pace-active').addClass('pace-inactive')
      $('.pace.pace-active').removeClass('pace-active')
    }, 800);
  }
}

export function requestUpdateModal(dataMethodIndex) {
  const [ itemIndex ] = dataMethodIndex.split('-')

  const requestUpdateMethod = document.querySelector(`#request-fvs-${itemIndex}`)
  const requestUpdateMethodUrl = requestUpdateMethod.href
  requestUpdateMethod.href = `${requestUpdateMethodUrl}&index_item_method=${dataMethodIndex}`
  requestUpdateMethod.click()
}

export function defineFlashMessage(type,resource) {
  return `
    <div class="col-md-4 offset-md-4 flash-messages">
      <div class="alert-box ${type} animated bounceInRight">
        <p>
        ${I18n.t(translations(), `fvs_preenchimentos.components.flash_messages.${type}.${resource}`)}
          <a href="#" class="close" data-dismiss="alert" aria-label="close">×</a>
        </p>
      </div>
    </div>
  `
}
