import FinancesHelpers from "../../utils/finances_helpers.coffee"
import BuildSteps from "../../utils/build_steps.coffee"
import translations from "../../translations"
import * as I18n from "../../services/i18n"
import ServiceInspectionFormFilledChart from "./charts/service_inspection_form_filled_chart.coffee"
import StepProgressChart from "./charts/step_progress_chart.js"
import PrintDashboard from "./print_dashboard.js"
import OccurrenceChart from "./charts/occurrence_chart.js"
import OccurrenceDetail from '../../pages/problemas_encontrados/occurrence_detail.coffee'

import Chart from "chart.js"

options = responsive:true
generatedColor = (fator) ->
  max_hex_value = 16777215
  base = 16

  # Generate a random number according with the max_hex_value and converts to hexadecimal
  '#'+Math.floor(Math.random() * max_hex_value * fator).toString(base);

pieData = (ocorrencias) ->
  pieDataOcorrencias = []
  for ocorrencia in ocorrencias
    data = { value: ocorrencia.total,
    color: generatedColor(1),
    label: ocorrencia.occurrence_type }
    pieDataOcorrencias.push(data)
  pieDataOcorrencias

pieDataForSevenDays = ->
  ateSeteDiasAtras = $("#tab-1[data-ocorrencias]").data("ocorrencias")
  pieData(ateSeteDiasAtras)

pieDataForThirtyDays = ->
  ateTrintaDiasAtras = $("#tab-2[data-ocorrencias]").data("ocorrencias")
  pieData(ateTrintaDiasAtras)

pieDataForAllDays = ->
  allTime = $("#tab-3[data-ocorrencias]").data("ocorrencias")
  pieData(allTime)

pieDataForAllDays = ->
  expired = $("#tab-4[data-ocorrencias]").data("ocorrencias")
  pieData(expired)

getChartForSevenDays = ->
  data = $("#tab-1[data-ocorrencias]").data("ocorrencias")
  OccurrenceChart.handle_show_and_hide_tab_component("seven")
  return if data.length == 0

  ctx1 = document.getElementById("chart-area1").getContext("2d")

  OccurrenceChart.render(ctx1, data, "seven")

getChartForThirtyDays = ->
  data = $("#tab-2[data-ocorrencias]").data("ocorrencias")
  OccurrenceChart.handle_show_and_hide_tab_component("thirty")
  return if data.length == 0

  ctx2 = document.getElementById("chart-area2").getContext("2d")

  OccurrenceChart.render(ctx2, data, "thirty")

getChartForAllDays = ->
  data = $("#tab-3[data-ocorrencias]").data("ocorrencias")
  OccurrenceChart.handle_show_and_hide_tab_component("all")
  return if data.length == 0

  ctx3 = document.getElementById("chart-area3").getContext("2d")

  OccurrenceChart.render(ctx3, data, "all")

getChartForExpired = ->
  data = $("#tab-4[data-ocorrencias]").data("ocorrencias")
  OccurrenceChart.handle_show_and_hide_tab_component("expired")
  return if data.length == 0

  ctx4 = document.getElementById("chart-area4").getContext("2d")
  OccurrenceChart.render(ctx4, data, "expired")

occurrenceTabsListener = ->
  $(".tabs-menu a, #tabs-menu a").on 'click', (event) ->
    $(this).parent().addClass("active")
    $(this).parent().siblings().removeClass("active")
    tab = $(this).data("tab")
    $(".tab-content").not(tab).css("z-index", "0").css("opacity", "0")
    $(tab).css("z-index", "1").css("opacity", "1")
    $('.tab-non-exist').removeClass("hide")

init_financial_chart = ->
  callback = FinancesHelpers.draw_financial_graph
  render_financial_graph('#financial-chart-weekly', 'weekly', callback)
  render_financial_graph('#financial-chart-monthly', 'months', callback)

  build_steps = new BuildSteps(
    'financial',
    [
      I18n.t(translations(), 'dashboard.show.weekly'),
      I18n.t(translations(), 'dashboard.show.monthly')
    ]
  )

  build_steps.init()
  build_steps.handler_events()

init_physic_chart = ->
  callback = FinancesHelpers.draw_physical_graph
  render_financial_graph('#physic-chart-weekly', 'weekly', callback)
  render_financial_graph('#physic-chart-monthly', 'months', callback)

  build_steps = new BuildSteps(
    'buildprogress',
    [
      I18n.t(translations(), 'dashboard.show.weekly'),
      I18n.t(translations(), 'dashboard.show.monthly')
    ]
  )

  build_steps.init()
  build_steps.handler_events()

$(document).ajaxStop ->
  $('#btn-dashboard-download-pdf').removeClass('hide');

$(document).on 'turbolinks:load', ->
  $("#construction-site-info-text").modal()
  $('.js-multisite-delayed-indicator-popover').popover {
    trigger: 'hover click'
  }

  if $('body').hasClass('dashboard-show')
    $('#btn-dashboard-download-pdf').removeClass('hide');

    # Rendering reports
    $.ajax {
      url: "/occurrence-charts.js",
      method: 'GET',
      success: (data) ->
        $('.occurrence-charts').html data
        getChartForSevenDays()
        getChartForThirtyDays()
        getChartForAllDays()
        getChartForExpired()
        occurrenceTabsListener()
      error: (data) ->
        $('.occurrence-charts').html data.responseText
    }

    ServiceInspectionFormFilledChart.render()
    StepProgressChart.render()
    # PrintDashboard.handler_events()
    if $('#dashboard-fails').length
      $.get "/fails-info", (data) ->
        $('.fails-info').html data
        $('.js-delayed-indicator-popover').popover {
          trigger: 'hover click'
        }
    if $('#works-dashboard-ranking').length
      $.get "/ranking", (data) ->
        $('.ranking-table').html data
        handle_column_filter_click('table-ranking', '#works-dashboard-ranking')

    $.get "/ongoing-services", (data) ->
      $('.ongoing-services-table').html data
      handle_column_filter_click('table-services', '#works-dashboard-ongoing-services')

    $.get "/supplies_schedule", (data) ->
      $('.supplies-schedule-table').html data

    $.get "/total-applied", (data) ->
      $('.total-applied').html data
      $('.js-total-applied-popover').popover {
        trigger: 'hover click'
      }

    $.get "/problems-table", (data) ->
      $('.problems-table').html data
      new OccurrenceDetail().handler_events()

    $.get "/trucks-indicators", (data) ->
      $('.trucks-indicators').html data

    $.get "/build_progress", (data) ->
      $('.build-progress').html data
      init_physic_chart()
      $('.js-build-progress-popover').popover {
        trigger: 'hover click'
      }

    $.get "/financial", (data) ->
      $('.financial').html data
      init_financial_chart()

handle_column_filter_click = (clickClass, targetElement) ->
  $(document).on 'click', ".#{clickClass} .sort_link", (e) ->
   e.preventDefault()
   url = $(this).attr('href')
   $.get url, (data) ->
    $(targetElement).html data

render_financial_graph = (container, data_type, callback) ->
  pure_selector_container = container
  container = $(container)
  labels = JSON.parse container.attr("data-#{data_type}")
  series = []
  executeds = JSON.parse container.attr('data-executeds-amount')
  expecteds = JSON.parse container.attr('data-expecteds-amount')
  series.push executeds
  series.push expecteds
  callback(pure_selector_container, labels, series)