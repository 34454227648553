import translations from 'translations'
import * as I18n from 'services/i18n'
import { formatSelection, formatResult } from './helpers'

$(document).on("turbolinks:load", () => {
  const selectLanguage = $('.select-language')

  if(selectLanguage.length){
    selectLanguage.select2({
      minimumResultsForSearch: Infinity,
      escapeMarkup(markup) {
        return markup;
      },
      formatSelection,
      formatResult,
      dropdownCssClass: 'select2-dropdown-header-application'
    })

    selectLanguage.on('change', () => {
      $('#select-language-company').submit()
    })

    const selectArrow = $('.select-language .select2-arrow')
    selectArrow.attr({
      'data-toggle': 'tooltip',
      'data-placement': 'bottom',
      'data-original-title': I18n.t(translations(), 'application.header.change_language')
    })

    selectArrow.tooltip()
  }
})