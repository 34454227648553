import DocumentsTable from "../documents_table.coffee"
import DocumentsDataPermissions from "../documents_data_permissions.coffee"
import StantMultiselect from "../../../utils/stant_multiselect.coffee"
import StantDropzone from "../../../utils/stant_dropzone.coffee"

$(document).on 'turbolinks:load', ->
  $(window).resize ->
    marginTopForTreeView = 270
    marginTopForTable = 350
    $('.documents-table-scroll').height($(window).height() - marginTopForTreeView).perfectScrollbar()
    $('.treeview-scroll').height($(window).height() - marginTopForTable).perfectScrollbar()
    return

  $(window).trigger 'resize'

  new DocumentsTable.add_listeners(false, false)
  new DocumentsDataPermissions.add_listeners()
  new StantMultiselect('user_email', false).add_listener()

  new StantDropzone('documents-dropzone', 'dropzone_lightbox', 'dropzone-template').add_listener()