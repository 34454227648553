import translations from "../../../translations"
import * as I18n from "../../../services/i18n"
import GanttChart from "./gannt_chart"

class GanttReport {
  constructor(schedule_responses) {
    this.schedule_responses = schedule_responses
    this.gantt_charts = $('.gantt-charts')
    this.container    = null
    this.chart        = null
    this.data_table   = null
  }

  draw_charts = () => {
    if(this.schedule_responses_is_empty()) {
      this.show_error()
      return
    }
    
    this.container  = $('.timeline-schedule-chart')[0]
    this.chart      = new (google.visualization.Timeline)(this.container)
    this.data_table = new (google.visualization.DataTable)

    this.add_columns()

    for(let index in Object.values(this.schedule_responses)) {
      let default_values  = this.schedule_responses[index].default
      let realized_values = this.schedule_responses[index].realized
      let gantt_chart     = new GanttChart(this.data_table, default_values, realized_values)

      gantt_chart.add_rows()
    }
    
    let options = { 
      forceIFrame: true, 
      avoidOverlappingGridLines: true, 
      timeline: { showRowLabels: true },
      tooltip: { isHtml: true }
    }

    this.chart.draw(this.data_table, options)
  }

  schedule_responses_is_empty = () => {
    return Object.keys(this.schedule_responses).length == 0
  }

  show_error = () => {
    msg  = I18n.t(translations(), "schedules.index.form.collection_empty")
    html = '<div class="alert-box error stant-error animated bounceInRight">' +
              '<p> ' + msg +
               '<a href="#" class="close" data-dismiss="alert" aria-label="close">' +
                 '&times;' +
               '</a>' +
             '</p>' +
            '</div>' +
    $('.flash-messages').html(html)
  }
  
  add_columns = () => {
    this.data_table.addColumn({ type: 'string', id: 'Tipo' })
    this.data_table.addColumn({ type: 'string', id: 'Serviços' })
    this.data_table.addColumn({ type: 'string', id: 'Info', role: 'tooltip', p: { html: true } })
    this.data_table.addColumn({ type: 'date', id: 'Start' })
    this.data_table.addColumn({ type: 'date', id: 'End' })
  }
}
window.GanttReport = GanttReport 
