import ExpandCircleIcons from "./status_identifier.coffee";
import translations from "../../translations";
import * as I18n from "../../services/i18n";

$(document).on("turbolinks:load", function() {
  var details_title = I18n.t(
    translations(),
    "contract_progress_evaluations.index.items.buttons.details"
  );
  var edit_title = I18n.t(
    translations(),
    "contract_progress_evaluations.index.items.buttons.edit"
  );
  var delete_title = I18n.t(
    translations(),
    "contract_progress_evaluations.index.items.buttons.delete"
  );
  var approve_title = I18n.t(
    translations(),
    "contract_progress_evaluations.index.items.buttons.approve"
  );
  var reprove_title = I18n.t(
    translations(),
    "contract_progress_evaluations.index.items.buttons.reprove"
  );
  var finish_title = I18n.t(
    translations(),
    "contract_progress_evaluations.index.items.buttons.finish"
  );
  var pay_title = I18n.t(
    translations(),
    "contract_progress_evaluations.index.items.buttons.pay"
  );

  new ExpandCircleIcons(
    details_title,
    "btn-details-in-measure-cell"
  ).add_listeners();
  new ExpandCircleIcons(
    edit_title,
    "btn-editing-in-measure-cell"
  ).add_listeners();
  new ExpandCircleIcons(
    delete_title,
    "btn-delete-in-measure-cell"
  ).add_listeners();
  new ExpandCircleIcons(
    approve_title,
    "btn-approve-in-measure-cell"
  ).add_listeners();
  new ExpandCircleIcons(
    reprove_title,
    "btn-reprove-in-measure-cell"
  ).add_listeners();
  new ExpandCircleIcons(
    finish_title,
    "btn-finish-in-measure-cell"
  ).add_listeners();
  new ExpandCircleIcons(pay_title, "btn-pay-in-measure-cell").add_listeners();
});
