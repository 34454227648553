export default class EntityLinksSection

  constructor: () ->
    @add_fields = 'form .add_link_fields'
    @remove_fields = '.remove_link_fields'

# --- Public Methods ---

  add_listener: ->
    add_link_listeners(@)
    add_listeners_remove_exist_link_fields(@remove_fields)

# --- Private Methods ---

  prevent_default = ->
    event.preventDefault()

  configure_link_fields = (add_field, remove_link_fields) ->
    fields = $($(add_field).data('fields'))
    remove_button = fields.find(remove_link_fields)
    add_listeners_remove_link_fields(remove_button)
    $('.links_content').append(fields)

  add_link_listeners = (that) ->
    $(that.add_fields).click (event) ->
      configure_link_fields(this, that.remove_fields)
      prevent_default(event)

  remove_links_content = (remove_field) ->
    $(remove_field).parents('.link-section').remove()

  add_listeners_remove_link_fields = (remove_link_fields) ->
    $(remove_link_fields).off().click (event) ->
      remove_links_content(this)
      prevent_default(event)

  add_listeners_remove_exist_link_fields = (link_field) ->
    add_listeners_remove_link_fields(link_field)
