import { httpMethodByAction, buildHeaders } from '../utils/http'
import { getAttributeValue } from '../utils/params'
import { loadingProgress } from '../utils/helpers'

export default class InspectionImageCreator {
  constructor(token) {
    this.headers = buildHeaders(token)
    this.resourceUrl = '/api/v1/service_inspections_form_filled/inspection_data_images'
  }

  createMany(inspectionGuid, inspectionForm) {
    const imageForms = inspectionForm.querySelectorAll('.new-image-form')
    let loadingProgressPercetage = 0
    loadingProgress(1)

    return Promise.all(Array.from(imageForms).map((form) => {
      const imageAttr = getAttributeValue(form, 'inspection_data_image', 'image')
  
      return new Promise((resolve, reject) => {
        const params = {
          guid: getAttributeValue(form, 'inspection_data_image', 'guid'),
          inspection_data_guid: inspectionGuid,
          image: imageAttr.substr(imageAttr.indexOf(',') + 1),
          caption: getAttributeValue(form, 'inspection_data_image', 'caption'),
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }

        fetch(this.resourceUrl, {
          method: httpMethodByAction('new'),
          headers: this.headers,
          body: JSON.stringify(params) 
        }).then((response) => {
          response.json().then((data) => {
            loadingProgressPercetage =  loadingProgressPercetage + (90 / imageForms.length)
            loadingProgress(loadingProgressPercetage)

            if(response.ok) resolve(data)
            else reject(data)
          })
        }).catch(error => {
          reject(error)
        })
      })
    }))
  }
}
