export default class ManageableService
  @handler_events: ->
    value_input = $('#js-value-input')
    add_composition_btn = $('#js-add-composition-btn')
    checked = $('#js-checkbox-service-type').prop('checked')
    if checked
      add_composition_btn.addClass('disabled')
      value_input.prop('disabled', true)
      value_input.val('R$ 0,00')

    $('#js-checkbox-div').on 'change', '#js-checkbox-service-type', ->
      checked = $(@).prop('checked')
      add_composition_btn.toggleClass('disabled')
      add_composition_btn.parent().toggleClass('disabled')
      value_input.prop('disabled', checked)
      value_input.val('R$ 0,00') if checked