import Comment from './comment.coffee'
import OccurrenceActionPhotos from './occurrence_action_photos'
import OccurrenceActionReject from './occurrence_action_reject.coffee'
import OccurrenceActionFinish from './occurrence_action_finish.coffee'
import OccurrenceFileUploadBase from '../occurrences/document_upload_base'

$(document).on 'turbolinks:load', ->
  new Comment().handler_events()
  new OccurrenceActionPhotos().handler_events()
  new OccurrenceActionReject().handler_events()
  new OccurrenceActionFinish().handler_events()

  new OccurrenceFileUploadBase(
    '.js-occurrence-action-photos-reject-dropzone',
    '.js-dropzone-preview-container-reject-occurrence-action',
    '.js-dropzone-document-preview-container-reject-occurrence-action',
    '.js-occurrence-action-reject-upload-button',
    '#js-dropzone-preview-template-reject-occurrence-action',
    'occurrence_action[files][]'
  ).call()

  new OccurrenceFileUploadBase(
    '.js_occurrence_action_form',
    '.js-dropzone-preview-container-occurrence-action',
    '.js-dropzone-document-preview-container-occurrence-action',
    '.js-occurrence-action-upload-button',
    '#js-dropzone-preview-template-occurrence-action',
    'occurrence_action[files][]',
    true
  ).call()

  new OccurrenceFileUploadBase(
    '.js-occurrence-action-photos-finish-dropzone',
    '.js-dropzone-preview-container-finish-occurrence-action',
    '.js-dropzone-document-preview-container-finish-occurrence-action',
    '.js-occurrence-action-finish-upload-button',
    '#js-dropzone-preview-template-finish-occurrence-action',
    'occurrence_action[files][]'
  ).call()
