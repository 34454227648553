import { handleFormValidations } from './utils/form_validations'

$(document).on('turbolinks:load', function () {
  handleFormValidations()

  $(document).on('click', '#checkbox-all-period-excel-analytics', function() {
    tooglePeriodFields()
  })

  $(document).on('change', '#js-excel-analytics-report-type-select', function() {
    const reportTypeElement = $(this);
    const formElement = $('#excel-analytics-form');
    const switchAllPeriod = $('.switch-checkbox-all-period-excel-analytics');
    const inputSwitchAllPeriod = $('#checkbox-all-period-excel-analytics');
    const containerFieldSetFilters = $('.fieldset-filters-container');
    const siffsIdsFilter = $('.request_siff_ids');
    const placesIdsCheckboxesCheked = $('.request_places_ids :checkbox:checked');
    const servicesIdsCheckboxesCheked = $('.request_services_ids :checkbox:checked')
    const siffsIdsCheckboxesCheked = $('.request_siff_ids :checkbox:checked');
    const warningContainers = $('.warning-container');

    formElement.attr('action', reportTypeElement.val() || '/reports/excel_analytics/');

    placesIdsCheckboxesCheked.trigger('click');
    servicesIdsCheckboxesCheked.trigger('click');
    siffsIdsCheckboxesCheked.trigger('click');
    containerFieldSetFilters.show();
    switchAllPeriod.removeClass('is-disabled');
    warningContainers.hide();
    siffsIdsFilter.show();

    if (/verified_methods_report|construction_site_occurrence_report/.test(formElement.attr('action'))) {
      if(!switchAllPeriod.hasClass('is-disabled')) {
        if (!inputSwitchAllPeriod.is(':checked')) inputSwitchAllPeriod.trigger('click');
        switchAllPeriod.addClass('is-disabled');

        warningContainers.show();
        containerFieldSetFilters.hide();
      }
    } else if (/services_schedule_report|planning_progress_report/.test(formElement.attr('action'))){
      siffsIdsFilter.hide();
    }
  })

  function tooglePeriodFields() {
    const inputSwitchAllPeriod = $('#checkbox-all-period-excel-analytics');

    if (inputSwitchAllPeriod.is(':checked')) {
      $('.request_begin_at, .request_end_at, .pickadate').addClass('is-disabled')
    } else {
      $('.request_begin_at, .request_end_at, .pickadate').removeClass('is-disabled')
    }
  }

})



