import StantMultiselectBox from "../../../utils/stant_multiselect_box.coffee"
import AjaxLoader from "../../../utils/ajax_loader.coffee"
import translations from "../../../translations"
import * as I18n from "../../../services/i18n"
import PrintChart from "./print_chart.coffee"

$(document).on 'turbolinks:load', ->
  checkbox_all_construction_site = $('.checkbox-all-construction-site');
  period_at                      = $('.period-at');
  print_button                   = $('#print-gantt')

  checkbox_all_construction_site.on 'click', ->
    period_at.toggleClass 'is-disabled'

  selectable_header   = I18n.t(translations(), 'schedules.index.form.services_ids')
  selection_header    = I18n.t(translations(), 'schedules.index.form.services_ids_selected', { item: I18n.t(translations(), 'schedules.index.form.services_ids') })
  custom_multi_select = new StantMultiselectBox('request_services_ids', 'schedules-request-multi-select', selectable_header, selection_header)
  custom_multi_select.add_listener()

  new PrintChart().activate()

  $('#request_form').on 'submit', ->
    new AjaxLoader().show();
    $('.btn-submit').addClass 'is-disabled'
