
export default class inspectionCardHandler {
  constructor() {
    this.fvsResultContainer = document.querySelector('.fvs-service-verification-table-result')
  }

  init() {
    clearCardContainer(this.fvsResultContainer)
  }
}

function clearCardContainer(fvsResultContainer) {
  const mutationObserver = new MutationObserver(() => {
    $('.panel-collapse.collapse').on('hidden.bs.collapse', function () {
      $(this).find('.sub-card-item').empty()
    })

    $('.panel-collapse.collapse').on('shown.bs.collapse', function () {
      Array.from($('.panel-collapse.collapse')).forEach((collapse) => {
        if (collapse != this) {
          $(collapse).find('.sub-card-item').empty()
        }
      })
    })

    $('.panel-collapse.collapse').on('hide.bs.collapse', function () {
      const ContainerCard = $(this).closest('.card')
      $(ContainerCard).find('.btn-view').attr('data-remote', false)
    })

    $('.panel-collapse.collapse').on('show.bs.collapse', function () {
      const ContainerCard = $(this).closest('.card')
      $(ContainerCard).find('.btn-view').attr('data-remote', true)
    })
  });

  if (fvsResultContainer) mutationObserver.observe(fvsResultContainer, { childList: true });
}