import translations from "../../translations"
import * as I18n from "../../services/i18n"
import ServiceCompositionSection from "./service_composition_section.coffee"
import CalculateValueFromServiceWithComposition from "./calculate_value_from_service_with_composition.coffee"
import ManageableService from "./manageable_service.coffee"
import MethodImageUploadHandler from './method_images_upload_handler'
import BuildActionButtons from '../servicos/build_action_buttons.js'
import ServicosHistory from './servicos_history.coffee'
import ServicesCreation from './services_creation.coffee'

$(document).on('turbolinks:load', function() {
  ServiceCompositionSection.handler_events()
  CalculateValueFromServiceWithComposition.handler_events()
  ManageableService.handler_events()

  new ServicosHistory().handler_events()
  new MethodImageUploadHandler().call()
  new BuildActionButtons().handler_events()
  new ServicesCreation().handler_events()


  $(document).on('click', '#form_servicos .nav-step-wrapper-01', function(){
    let html = `
   <a href="javascript:void(0)" class="servicos-history-button btn btn-secondary btn-small">
     <i class="icon new icon-back-in-time traceability-tooltip" tabindex="0" data-placement="left" data-toggle="tooltip"
       title="${I18n.t(translations(), 'servicos.form.tooltips.cadastro_rapido')}" >
     </i> 
   </a>
   `
    $('#btn-historico').html(html);
    $('.traceability-tooltip').tooltip();
    new ServicosHistory().handler_events()
  })

  document.addEventListener("turbolinks:before-cache", function() {
    $('[data-toggle="tooltip"]').tooltip('hide')
  });

  $(document).on('click', '#form_servicos .nav-step-wrapper-02', function(){
    let url = $(location).attr('href');
    let url_segments = url.split('/');
    let service_id = url_segments[4]
    let html =
        `
   <a href="${'/servicos/history/'+ service_id}" class=" btn btn-secondary btn-small">
     <i class="icon new icon-back-in-time traceability-tooltip" tabindex="0" data-placement="left" data-toggle="tooltip"
        title="${I18n.t(translations(), 'servicos.form.tooltips.itens_de_inspecao')}" >
     </i> 
   </a>
   `
    $('#btn-historico').html(html);
    $('.traceability-tooltip').tooltip();
  })

  $('.js-remove-method-image').on('confirmed', (e)=> {
    const target = $(e.currentTarget)
    const imageId = target.data('id')
    $(`#js-method-image-id-${imageId}`).remove()
    $(`#js-method-image-preview-${imageId}`).remove()
    $(`#js-method-image-gallery-preview-${imageId}`).remove()
  })

  $(document).on('blur', '.servico-item-observacao', (e) => {
    const item = $(e.currentTarget)
    $(`.btn-cancel-observacao-${item.data('order')}`).attr('data-confirm', I18n.t(translations(), 'servicos.form.cancel_confirm'))
  })

  $(document).on('confirmed', `.btn-cancel-observacao`, (e) => {
    const item = $(e.currentTarget)
    $(`.btn-cancel-observacao-${item.data('order')}`).removeAttr('data-confirm')
    item.parents(`.lightbox-observacao-${item.data('order')}`).modal('hide')
  })

  $(document).on('click', '.btn-cancel-observacao', (e) => {
    const item = $(e.currentTarget)
    let observacao = item.parents(`.lightbox-observacao-${item.data('order')}`).find('.servico-item-observacao-hide')
    let new_observacao = item.parents(`.lightbox-observacao-${item.data('order')}`).find(`.servico-item-observacao-${item.data('order')}`)
    new_observacao.val(observacao.val())
    
    if (!item.data('confirm')) {
      item.parents(`.lightbox-observacao-${item.data('order')}`).modal('hide')
    }
  })

  $(document).on('click', '.btn-confirm-observacao', (e) => {
    const item = $(e.currentTarget)
    const observacao = item.parents(`.lightbox-observacao-${item.data('order')}`).find('.servico-item-observacao-hide')
    const new_observacao = item.parents(`.lightbox-observacao-${item.data('order')}`).find(`.servico-item-observacao-${item.data('order')}`)
    observacao.val(new_observacao.val())
    $(`.clickable-${item.data('order')}`).text(I18n.t(translations(), 'servicos.form.editar_observacao'))
  })

  $(document).on('click', '.collapse-icon', function() {
    $('.parent-colapse').collapse('hide')
    const collapseTarget = $(this).data('target')
    $(collapseTarget).collapse('show')
  })

  $(document).on('keypress', '#items-list', (event) => {
    const enterKeyCode = 13
    return (event.charCode || event.keyCode) !== enterKeyCode
  })

  $('.dynamic-form').on('cocoon:after-remove', function(event, item) {
    if (item.hasClass('possible-value-div')) {
      item.find('input.possible-value-order').remove();
      item.find('input.dynamic-field-border-input').remove();
    } else {
      $('.dynamic-field-div').each(function() {
        let element = $(this)[0];
        if (element.style.display) {
          element.remove(); 
        }
      });
    }
  });

  $(document).on('click', '#checkbox-disable-mobile-status-change', function() {
    let checked = $(this).prop('checked')
    console.log('changed', checked)
    $('#disable-mobile-status-change-alert').toggleClass('hide', !checked)
  });
})
