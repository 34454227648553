export default StantMenu = ->
  @init = ->
    $('.collapse-menu .submenu li').each ->
      if $(this).hasClass('active')
        $('.submenu').hide()

    $('.submenu li').each ->
      if $(this).hasClass('active')
        $(this).parents('.has-sub').addClass 'open'

    $('.menu a').click ->
      click($(this))

  click = (input) ->
    prevMenu = ''
    if input.is(prevMenu)
      input.siblings('.submenu').slideToggle 250
      input.parent().toggleClass 'open'
    else
      if prevMenu
        $(prevMenu).siblings('.submenu').slideUp 250
        input.parent().removeClass 'open'

      input.siblings('.submenu').slideToggle 250
      $('.menu li').removeClass 'open'
      input.parent().toggleClass 'open'
      prevMenu = input
    return

  return
