import StantMultiselectBox from "../../../utils/stant_multiselect_box.coffee"
import StantCepSearch from "../../../utils/stant_cep_search.coffee"
import EntitySection from "./entity_section.coffee"
import EntityLinksSection from "./entity_links_section.coffee"
import MultisiteSection from "./multisite_section.coffee"
import ChangeMultisiteDate from "./change_multisite_date.coffee"
import DateInputError from "./date_input_error.coffee"
import translations from "../../../translations"
import * as I18n from "../../../services/i18n"

$(document).on 'turbolinks:load', ->
  selectable_header_obra  = I18n.t(translations(), 'obras.form.pessoas_ids')
  selection_header_obra = I18n.t(translations(), 'multi_select.selected_items', { item: I18n.t(translations(), 'obras.form.pessoas_ids')})

  custom_multi_select = new StantMultiselectBox('', 'obras-multi-select', selectable_header_obra, selection_header_obra)
  custom_multi_select.add_listener()

  new StantCepSearch()
    .add_listener(".consultar-cep", true)
    .add_input_listener("#cep_input_id", ".consultar-cep")

  new EntitySection().add_listener()

  new EntityLinksSection().add_listener()

  new MultisiteSection().handle_events()

  new ChangeMultisiteDate().handle_events()

  new DateInputError().add_listener()
