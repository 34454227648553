import Swal from "sweetalert2"
import CountFieldCharacters from 'utils/count_field_characters'
import translations from "translations"
import * as I18n from "services/i18n"
import { handleFormValidations } from './utils/form_validations'
import ReportHeaderHandler from './utils/report_header_handler'
import { endsWith } from "lodash"
import { rebuildSwitchElement } from "./utils/helpers"
import StantMultiSelectLevels from"../../../utils/stant_multiselect_levels.coffee"

$(document).on('turbolinks:load', function () {
  new StantMultiSelectLevels('locals-select-multiselectlevels', 'data-local_id').add_listener()
  
  $('.select-image-option, .btn-option').tooltip()

  $('.select-image-option, .btn-option').hover(
    function () {
      $(this).tooltip('show')
    },
    function () {
      $(this).tooltip('hide')
    }
  )

  new ReportHeaderHandler(['siff_list', 'occurrences_details']).start()
  handleRadioOptions()

  handleFormValidations()

  $(document).on('click', '.checkbox-all-period', function () {
    $(`.${this.dataset.period}`).toggleClass('is-disabled')
  })

  $(document).on('change', '#checkbox_all_options', function (event) {
    const status = $(this).is(':checked')
    const radioLabelColor = status ? '#3C3C46' : '#CECED5'
    const modalOptionColor = status ? '#3085d6' : '#CECED5'
    const optionValue = status ? 'modal' : ''

    $('.options-list').prop('checked', status)
    $('.reportsgenerator-radio-input').attr('disabled', !status)
    $('.reportsgenerator-radio-label').attr('disabled', !status).css('color', radioLabelColor)
    $('.btn-option').attr('disabled', !status).css('color', modalOptionColor).attr('data-toggle', optionValue)
    $('.select-image-option').attr('disabled', !status)
    if (status)
      $('.btn-link-disabled').removeClass('btn-link-disabled')
    else
    {
      $('.btn-option').addClass('btn-link-disabled')
      $('.select-image-option').addClass('btn-link-disabled')
    }
    

  })

  if ($('#checkbox_all_options').is(':checked')) {
    $('.reportsgenerator-radio-input').attr('disabled', false)
    $('.reportsgenerator-radio-label').attr('disabled', false).css('color', '#3C3C46')
    $('.btn-option').attr('disabled', false).css('color', '#3085d6').attr('data-toggle', 'modal')
    $('.select-image-option').attr('disabled', false)
  }

  $(document).on('click', '.options-list', function () {
    let status = false;
    $('#options_checkbox_list').find('.options-list').each(function () {
      if ($(this).is(':checked')) {
        $('#checkbox_all_options').prop('checked', true)
      } else {
        status = true;
      }
    })

    if (status) {
      $('#checkbox_all_options').prop('checked', false)
    }
  })

  $('#reset_reports_generator_form').on('click', function () {
    Swal.fire({
      title: `${I18n.t(translations(), 'reports.reports_generator.form.confirm_dialog_title')}?`,
      text: I18n.t(translations(), 'reports.reports_generator.form.confirm_dialog'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: I18n.t(translations(), 'reports.reports_generator.form.confirm_button'),
      cancelButtonText: I18n.t(translations(), 'reports.reports_generator.form.cancel_button')
    }).then((result) => {
      if (result.value) {
        $('#reports-generator-form')[0].reset();
        $('.ql-editor > p').text("");
        $('.ql-editor').attr("contenteditable", false);
        $('#reportsgenerator-comments').addClass('ql-disabled disabled-field')
        $('.characterscounter-current').text("0")
        $('.ms-selectall > .selected').each(function () {
          $(this).trigger()
        });
        $('.ms-options-wrap > button').text("");
        $('.radio-label').css('color', '#ceced5');
        $('.reportsgenerator-radio-input').attr('disabled', true);
        $('.selected').removeClass('selected');

        //Desabilita os campos para o modal
        $('.reportsgenerator-radio-input').attr('disabled', true).addClass('btn-link-disabled')
        $('.reportsgenerator-radio-label').attr('disabled', true).css('color', '#CECED5').addClass('btn-link-disabled')
        $('.btn-option').attr('disabled', true).css('color', '#CECED5').attr('data-toggle', '').addClass('btn-link-disabled')
        $('.select-image-option').attr('disabled', true).addClass('btn-link-disabled')
      }
    })
  })

  $(document).on('change', '.services_mode', function () {
    const servicesMode = $('.services_mode')[0].value
    const detailingByEmployeeSwitch = $('#checkbox_detailing_by_employee')

    if (servicesMode === 'plot') {
      detailingByEmployeeSwitch.attr('checked', false)
      detailingByEmployeeSwitch.attr('disabled', true)
    } else {
      detailingByEmployeeSwitch.attr('disabled', false)
    }

    rebuildSwitchElement(detailingByEmployeeSwitch)
  })

  $(document).on('change', '#checkbox_hide_quantity', function () {
    const checked = $(this).is(':checked')
    const checkboxQuantityByPeriod = $('#checkbox_quantity_by_period')

    if (checked) {
      checkboxQuantityByPeriod.attr('checked', false)
      rebuildSwitchElement(checkboxQuantityByPeriod)
    }
  })

  $(document).on('change', '#checkbox_quantity_by_period', function () {
    const checked = $(this).is(':checked')
    const checkboxHideQuantity = $('#checkbox_hide_quantity')

    if (checked) {
      checkboxHideQuantity.attr('checked', false)
      rebuildSwitchElement(checkboxHideQuantity)
    }
  })

  $(document).on('change', '#checkbox_hide_value', function () {
    const checked = $(this).is(':checked')
    const checkboxValueByPeriod = $('#checkbox_value_by_period')

    if (checked) {
      checkboxValueByPeriod.attr('checked', false)
      rebuildSwitchElement(checkboxValueByPeriod)
    }
  })

  $(document).on('change', '#checkbox_value_by_period', function () {
    const checked = $(this).is(':checked')
    const checkboxHideValue = $('#checkbox_hide_value')

    if (checked) {
      checkboxHideValue.attr('checked', false)
      rebuildSwitchElement(checkboxHideValue)
    }
  })
})

function handleRadioOptions() {
  const reportWithRadioOptions = ['siff_list', 'physical_execution', 'financial_execution',
    'construction_site_images', 'occurrences_details', 'services_schedule', 'occurrences_list', 'manageable_services']

  reportWithRadioOptions.forEach((reportWithRadioOption) => {
    verifyStateRadioOption(reportWithRadioOption)
    addListenerVerifyStateRadioOption(reportWithRadioOption)
  })
}

function addListenerVerifyStateRadioOption(module) {
  $(document).off('change', `#${module}`).on('change', `#${module}`, function (event) {
    verifyStateRadioOption(module)
  })
}

function verifyStateRadioOption(module) {
  let optionRadio = $(`.reportsgenerator-${module}-radio`)
  
  if ($(`#${module}`).is(":checked")) {
    optionRadio
      .find('.reportsgenerator-radio-input').attr('disabled', false).removeClass('btn-link-disabled')
  
    optionRadio
      .find('.reportsgenerator-radio-label').attr('disabled', false).removeClass('btn-link-disabled')
      .css('color', '#3C3C46')

    optionRadio
      .find('.btn-option').attr('disabled', false).removeClass('btn-link-disabled')
      .css('color', '#3085d6')
      .attr('data-toggle', 'modal')
    optionRadio
      .find('.select-image-option').attr('disabled', false).removeClass('btn-link-disabled')
  } else {
    optionRadio
      .find('.reportsgenerator-radio-input').attr('disabled', true).addClass('btn-link-disabled')
    
    optionRadio
      .find('.reportsgenerator-radio-label').attr('disabled', true).addClass('btn-link-disabled')
      .css('color', '#CECED5')
    
    optionRadio
      .find('.btn-option').attr('disabled', true).addClass('btn-link-disabled')
      .css('color', '#CECED5')
      .attr('data-toggle', '')
    optionRadio
      .find('.select-image-option').attr('disabled', true).addClass('btn-link-disabled')
  }
}

$(document).on("change", "#checkbox-only_internal", function () {
  var checked = $(this).is(':checked');
  var external = $('#checkbox-only_external').is(':checked')

  if (checked && external) {
    $('#checkbox-only_external').click();
  }
});

$(document).on("change", "#checkbox-only_external", function () {
  var checked = $(this).is(':checked');
  var internal = $('#checkbox-only_internal').is(':checked')

  if (checked && internal) {
    $('#checkbox-only_internal').click();
  }
});

