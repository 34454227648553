import Comment from './comment.coffee'
import BuildSteps from '../../utils/build_steps.coffee'
import OccurrencePhotos from './occurrence_photos'
import OccurrenceFileUploadBase from './document_upload_base'

$(document).on 'turbolinks:load', ->
  new OccurrencePhotos().handler_events()
  new Comment().handler_events()
  new BuildSteps('js-occurrence',[]).handler_events()

  new OccurrenceFileUploadBase(
    '.js_occurrence_photos_form',
    '.js-dropzone-preview-container-occurrence-photos',
    '.js-dropzone-document-preview-container-occurrence',
    '.js-occurrence-photos-upload-button',
    '#js-dropzone-preview-template-occurrence-photos',
    'occurrence[files][]',
    true
  ).call()
