/*
 MIT
*/
(function(g){g.fn.sortableLists=function(k){function n(a){if(b.isDragged){var f=b.cEl,d=b.doc,u=b.win;a.pageX||A(a);d.scrollTop()>b.rootEl.offset.top-10&&50>a.clientY?b.upScroll?(a.pageY-=c.scroll,g("html, body").each(function(a){g(this).scrollTop(g(this).scrollTop()-c.scroll)}),r(a)):e(a):d.scrollTop()+u.height()<b.rootEl.offset.top+b.rootEl.el.outerHeight(!1)+10&&50>u.height()-a.clientY?b.downScroll?(a.pageY+=c.scroll,g("html, body").each(function(a){g(this).scrollTop(g(this).scrollTop()+c.scroll)}),
r(a)):l(a):x(b);b.oElOld=b.oEl;f.el[0].style.visibility="hidden";b.oEl=oEl=B(a.pageX,a.pageY);f.el[0].style.visibility="visible";C(a,b);D(a,b)}}function m(a){var q=b.cEl,d=g("#sortableListsHint",b.rootEl.el),u=f[0].style,h=null,e=!1,k=g("#sortableListsHintWrapper");"block"==u.display&&d.length&&b.isAllowed?(h=d,e=!0):(h=b.placeholderNode,e=!1);offset=h.offset();q.el.animate({left:offset.left-b.cEl.mL,top:offset.top-b.cEl.mT},250,function(){E(q);h.after(q.el[0]);h[0].style.display="none";u.display=
"none";d.remove();k.removeAttr("id").removeClass(c.hintWrapperClass);k.length&&k.prev("div").append(t.clone(!0));e?b.placeholderNode.slideUp(150,function(){b.placeholderNode.remove();y();c.onChange(q.el);c.complete(q.el);b.isDragged=!1}):(b.placeholderNode.remove(),y(),c.complete(q.el),b.isDragged=!1)});x(b);b.doc.unbind("mousemove",n).unbind("mouseup",m)}function e(a){b.upScroll||(b.upScroll=setInterval(function(){b.doc.trigger("mousemove")},50))}function l(a){b.downScroll||(b.downScroll=setInterval(function(){b.doc.trigger("mousemove")},
50))}function r(a){b.pY=a.pageY;b.pX=a.pageX;b.cY=a.clientY;b.cX=a.clientX}function A(a){a.pageY=b.pY;a.pageX=b.pX;a.clientY=b.cY;a.clientX=b.cX}function x(a){clearInterval(a.upScroll);clearInterval(a.downScroll);a.upScroll=a.downScroll=!1}function D(a,b){var c=b.cEl;c.el.css({top:a.pageY-c.xyOffsetDiff.Y-c.mT,left:a.pageX-c.xyOffsetDiff.X-c.mL})}function B(a,c){if(!document.elementFromPoint)return null;var d=b.isRelEFP;if(null===d){var f,h;0<(f=b.doc.scrollTop())&&(d=null==(h=document.elementFromPoint(0,
f+g(window).height()-1))||"HTML"==h.tagName.toUpperCase());0<(f=b.doc.scrollLeft())&&(d=null==(h=document.elementFromPoint(f+g(window).width()-1,0))||"HTML"==h.tagName.toUpperCase())}d&&(a-=b.doc.scrollLeft(),c-=b.doc.scrollTop());d=g(document.elementFromPoint(a,c));if(b.rootEl.el.find(d).length){if(d.is("#sortableListsPlaceholder")||d.is("#sortableListsHint"))return null;if(!d.is("li"))return d=d.closest("li"),d[0]?d:null;if(d.is("li"))return d}else return null}function C(a,q){var d=q.oEl;if(d&&
q.oElOld){var e=d.outerHeight(!1),h=a.pageY-d.offset().top;if(c.insertZonePlus)if(14>h)a:{e=7>h;g("#sortableListsHintWrapper",b.rootEl.el).length&&f.unwrap();if(!e&&a.pageX-d.offset().left>c.insertZone){e=d.children();h=d.children(c.listSelector).first();if(h.children().first().is("#sortableListsPlaceholder")){f.css("display","none");break a}h.length?h.prepend(f):(e.first().after(f),f.wrap(v));b.oEl&&p(d)}else{if(d.prev("#sortableListsPlaceholder").length){f.css("display","none");break a}d.before(f)}f.css("display",
"block");b.isAllowed=c.isAllowed(b.cEl.el,f,f.parents("li").first())}else{if(e-14<h)a:{e=e-7<h;g("#sortableListsHintWrapper",b.rootEl.el).length&&f.unwrap();if(!e&&a.pageX-d.offset().left>c.insertZone){e=d.children();h=d.children(c.listSelector).last();if(h.children().last().is("#sortableListsPlaceholder")){f.css("display","none");break a}h.length?e.last().append(f):(d.append(f),f.wrap(v));b.oEl&&p(d)}else{if(d.next("#sortableListsPlaceholder").length){f.css("display","none");break a}d.after(f)}f.css("display",
"block");b.isAllowed=c.isAllowed(b.cEl.el,f,f.parents("li").first())}}else if(5>h)a:{g("#sortableListsHintWrapper",b.rootEl.el).length&&f.unwrap();if(a.pageX-d.offset().left<c.insertZone){if(d.prev("#sortableListsPlaceholder").length){f.css("display","none");break a}d.before(f)}else{e=d.children();h=d.children(c.listSelector).first();if(h.children().first().is("#sortableListsPlaceholder")){f.css("display","none");break a}h.length?h.prepend(f):(e.first().after(f),f.wrap(v));b.oEl&&p(d)}f.css("display",
"block");b.isAllowed=c.isAllowed(b.cEl.el,f,f.parents("li").first())}else if(e-5<h)a:{g("#sortableListsHintWrapper",b.rootEl.el).length&&f.unwrap();if(a.pageX-d.offset().left<c.insertZone){if(d.next("#sortableListsPlaceholder").length){f.css("display","none");break a}d.after(f)}else{e=d.children();h=d.children(c.listSelector).last();if(h.children().last().is("#sortableListsPlaceholder")){f.css("display","none");break a}h.length?e.last().append(f):(d.append(f),f.wrap(v));b.oEl&&p(d)}f.css("display",
"block");b.isAllowed=c.isAllowed(b.cEl.el,f,f.parents("li").first())}}}function p(a){a.removeClass("sortableListsClosed").addClass("sortableListsOpen");a.children(c.listSelector).css("display","block");a=a.children("div").children(".sortableListsOpener").first();"html"==c.opener.as?a.html(c.opener.close):"class"==c.opener.as?a.addClass(c.opener.close).removeClass(c.opener.open):a.css("background-image","url("+c.opener.close+")")}function z(a){a.removeClass("sortableListsOpen").addClass("sortableListsClosed");
a.children(c.listSelector).css("display","none");a=a.children("div").children(".sortableListsOpener").first();"html"==c.opener.as?a.html(c.opener.open):"class"==c.opener.as?a.addClass(c.opener.open).removeClass(c.opener.close):a.css("background-image","url("+c.opener.open+")")}function E(a){var b=a.el[0].style;a.el.removeClass(c.currElClass+" sortableListsCurrent");b.top="0";b.left="0";b.position="relative";b.width="auto"}function y(){g(c.listSelector,b.rootEl.el).each(function(a){g(this).children().length||
(g(this).prev("div").children(".sortableListsOpener").first().remove(),g(this).remove())})}var w=g("body").css("position","relative"),F={currElClass:"",placeholderClass:"",placeholderCss:{position:"relative",padding:0},hintClass:"",hintCss:{display:"none",position:"relative",padding:0},hintWrapperClass:"",hintWrapperCss:{},baseClass:"",baseCss:{position:"absolute",top:0-parseInt(w.css("margin-top")),left:0-parseInt(w.css("margin-left")),margin:0,padding:0,"z-index":2500},opener:{active:!1,open:"",
close:"",openerCss:{"float":"left",display:"inline-block","background-position":"center center","background-repeat":"no-repeat"},openerClass:""},listSelector:"ul",listsClass:"",listsCss:{},insertZone:50,insertZonePlus:!1,scroll:20,ignoreClass:"",isAllowed:function(a,b,c){return!0},onDragStart:function(a,b){return!0},onChange:function(a){return!0},complete:function(a){return!0}},c=g.extend(!0,{},F,k),G=g("<"+c.listSelector+" />").prependTo(w).attr("id","sortableListsBase").css(c.baseCss).addClass(c.listsClass+
" "+c.baseClass),H=g("<li />").attr("id","sortableListsPlaceholder").css(c.placeholderCss).addClass(c.placeholderClass),f=g("<li />").attr("id","sortableListsHint").css(c.hintCss).addClass(c.hintClass),v=g("<"+c.listSelector+" />").attr("id","sortableListsHintWrapper").addClass(c.listsClass+" "+c.hintWrapperClass).css(c.listsCss).css(c.hintWrapperCss),t=g("<span />").addClass("sortableListsOpener "+c.opener.openerClass).css(c.opener.openerCss).on("mousedown",function(a){a=g(this).closest("li");a.hasClass("sortableListsClosed")?
p(a):z(a);return!1});"class"==c.opener.as?t.addClass(c.opener.close):"html"==c.opener.as?t.html(c.opener.close):(t.css("background-image","url("+c.opener.close+")"),console.error("jQuerySortableLists opener as background image is deprecated. In version 2.0.0 it will be removed. Use html instead please."));var b={isDragged:!1,isRelEFP:null,oEl:null,rootEl:null,cEl:null,upScroll:!1,downScroll:!1,pX:0,pY:0,cX:0,cY:0,isAllowed:!0,e:{pageX:0,pageY:0,clientX:0,clientY:0},doc:g(document),win:g(window)};
if(c.opener.active){if(!c.opener.open)throw"Opener.open value is not defined. It should be valid url, html or css class.";if(!c.opener.close)throw"Opener.close value is not defined. It should be valid url, html or css class.";g(this).find("li").each(function(){var a=g(this);a.children(c.listSelector).length&&(t.clone(!0).prependTo(a.children("div").first()),a.hasClass("sortableListsOpen")?p(a):z(a))})}return this.on("mousedown",function(a){var e=g(a.target);if(!(!1!==b.isDragged||c.ignoreClass&&e.hasClass(c.ignoreClass))){a.preventDefault();
var e=e.closest("li"),d=g(this);if(e[0]){c.onDragStart(a,e);b.isDragged=!0;var k=parseInt(e.css("margin-top")),h=parseInt(e.css("margin-bottom")),l=parseInt(e.css("margin-left")),r=parseInt(e.css("margin-right")),p=e.offset(),t=e.innerHeight();b.rootEl={el:d,offset:d.offset(),rootElClass:d.attr("class")};b.cEl={el:e,mT:k,mL:l,mB:h,mR:r,offset:p};b.cEl.xyOffsetDiff={X:a.pageX-b.cEl.offset.left,Y:a.pageY-b.cEl.offset.top};b.cEl.el.addClass("sortableListsCurrent "+c.currElClass);e.before(H);a=b.placeholderNode=
g("#sortableListsPlaceholder");e.css({width:e.width(),position:"absolute",top:p.top-k,left:p.left-l}).prependTo(G);a.css({display:"block",height:t});f.css("height",t);b.doc.on("mousemove",n).on("mouseup",m)}}})};g.fn.sortableListsToArray=function(k,n){k=k||[];var m=0;this.children("li").each(function(){var e=g(this),l={},r=e.attr("id");if(!r)throw console.log(e),"Previous item in console.log has no id. It is necessary to create the array.";l.id=r;l.parentId=n;l.value=e.data("value");l.order=m;k.push(l);
e.children("ul,ol").sortableListsToArray(k,r);m++});return k};g.fn.sortableListsToHierarchy=function(){var k=[],n=0;g(this).children("li").each(function(){var m=g(this),e={},l=m.attr("id");if(!l)throw console.log(m),"Previous item in console.log has no id. It is necessary to create the array.";e.id=l;e.value=m.data("value");e.order=n;k.push(e);e.children=m.children("ul,ol").sortableListsToHierarchy();n++});return k};g.fn.sortableListsToString=function(k,n){k=k||[];n=n||"no-parent";g(this).children("li").each(function(){var m=
g(this),e=m.attr("id"),e=e?e.match(/(.+)[-=_](.+)/):null;if(!e)throw console.log(m),"Previous item in console.log has no id or id is not in required format xx_yy, xx-yy or xx=yy. It is necessary to create valid string.";k.push(e[1]+"["+e[2]+"]="+n);g(this).children("ul,ol").sortableListsToString(k,e[2])});return k.join("&")}})(jQuery);
