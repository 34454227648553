export default StantSteps = ->

  @init = ->
    $(".avancar-form").on 'click', ->
      step = $(this).attr("step")
      changeStepTo(parseFloat(step))

    $(".avancar-form-2").on 'click', ->
      step = $(this).attr("step")
      changeStepTo(parseInt(step))

    $(".voltar-form").on 'click', ->
      step = $(this).attr("step")
      if $('#step01').hasClass('active')
        window.history.back()
      else
        changeStepTo parseFloat(step)

    $(".voltar-form-1-2").on 'click', ->
      step = $(this).attr("step")
      changeStepTo(parseFloat(step))

    $(".voltar-form-2").on 'click', ->
      step = $(this).attr("step")
      changeStepTo(parseInt(step))

    $(".nav-step-01").on 'click', ->
      changeStepTo(1)

    $(".nav-step-1-2").on 'click', ->
      changeStepTo(1.2)

    $(".nav-step-02").on 'click', ->
      changeStepTo(2)

    $(".nav-step-03").on 'click', ->
      changeStepTo(3)

    addSubmitListener()

  changeStepTo = (step) ->
    $(".step-01").css 'display', 'none'
    $(".step-1-2").css 'display', 'none'
    $(".step-02").css 'display', 'none'
    $(".step-03").css 'display', 'none'
    $(".nav-step-01 .circle span").removeClass 'checked'
    $(".nav-step-1-2 .circle span").removeClass 'checked'
    $(".nav-step-02 .circle span").removeClass 'checked'
    $(".nav-step-wrapper-01").removeClass 'active'
    $(".nav-step-wrapper-1-2").removeClass 'active'
    $(".nav-step-wrapper-02").removeClass 'active'
    $(".nav-step-wrapper-03").removeClass 'active'

    if step == 1
      $(".step-01").css 'display', 'block'
      $(".nav-step-wrapper-01").addClass 'active'

    if step == 1.2
      $(".step-1-2").css 'display', 'block'
      $(".nav-step-01 .circle span").addClass 'checked'
      $(".nav-step-wrapper-1-2").addClass 'active'

    else if step == 2
      $(".step-02").css 'display', 'block'
      $(".nav-step-wrapper-02").addClass 'active'
      $(".nav-step-01 .circle span").addClass 'checked'
      $(".nav-step-1-2 .circle span").addClass 'checked'

    else if step == 3
      $(".step-03").css 'display', 'block'
      $(".nav-step-02 .circle span").addClass 'checked'
      $(".nav-step-01 .circle span").addClass 'checked'
      $(".nav-step-1-2 .circle span").addClass 'checked'
      $(".nav-step-wrapper-03").addClass 'active'

  addSubmitListener = ->
    $('.steps-form').on 'click', '.steps-submit', ->
      changeStepTo(1)

  return
