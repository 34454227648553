import "select2"

$(document).on 'turbolinks:load', ->
  $('.act-as-taggable').each ->
    $this = $(this)
    saved_tags = $this.data('saved')
    maximum_selection_size = $this.data('maximum-selection-size') || 0

    options =
      tags: true
      multiple: true
      maximumSelectionSize: maximum_selection_size
      initSelection : (element, callback) ->
        saved_tags = for tag in saved_tags
          JSON.parse({id: tag.id, name: tag.titulo})

        saved_tags && callback(saved_tags)
      formatResult: (item, page) ->
        return item.name
      formatSelection: (item, page) ->
        return item.name

    $this.select2(options)
