const set_url_for_upload_images_options = (form, id) => {
  const form_url = form.prop("action")
  form.prop("dropzone").options.url = form_url.replace(":js_id", id)
}

const show_static_modal_and_toggle_button_visibility = (modal_id) => {
  $(modal_id).modal({
    keyboard: false,
    show: true
  })

  $(".js-occurrence-dropzone-upload-link").show()
  $('.js-occurrence-dropzone-close-modal-link').hide()
}

const process_upload_queue = (upload_element) => {
  upload_element.prop("dropzone").processQueue()
}

const handle_done_modal_button_click = (redirect_path) => {
  $(".js-occurrence-dropzone-upload-link").on('click', () => {
    Turbolinks.visit(redirect_path)
  })
}

const format_redirect_path_and_add_listener_only_on_queuecomplete = (form, redirect_path, success_url) => {
  const dropzone_instance = form.prop('dropzone')
  dropzone_instance.on('queuecomplete', () => {
    const success_count = dropzone_instance.getFilesWithStatus('success').length
    let file_response = dropzone_instance.files[0].xhr.response
    const message_url = unpermitted_user(file_response) ? fail_url() : success_url
    const extra_url = success_count > 0 ? message_url : ''

    handle_done_modal_button_click(`${redirect_path}${extra_url}`)
  })
}

const unpermitted_user = (response) => {
  return response.substring(64, 77) == 'sem-permissao' ? true : false
}

const fail_url = () => {
  return '?message_type=document_uploaded_failure'
}

const select_severity_level = (suggested_severity_level, js_occurrence_severity_level_select) => {
  if (suggested_severity_level) {
    $(`option:contains(${suggested_severity_level})`).prop('selected', true)
  } else {
    js_occurrence_severity_level_select.prop("selected", false)
  }
}

const select_severity_level_page_render = () => {
  let suggested_severity_level = $('#js_occurrence_type_id option:selected').attr('data-suggested_severity_level');
  let js_occurrence_severity_level_select = $('#js_occurrence_severity_level option:selected');
  select_severity_level(suggested_severity_level, js_occurrence_severity_level_select);
}

const set_description_with_suggested = (suggested_description, js_occurrence_description_input) => {
    let new_description = js_occurrence_description_input.val() + suggested_description;
    js_occurrence_description_input.val(new_description);
}

const set_description_with_suggested_page_render = () => {
  let suggested_description = $('#js_occurrence_type_id option:selected').attr('data-suggested_description');
  let js_occurrence_description_input = $('#js_occurrence_description');
  set_description_with_suggested(suggested_description, js_occurrence_description_input);
}

export {
  set_url_for_upload_images_options,
  show_static_modal_and_toggle_button_visibility,
  handle_done_modal_button_click,
  process_upload_queue,
  format_redirect_path_and_add_listener_only_on_queuecomplete,
  set_description_with_suggested,
  set_description_with_suggested_page_render
}
