export default class FvsServiceVerificationRequestSessionStorage
  save_request: ->
    request =
      construction_site_id: ''
      all_fvs_period:       'false'
      hide_inapplicable:    0
      fvs_begin_at:         ''
      fvs_end_at:           ''
      services_ids:         []
      fvs_situations:       []
      places_ids:           []
      responsible_ids:      []
      finishers_ids:        []
      approvers_ids:        []
      siffs_ids:            []

    form_object = if $('#request_form').length > 0 then $('#request_form') else $('#request_form_fvs_service_verification')

    $.each form_object.serializeArray(), (i, field) ->
      regex_is_request = /request/
      return unless regex_is_request.test field.name

      regex_is_array       = /\[]/g
      regex_when_array     = /(request\[)(\w*)(]\[])/g
      regex_when_not_array = /(request\[)(\w*)(])/g

      if regex_is_array.test field.name
        attr = field.name.replace regex_when_array, "$2"
        request[attr].push field.value
      else
        attr = field.name.replace regex_when_not_array, "$2"
        request[attr] = field.value

    sessionStorage.setItem 'request', JSON.stringify request

  @get_request: ->
    JSON.parse sessionStorage.getItem 'request'

window.FvsServiceVerificationRequestSessionStorage = FvsServiceVerificationRequestSessionStorage
