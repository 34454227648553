import translations from "../../translations"
import * as I18n from "../../services/i18n"
import StantMultiselectBox from "../../utils/stant_multiselect_box.coffee"
import StantMultiselect from "../../utils/stant_multiselect.coffee"

window.DataAccessHelper = class DataAccessHelper
  @set_multiselect_data_access: ->
    selectable_header_object  = I18n.t(translations(), 'data_access/components/new_group_modal.users')
    selection_header_object   = I18n.t(translations(), 'data_access/components/new_group_modal.users_selecteds')

    custom_multi_select = new StantMultiselectBox('allowed_users_ids', 'data-access-multi-select', 
      selectable_header_object, selection_header_object)

    custom_multi_select.add_listener()

    custom_multi_select_new_group = new StantMultiselect('new-group-multiselect')
    custom_multi_select_new_group.add_listener()
  
  @build_option: (data) ->
    select = document.querySelector('#data_access_allowed_data_access_group_id')
    option = document.createElement('option')
    option.text = data.description
    option.value = data.id
    option.selected = 'selected'
    select.add(option)
