import "javascripts/bootstrap-treeview/bootstrap-treeview"

export default StantTreeview = (data) ->
  @data = data

  @options =
    expandIcon: "icon icon-arrow-collapse rotate",
    collapseIcon: "icon icon-arrow-collapse",
    backColor: "#fff",
    onhoverColor: "#ec7234",
    highlightSelected: true,
    selectedColor: "#ea641f",
    selectedBackColor: "#fff",
    enableLinks: true,
    data: @data

  @init = ->
    $('#treeview').treeview @options

  return

window.StantTreeview = StantTreeview
