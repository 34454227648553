options = ->
  maxDepth: 2

lastOrderItemIndex = ->
  max = 0
  $(".list-item .order-item").map ->
    order = Number($(this).val())
    max = order unless max > order
  max + 1

remapOrderItemIndex = ->
  max = 1
  $(".list-item .order-item").map ->
    input = $(this)
    input.val(max)
    max = max + 1

remapOrderMetodoIndex = ->
  $(".list-item").each ->
    max = 1
    $(this).find('.order-metodo').map ->
      input = $(this)
      input.val(max)
      max = max + 1

changeHrefLinkLightbox = ->
  order = 0
  $(".list-item .lightbox-ajax").map ->
    $(this).attr("href", "#lightbox_" + order)
    order = order + 1

changeIdLightBox = ->
  order = 0
  $(".list-item .lightbox-observacao").map ->
    $(this).attr("id", "lightbox_" + order)
    order = order + 1

changeIdAndHrefLightbox = ->
  changeHrefLinkLightbox()
  changeIdLightBox()

findOrderItemAndSetLastOrderIndex = (insertedItem) ->
  maxOrder = lastOrderItemIndex()
  input = insertedItem.find('.order-item')
  input.val(maxOrder)
  maxOrder

setFocus = (insertedItem) ->
  insertedItem.find('.item-description').focus()
  if insertedItem.find('.metodo-description')
    insertedItem.find('.metodo-description').focus()

$(document).on 'turbolinks:load', ->
  options =
    opener:
      active: true
      as: 'html'
      close: '<button class="btn btn-link">-</button>'
      open: '<button class="btn btn-link">+</button>'
    ignoreClass: 'clickable'
    listsClass: 'dd-item'
    placeholderCss: {'border':'1px dashed #b6bcbf'}
    hintCss: {'border':'1px dashed #ec7234'}
    onChange: (element) ->
      remapOrderItemIndex()
      remapOrderMetodoIndex()
    isAllowed: (currEl, hint, target) ->
      if currEl.data('type') == 'item' && target.data('type') == 'item'
        hint.css('background-color', '#E0E0E0')
        false
      else if currEl.data('type') == 'item' && target.data('type') == 'method'
        hint.css('background-color', '#E0E0E0')
        false
      else
        hint.css('background-color', '#FFF')
        true

  $('#items-list').off().sortableLists(options)
  changeIdAndHrefLightbox()

  $("#items-list").on "cocoon:after-insert", (e, insertedItem) ->
    findOrderItemAndSetLastOrderIndex(insertedItem)
    setFocus(insertedItem)
    changeIdAndHrefLightbox()
    remapOrderItemIndex()
    remapOrderMetodoIndex()

    $("#items-list").on "cocoon:after-remove", (e, insertedItem) ->
      remapOrderItemIndex()

  $('.avancar-form-2').on 'click', ->
    service_title = $("#service_title").val()

    $("#title_item_inspecao").append(" - (" + service_title + ")") if service_title && service_title.length > 0
