import HistoryBuilder from "../../../utils/history_builder.coffee"
import translations from "../../../translations"
import * as I18n from "../../../services/i18n"

export default class StepHistory
  handler_events: ->
    handle_on_click_history_button()

  handle_on_click_history_button = ->
    $('.step-history-button').on 'click', ->
      histories = $(@).closest('[data-histories]').data('histories')
      html = new HistoryBuilder(histories, build_history_template).build_history_html()
      $('#layout_modal_ .layout-modal-content').html(html)
      $('#layout_modal_').modal('show')

  build_history_template = (history) ->
    name = history.historyable_json.name
    start_at = format_date(history.historyable_json.start_at)
    finish_at = format_date(history.historyable_json.finish_at)
    observation = history.historyable_json.observation

    """
      <div class="history-item">
        <h4> #{format_date(history.created_at)} </h4>
        <p> #{I18n.t(translations(), 'atividades.index_multisites.name')}: #{name} </p>
        <p> #{I18n.t(translations(), 'atividades.index_multisites.start_at')}: #{start_at} </p>
        <p> #{I18n.t(translations(), 'atividades.index_multisites.finish_at')}: #{finish_at} </p>
        <p> #{I18n.t(translations(), 'atividades.index_multisites.observation')}: #{observation} </p>
        <p> #{I18n.t(translations(), 'atividades.index_multisites.user')}: #{history.person_name} </p>
        <hr>
      </div>
    """

  format_date = (date) ->
    dateObj = new Date(date)
    month = dateObj.getUTCMonth() + 1
    day = dateObj.getUTCDate()
    year = dateObj.getUTCFullYear()

    newdate = day + "/" + month + "/" + year
