export default class AjaxLoader
  constructor: (@ajax_class = 'loader-spinner') ->
    @ajax_class = ".#{@ajax_class}"

  show: (custom_class) ->
    $(build_complete_class_name.call(@, custom_class)).css 'display', 'inline-block'

  hide: (custom_class) ->
    $(build_complete_class_name.call(@, custom_class)).css 'display', 'none'

  show_and_build_loader: () ->
    loader_element = """<span class="loader loader-spinner"></span>"""
    target_container = $('[data-loader-target="loader"]').append(loader_element)
    add_listener_loader_and_disable_button()

  hide_and_enable_button: (custom_class) ->
    new AjaxLoader().hide()
    $('[data-loader-show="loader"]').attr('disabled', false)

  # ---- Private methods ----
  
  add_listener_loader_and_disable_button = ->
    $('[data-loader-show="loader"]').on 'click', (e) ->
      milliseconds = 13
      setTimeout((-> @.disabled = true).bind(@), milliseconds)
      new AjaxLoader().show()

  build_complete_class_name = (custom_class) ->
    complete_class = @ajax_class
    if custom_class != null && custom_class != undefined
      complete_class += '.' + custom_class

    complete_class

window.AjaxLoader = AjaxLoader
