import StantFormat from "../../../utils/stant_formt.coffee"

function convertToReal(number) {
  const format = { 
    minimumFractionDigits: 2,
    style: 'currency', 
    currency: 'BRL' 
  }

  return number.toLocaleString('pt-BR', format)
}

$(document).on('turbolinks:load', function () {
  if ($('.entry-placeitem').length != 0) {
    const totalColumn = Array.from($('.total .value'))
    const totalBalanceDiv = $('.total-balance')
    const totalBalanceSpan = $('.value-total-items')[0]

    totalColumn.map((totalItem, index) => {
      const valueItem = $(`#item-value-${index}`)[0].innerText
      const quantityItem = $(`#item-quantity-${index}`)[0].innerText
      const totalValue = StantFormat.converteMoedaFloat(valueItem) * parseInt(quantityItem)

      totalItem.innerText = convertToReal(totalValue)
    })

    const totalPositive = Array.from($(`.total.amount.-credit span`)).map((value) => StantFormat.converteMoedaFloat(value.innerText))
    const totalNegative = Array.from($(`.total.amount.-debit span`)).map((value) => StantFormat.converteMoedaFloat(value.innerText))

    const totalPositiveResult = totalPositive.length > 0 ? 
    totalPositive.reduce((total, value) => total + value) : 0
 
    const totalNegativeResult = totalNegative.length > 0 ?
    totalNegative.reduce((total, value) => total + value) : 0

    totalBalanceSpan.innerText = convertToReal(totalPositiveResult - totalNegativeResult)

    if ((totalPositiveResult - totalNegativeResult) < 0) {
      totalBalanceDiv.addClass('-debit')
    } else {
      totalBalanceDiv.addClass('-credit')
    }
  }
})
