import StantCepSearch from "../../utils/stant_cep_search.coffee"
import AjaxLoader from "../../utils/ajax_loader.coffee"
import Error from "../../utils/error.coffee"

selectCreatedPessoa = (pessoa) ->
  $('.obras-multi-select').multiSelect 'addOption',
      value: pessoa['id']
      text: pessoa['nome']
      index: 0

  $('#obra_obra_pessoa_ids').append "<option value='#{pessoa['id']}' >#{pessoa['nome']}</option>"

  $('.obras-multi-select').multiSelect 'select', '' +pessoa['id']

clearFields = ->
  $(':input', '#pessoa_form').not(':button, :submit, :reset, :hidden').val ''

$(document).on "turbolinks:load", ->
  $('.mask-money').maskMoney('mask')
  $('#pessoa_fisica_form').submit ->
    $('.mask-phone').unmask()

  $("#pessoa_form").on 'ajax:success', (e) ->
    [data, status, xhr] = event.detail
    clearFields()
    $("#pessoa_lightbox").modal('hide')
    Error.clear_global_errors()
    selectCreatedPessoa(data)
  $("#pessoa_form").on 'ajax:error', (e) ->
    [data, status, xhr] = event.detail
    Error.clear_global_errors()
    errors = data

    for key of errors
      idClass = 'pessoa_pessoa_' + key
      msg = errors[key][0]
      new Error(idClass, msg).show()

  # Change person type tab
  $('a[data-toggle="tab"]').on 'shown.bs.tab', (e) ->
    $('a[data-toggle="tab"]').removeClass('active')
    $(@).addClass('active')

    $('#cnpj-search-form').show()
    $('.person-form').hide()
    $('.voltar-form').click()
    Error.clear_global_errors

  # New Company
  $('#empresa_form').on 'ajax:success', () ->
    $('#company_lightbox').modal('hide')
    cnpj = $('input#empresa_empresa_cnpj').val().replace(/[^\d]+/g,'')
    $('#cnpj-search-form .cnpj').val(cnpj)
    $('#cnpj-search-form .search').click()

  $('#position_form').on 'ajax:beforeSend', ->
    new AjaxLoader().show()

  $('#position_form').on 'ajax:success', (e) ->
    [data, status, xhr] = event.detail
    select = document.getElementById('cargo_select')
    option = document.createElement("option")
    option.text = data.titulo
    option.value = data.id
    select.add(option)
    $('.cargo-lightbox-cancel').click()
    $('#cargo_select').select2('data', {id: data.id, text: data.titulo});
    new AjaxLoader().hide()

  $('#position_form').on 'ajax:error', ->
    new AjaxLoader().hide()

  new StantCepSearch('#pj_cep_input', '#pj_endereco_input', '#pj_bairro_input', '#pj_cidade_input', '#pj_uf_input').add_listener('.consultar-cep')
  new StantCepSearch('#pf_cep_input', '#pf_endereco_input', '#pf_bairro_input', '#pf_cidade_input', '#pf_uf_input').add_listener('.consultar-cep')
