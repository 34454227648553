import StantMasks from "../../utils/stant_masks.coffee"
import AjaxLoader from "../../utils/ajax_loader.coffee"
import StantConfigureDecimal from "../../utils/stant_configure_decimal.coffee"
import CalculateValueFromServiceWithComposition from "./calculate_value_from_service_with_composition.coffee"

export default class ServiceCompositionSection
  total_service_input = '.servico-valor'
  total_composition = '.total-composition:visible'
  container_composition = '.composition-section'
  button_add = '.add_composition_fields'
  button_remove = '.remove-composition-fields'
  select = '.composition_select'

  @handler_events: ->
    handle_add_click()
    handle_remove_click()
    handle_change_select()

  handle_add_click = ->
    $(button_add).off().click ->
      clear_value_from_service_total_when_creating_first_composition()
      disable_service_value_input()
      show_fields()
      handle_remove_click()

  handle_remove_click = ->
    $(button_remove).off().click ->
      remove_add_composition_fields(this)
      update_service_total_with_deleted_value()
      verify_if_exist_compositions()

  handle_change_select = ->
    $(document).on 'change', select, ->
      verify_selected_value(this)

  clear_value_from_service_total = ->
    $(total_service_input).val(StantFormat.converteFloatMoeda(0))
  
  clear_value_from_service_total_when_creating_first_composition = ->
    unless have_some_composition()
      $(total_service_input).val(StantFormat.converteFloatMoeda(0))

  remove_add_composition_fields = (remove_button) ->
    father_element = $(remove_button).parents('.added_compositions_fields') 
    if $(father_element).children('.id_composition').val() == ''
      $(father_element).remove()
      verify_if_exist_compositions()
    else
      $(father_element).hide()
      $(father_element).children('.destroy_composition').val(1)
      verify_if_exist_compositions()

  verify_if_exist_compositions = ->
    unless have_some_composition()
      enable_service_value_input()
      clear_value_from_service_total()
      #restore_service_value()

  restore_service_value = ->
    value = $(total_service_input).data('value')
    $(total_service_input).val(StantFormat.converteFloatMoeda(value))

  have_some_composition = ->
    $('.added_compositions_fields:visible').length > 0

  update_service_total_with_deleted_value = () ->
    total = 0
    $(total_composition).each ->
      total += $(@).maskMoney('unmasked')[0]
    
    total = StantConfigureDecimal.configure_decimal_places(Math.round((total + Number.EPSILON) * 100) / 100)
    $(total_service_input).val(total)
    $(total_service_input).focus()
    $(total_service_input).blur()

  verify_selected_value = (element_select) ->
    if selected_value_is_empty(element_select)
      fill_inputs(element_select, 0, 0, 0)
    else
      get_composition(element_select)

  show_fields = ->
    view_id = get_current_time()
    fields = $(button_add).data('fields').replace(/view_id/g, view_id)
    view_id_input = "<input type='hidden' name='servico_servico[service_compositions_attributes][][view_id]' value='#{view_id}'>"
    $(container_composition).append(fields)
    $('.added_compositions_fields').last().append(view_id_input)
    CalculateValueFromServiceWithComposition.handler_events()

  get_current_time = ->
    date = new Date
    date.getTime()

  get_composition = (elementSelect) ->
    $.ajax {
      type: 'POST',
      dataType: 'json',
      url: "/compositions/_search/#{get_selected_id(elementSelect)}",
      beforeSend: ->
        config_loader('show', elementSelect)
      success: (data) ->
        fill_inputs(elementSelect, data.value, parseFloat(data.quantity), data.unidade_medida_id)
      complete: ->
        config_loader('hide', elementSelect)
    }

  config_loader = (action, elementSelect) ->
    elementId = elementSelect.id.split('_')[2]
    if action == 'show' 
      new AjaxLoader().show("loader_spinner_#{elementId}")
      $("div.quantity_#{elementId}").addClass('is-disabled')
      $("div.value_#{elementId}").addClass('is-disabled')
      $("div.composition_id_#{elementId}").addClass('is-disabled')
    else
      new AjaxLoader().hide("loader_spinner_#{elementId}")
      $("div.quantity_#{elementId}").removeClass('is-disabled')
      $("div.value_#{elementId}").removeClass('is-disabled')
      $("div.composition_id_#{elementId}").removeClass('is-disabled')

  fill_inputs = (elementSelect, value, quantity, unidade_medida_id) ->
    elementId = elementSelect.id.split('_')[2]
    $("#measurement_unity_#{elementId}").val(unidade_medida_id)
    configure_quantity_input("#quantity_#{elementId}", quantity)
    configure_value_input("#value_#{elementId}", value)
    configure_total_input("#total_#{elementId}", value, quantity)

  configure_quantity_input = (quantity_input, quantity) ->
    quantity = StantConfigureDecimal.configure_decimal_places(parseFloat(quantity), 3)
    $(quantity_input).val(quantity)
    $(quantity_input).focus()
    $(quantity_input).blur()

  configure_value_input = (value_input, value) ->
    value = StantConfigureDecimal.configure_decimal_places(parseFloat(value))
    $(value_input).val(value)
    $(value_input).focus()
    $(value_input).blur()

  configure_total_input = (total_input, value, quantity) ->
    total_value = parseFloat((value * quantity).toFixed(2))
    current_line_total = $(total_input).maskMoney('unmasked')[0]
    update_total_from_line($(total_input), total_value)
    update_service_total(current_line_total, total_value)

  update_total_from_line = (total_input, total_value) ->
    total_value = StantConfigureDecimal.configure_decimal_places(total_value)
    total_input.val(total_value)
    new StantMasks().set_money_mask(total_input)
    total_input.focus()
    total_input.blur()

  update_service_total = (current_line_total, total) ->
    current_service_total = subtract_from_service_total(current_line_total)
    total = StantConfigureDecimal.configure_decimal_places(total + current_service_total)
    $(total_service_input).val(total)
    $(total_service_input).focus()
    $(total_service_input).blur()

  subtract_from_service_total = (current_line_total) ->
    value = $(total_service_input).maskMoney('unmasked')[0] - current_line_total
    parseFloat(value.toFixed(2))

  get_selected_id = (elementSelect) ->
    $(elementSelect).val()

  selected_value_is_empty = (element_select) ->
    get_selected_id(element_select) == ''

  disable_service_value_input = ->
    $('.servico-valor').attr('readonly',true);

  enable_service_value_input = ->
    $('.servico-valor').attr('readonly',false);
