import AjaxLoader from '../../utils/ajax_loader.coffee'
import Error from '../../utils/error.coffee'
import * as I18n from '../../services/i18n'
import translations from '../../translations'
import {
  set_url_for_upload_images_options,
  show_static_modal_and_toggle_button_visibility,
  process_upload_queue,
  handle_done_modal_button_click
} from './helpers'

export default class OccurrenceCreate
  constructor: ->
    @occurrence_form = $('#js_occurrence_form')
    @form_fields = $('
      #js_occurrence_name,
      #js_occurrence_description,
      #js_occurrence_severity_level,
      #js_occurrence_occurrence_at,
      #js_occurrence_severity_resposibles,
      #js_occurrence_type_id,
      #js_place_id,
      #js_occurrence_action_name,
      #js_occurrence_action_attitude,
      #js_action_severity_level,
      #js_occurrence_action_responsibles,
      #js_occurrence_action_deadline
    ')
    @save_button = $('.js-save-occurrence-button')

  handler_events: ->
    handle_ajax_form_submit.call(@)
    handle_informative_check_change.call(@)

  handle_ajax_form_submit = ->
    self = @
    @save_button.on 'click', (e) ->
      form = self.occurrence_form.get(0)
      e.preventDefault()

      is_edition = self.occurrence_form.hasClass('edit')
      form_data = new FormData(form)

      $.ajax {
        method: form.method,
        url: form.action,
        data: form_data,
        dataType: 'json',
        processData: false,
        contentType: false,
        success: (response) ->
          handle_occurrence_create_success response, is_edition
        beforeSend: (xhr) ->
          before_send_data_submit(xhr, self)
        error: (e) ->
          if Object.hasOwn(e, 'responseJSON') && e.responseJSON.check_construction_site
            fail_msg = I18n.t(translations(), 'flash.construction_site_changed')
            Error.show_alert_flash_msg_fixed(fail_msg)
          else
            Error.show_alert_flash_msg(e.responseText)
        complete: (xhr) ->
          complete_data_submit(xhr.responseText, self)
      }

      checked_informative = $('#js-occurrence-is-informative').is(":checked")

      if !checked_informative
        errors_step_1 = $('.step-01').find('.error-input').length
        if errors_step_1 > 0
          $('.nav-step-01').click()


  handle_occurrence_create_success = (data, edit) ->
    occurrence_form = $(".js-occurrence-photos-dropzone")
    occurrence_action_form = $(".js-occurrence-action-photos-dropzone")

    occurrence_id = data.occurrence_id
    occurrence_action_id = data.occurrence_action_id

    occurrence_files_lenght = 0
    occurrence_files_lenght = occurrence_form.prop("dropzone").getQueuedFiles().length unless edit

    occurrence_action_files_lenght = 0
    occurrence_action_files_lenght = occurrence_action_form.prop("dropzone").
    getQueuedFiles().length unless edit

    message_type = 'create_success'
    message_type = 'update_success' if edit


    return Turbolinks.visit("/occurrences/#{occurrence_id}/details?message_type=#{message_type}") \
      if !occurrence_files_lenght && !occurrence_action_files_lenght

    set_url_for_upload_images_options(occurrence_form, data.occurrence_id)

    if data.occurrence_action_id && occurrence_action_files_lenght
      set_url_for_upload_images_options(occurrence_action_form, data.occurrence_action_id)
      process_upload_queue(occurrence_action_form)

    show_static_modal_and_toggle_button_visibility('#js-occurrence-dropzone-lightbox')
    process_upload_queue(occurrence_form)
    redirect_path = "/occurrences/#{occurrence_id}/details?message_type=#{message_type}"
    handle_done_modal_button_click(redirect_path)

  before_send_data_submit = (xhr, context) ->
    self = context
    self.save_button.attr('disabled', 'disabled')
    new AjaxLoader().show()
    Error.clear_global_errors()
    send_form = true

    self.form_fields.each ->
      element = $(@)
      unless element.val() && element.val().length
        add_error_to_input(element)
        send_form = false
        new AjaxLoader().hide()
        self.save_button.attr('disabled', false)

    return xhr.abort() unless send_form

  add_error_to_input = (element) ->
    id_input = element.attr('id')
    message = I18n.t(translations(), 'errors.messages.blank')
    new Error(id_input, message).show()

  complete_data_submit = (response_text, self) ->
    unless response_text.substring(0, 10) == 'Turbolinks'
      self.save_button.attr('disabled', false)
      new AjaxLoader().hide()

  handle_informative_check_change = ->
    self = @
    $('.js-occurrence-is-informative-container').on 'change' ,'#js-occurrence-is-informative', ->
      $('#js-occurrence-action-tab').toggleClass('hide-important')
      alternate_between_next_and_save_button_on_steps.call(@)
      set_correct_fields_for_validation.call(@, self)

  alternate_between_next_and_save_button_on_steps = ->
    if @checked
      $('.js-dropzone-preview-container-action').find('[data-js-dz-remove]').click()
      $('.js-occurrence-next-footer').addClass('hide')
      $('.js-occurrence-save-footer').removeClass('hide')
      $('.occurrence-action-upload-area').addClass('hide')
    else
      $('.js-occurrence-next-footer').removeClass('hide')
      $('.js-occurrence-save-footer').addClass('hide')
      $('.occurrence-action-upload-area').removeClass('hide')


  set_correct_fields_for_validation = (self) ->
    form_fields_only_occurrence = $('
      #js_occurrence_name,
      #js_occurrence_description,
      #js_occurrence_severity_level,
      #js_occurrence_occurrence_at,
      #js_occurrence_severity_resposibles,
      #js_occurrence_type_id,
      #js_place_id
    ')

    if @checked
      return self.form_fields = form_fields_only_occurrence

    form_fields_only_action = $('
      #js_occurrence_action_name,
      #js_occurrence_action_attitude,
      #js_action_severity_level,
      #js_occurrence_action_deadline,
      #js_occurrence_action_responsibles
    ')

    self.form_fields = form_fields_only_occurrence
      .add(form_fields_only_action)
