import "pickadate/lib/picker.date.js"
import translations from "../translations"
import * as I18n from "../services/i18n"

export default class StantDatepicker
  constructor: (@input) ->

  options = ->
    input = @input

    monthsFull: $('#translations').data("content").date.month_names.slice(1),
    monthsShort: $('#translations').data("content").date.abbr_month_names.slice(1),
    weekdaysFull: $('#translations').data("content").date.day_names,
    weekdaysShort: $('#translations').data("content").date.abbr_day_names,
    today: I18n.t(translations(), 'datepicker.hoje'),
    clear: I18n.t(translations(), 'datepicker.limpar'),
    close: I18n.t(translations(), 'datepicker.fechar'),
    format: 'dd/mm/yyyy',
    formatSubmit: 'yyyy/mm/dd',
    editable: false,
    container: 'body'
    onOpen: ->
      div_wrapper = input.parent().find('div.special-field')
      div_wrapper.addClass 'filled'
    onClose: ->
      $(document.activeElement).blur()
      date_input = this.$node.siblings().find('.mask-date-field').attr('id')

      if date_input == undefined
        date_input = this.$node.siblings('.mask-date-field').attr('id')
      $('#' + date_input).val(this.$node.val()) unless this.$node.val() == ''

  pick: ->
    input = @input.pickadate(options.call(@))

  @init: ->
    new StantDatepicker($('.pickadate')).pick()