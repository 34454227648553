import StantMenuTooltip from "./utils/stant_menu_tooltip.coffee"

$(document).on 'turbolinks:load', ->
  if typeof localStorage == 'undefined'
    document.getElementById('result').innerHTML = 'Your browser does not support HTML5 localStorage. Try upgrading.'
  else
    has_collapse_menu = false
    $('body').each (i, el) ->
      if localStorage['cllps' + i] == 'collapse-menu'
        has_collapse_menu = true
        $(this).addClass 'collapse-menu'
      return

    new StantMenuTooltip().enable() if has_collapse_menu

  $('.collapse-link').on 'click', ->
    $item = $(this).closest('body')
    index = $('body').index($item)

    $item.toggleClass 'collapse-menu'
    if $item.hasClass('collapse-menu')
      new StantMenuTooltip().enable()
      localStorage.setItem 'cllps' + index, 'collapse-menu'
    else
      new StantMenuTooltip().disable()
      localStorage.removeItem 'cllps' + index
    return
  return
