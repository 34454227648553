import translations from "../../../../translations"
import * as I18n from "../../../../services/i18n"

export default class PlanningSiffs
  constructor: ->
    @layoutModal = $('#layout_modal_')

  handler_events: ->
    selfClass = @
    handle_on_click_siffs_button(selfClass)
    @layoutModal.on 'ajax:complete', '.form-siffs', ->
      $(@).find('.loader-spinner').hide()

  handle_on_click_siffs_button = (selfClass) ->
    layoutModal = selfClass.layoutModal
    $('.planning-siffs-button').on 'click', ->
      planningId = $(@).parents('.place-row').find('.planning-id').val()

      #prevent call again if is already loaded
      if(planningId != layoutModal.data('planning-id'))
        layoutModal.data('planning-id', planningId)
        mountModal(layoutModal, planningId)
        addFinalRangeCalc(layoutModal)
        showLoaderOnSubmit(layoutModal)

      layoutModal.modal('show')

  showLoaderOnSubmit = (layoutModal) ->
    layoutModal.find('form').on 'submit', ->
      layoutModal.find('.loader-div').show()

  removeLoaderOnComplete = ->


  addFinalRangeCalc = (layoutModal) ->
    layoutModal.find('#siffs-quantity, #siffs-range-start').on 'keyup', ->
      quantity = parseInt(layoutModal.find('#siffs-quantity').val())
      rangeStart = parseInt(layoutModal.find('#siffs-range-start').val())
      siffsRangeFinalValue = quantity + rangeStart - 1
      layoutModal.find('#siffs-range-final').val(siffsRangeFinalValue)

  mountModal = (layoutModal, planningId) ->
    layoutModal
      .find('.layout-modal-content')
        .removeClass('container')
        .end()
      .find('.modal-body')
        .removeClass('modal-scroll')
        .end()
      .find('.modal-title')
        .text(I18n.t(translations(), 'application.layout_modal.title_add_siffs'))
        .end()
      .find('.layout-modal-content')
        .html(build_content_html(planningId))

  build_content_html = (planningId) ->
    siffsPreCreatePath = $("#siffs-pre-create-path").val()
    """
      <form class="form-siffs" action="#{siffsPreCreatePath}" method="post" data-remote="true">

        <input class="planning_id" type="hidden" name="siffs[planning_id]" value="#{planningId}">
        <div class="col-md-12">
          <label class="label">#{I18n.t(translations(), 'application.layout_modal.add_siffs.name')}<abbr title="required">*</abbr></label>
          <input type="text" tabindex="1" class="form-control" name="siffs[prefix]" required>
        </div>
        <div class="col-md-12">
          <label class="label">#{I18n.t(translations(), 'application.layout_modal.add_siffs.quantity')}<abbr title="required">*</abbr></label>
          <input id="siffs-quantity" name="siffs[quantity]"  type="number" min="1"  tabindex="1" value="1" min="0" class="form-control" required>
        </div>
        <div class="col-md-6">
          <label class="label p-b-0">#{I18n.t(translations(), 'application.layout_modal.add_siffs.units_checked.title')}:</label>
          <label class="label p-t-0">#{I18n.t(translations(), 'application.layout_modal.add_siffs.units_checked.start')}<abbr title="required">*</abbr></label>
          <input id="siffs-range-start" name="siffs[range_start]" type="number" min="1" tabindex="1" value="1" class="form-control" required>
        </div>
        <div class="col-md-6">
          <div class="label offset-md-8 p-b-0">&nbsp;</div>
          <label class="label p-t-0">#{I18n.t(translations(), 'application.layout_modal.add_siffs.units_checked.end')}</label>
          <input id="siffs-range-final" disabled type="number" tabindex="1" value="1" class="form-control">
        </div>
        <div class="modal-footer-siffs col-md-12 m-t-15">
          <div class="col-md-6" data-dismiss="modal">
            <a class="btn btn-link btn-danger">#{I18n.t(translations(), 'application.layout_modal.add_siffs.cancel')}</a>
          </div>
          <div class="col-md-5">
            <button id="submit-pre-create-siffs" name="button" type="submit" class="btn btn-success">
              <i class="icon icon-save"></i> #{I18n.t(translations(), 'application.layout_modal.add_siffs.create_siffs')}
            </button>
          </div>
          <div class="col-md-1 loader-div hide m-t-5">
            <span class="loader loader-spinner" style="display: inline-block;"></span>
          </div>
        </div>

      </form>
    """
