import "javascripts/jquery-multiselect/jquery.multiselect"
import translations from "../translations"
import * as I18n from "../services/i18n"

export default class StantMultiselect
  constructor: (@class_name, @showCheckbox=true, @selectManageables=false) ->

  add_listener: ->
    $(".#{@class_name}").multiselect
      placeholder: I18n.t(translations(), 'multi_select.select_one_or_more_options')
      selectAll: false
      search: true
      showCheckbox: @showCheckbox
      searchOptions:
        default: I18n.t(translations(), 'multi_select.search_for')

    add_custom_links_and_listeners(@class_name, @selectManageables)

  add_custom_links_and_listeners = (class_name, selectManageables) ->
    $(".#{class_name}").each (index, selector) ->
      add_link_select_all(selector)
      add_listener_click_select_all(selector)
      add_listener_keyup_search_input(selector)
      add_listener_click_selector_options(selector)
      add_label_search_no_results(selector)

      if selectManageables and get_manageable_ids().length
        add_link_select_manageable(selector)
        add_listener_click_select_manageable(selector)

      add_initializer_listener(selector)
      initialize_labels(selector)

  add_link_select_all = (selector) ->
    optionsList = $(selector).siblings('.ms-options-wrap').find('.ms-options > ul')
    optionsList.before("<a href='#' class='ms-selectall-filtered'>#{get_label_select_all()}</a>")

  add_link_select_manageable = (selector) ->
    optionsList = $(selector).siblings('.ms-options-wrap').find('.ms-options > ul')
    optionsList.before("<a href='#' class='ms-selectmanageable-filtered'>#{get_label_select_manageable()}</a>")

  add_listener_click_select_all = (selector) ->
    $(selector).siblings('.ms-options-wrap').on 'click', '.ms-selectall-filtered', (event) ->
      event.preventDefault()

      optionsList = $(event.target).parents('.ms-options').find('ul > li:visible')

      if optionsList.filter(':not(.selected)').length
        optionsList.filter(':not(.selected)').find('input[type="checkbox"]').trigger('click')
      else
        optionsList.find('input[type="checkbox"]').trigger('click')

  add_listener_click_select_manageable = (selector) ->
    $(selector).siblings('.ms-options-wrap').on 'click', '.ms-selectmanageable-filtered', (event) ->
      event.preventDefault()

      optionsList = $(event.target).parents('.ms-options').find('ul > li:visible')
      manageableOptions = filter_manageable_options(optionsList)

      if manageableOptions.filter(':not(.selected)').length
        manageableOptions.filter(':not(.selected)').find('input[type="checkbox"]').trigger('click')
      else
        manageableOptions.find('input[type="checkbox"]').trigger('click')

  add_listener_keyup_search_input = (selector) ->
    optionsWrap = $(selector).siblings('.ms-options-wrap')
    optionsWrap.find('.ms-options > .ms-search input').on 'keyup', () ->
      update_label_select_all(optionsWrap)
      update_visibility_select_all_and_search_no_results(optionsWrap)
      update_label_select_manageable(optionsWrap)
      update_visibility_select_manageable(optionsWrap)

  add_listener_click_selector_options = (selector) ->
    optionsWrap = $(selector).siblings('.ms-options-wrap')
    optionsWrap.on 'click', 'input[type="checkbox"]', () ->
      selectedOption = $(this).parent().parent()
      update_visibility_selected_option(optionsWrap, selectedOption)
      update_label_select_all(optionsWrap)
      update_visibility_select_all_and_search_no_results(optionsWrap)
      update_label_select_manageable(optionsWrap)
      update_visibility_select_manageable(optionsWrap)

  add_label_search_no_results = (selector) ->
    options = $(selector).siblings('.ms-options-wrap').find('.ms-options')
    options.append("<div class='ms-search-no-results' style='display: none;'>#{get_label_search_no_results()}</div>")

  update_label_select_all = (optionsWrap) ->
    selectAll = optionsWrap.find('.ms-selectall-filtered')
    return unless selectAll.length

    optionsList = optionsWrap.find('.ms-options > ul > li')
    optionsVisible = optionsList.filter((index,element) ->
      $(element).css('display') != 'none'
    )

    if optionsVisible.filter(':not(.selected)').length
      selectAll.text(get_label_select_all())
    else
      selectAll.text(get_label_deselect_all())

  update_label_select_manageable = (optionsWrap) ->
    selectManageable = optionsWrap.find('.ms-selectmanageable-filtered')
    return unless selectManageable.length

    optionsList = optionsWrap.find('.ms-options > ul > li')
    optionsVisible = optionsList.filter((index,element) ->
      $(element).css('display') != 'none'
    )
    manageableOptions = filter_manageable_options(optionsVisible)

    if manageableOptions.filter(':not(.selected)').length
      selectManageable.text(get_label_select_manageable())
    else
      selectManageable.text(get_label_deselect_manageable())

  update_visibility_select_manageable = (optionsWrap) ->
    selectManageable = optionsWrap.find('.ms-selectmanageable-filtered')
    return unless selectManageable.length

    optionsList = optionsWrap.find('.ms-options > ul > li')
    optionsVisible = optionsList.filter((index,element) ->
      $(element).css('display') != 'none'
    )
    manageableOptions = filter_manageable_options(optionsVisible)

    if manageableOptions.length
      selectManageable.show()
    else
      selectManageable.hide()

  update_visibility_select_all_and_search_no_results = (optionsWrap) ->
    selectAll = optionsWrap.find('.ms-selectall-filtered')
    return unless selectAll.length
    searchNoResults = optionsWrap.find('.ms-search-no-results')

    optionsList = optionsWrap.find('.ms-options > ul > li')
    optionsVisible = optionsList.filter((index,element) ->
      $(element).css('display') != 'none'
    )

    if optionsVisible.length
      selectAll.show()
      searchNoResults.hide()
    else
      selectAll.hide()
      searchNoResults.show()

  update_visibility_selected_option = (optionsWrap, selectedOption) ->
    searchInputText = optionsWrap.find('.ms-search input').val().toLowerCase()
    selectedOptionText = selectedOption.find('label').text().toLowerCase()
    unless selectedOptionText.lastIndexOf( searchInputText ) > -1
      selectedOption.hide()

  get_label_select_all = () ->
    return I18n.t(translations(), 'multi_select.select_all')

  get_label_deselect_all = () ->
    return I18n.t(translations(), 'multi_select.deselect_all')

  get_label_select_manageable = () ->
    return I18n.t(translations(), 'multi_select.select_manageable')

  get_label_deselect_manageable = () ->
    return I18n.t(translations(), 'multi_select.deselect_manageable')

  get_label_search_no_results = () ->
    return I18n.t(translations(), 'multi_select.search_no_results')

  get_manageable_ids = () ->
    return JSON.parse($('#js-manageable-services-ids').val())
  filter_manageable_options = (optionsList) ->
    manageableIds = get_manageable_ids()
    optionsList.filter( (index, option) ->
      optionId = parseInt($(option).find('input[type="checkbox"]').val())
      manageableIds.lastIndexOf(optionId) != -1
    )

  add_initializer_listener = (selector) ->
    $(selector).siblings('.ms-options-wrap').on 'initialize', () ->
      initialize_labels(selector)

  initialize_labels = (selector) ->
    optionsWrap = $(selector).siblings('.ms-options-wrap')
    searchNoResults = optionsWrap.find('.ms-search-no-results')
    selectAll = optionsWrap.find('.ms-selectall-filtered')
    selectManageable = optionsWrap.find('.ms-selectmanageable-filtered')
    optionsList = optionsWrap.find('.ms-options > ul > li')
    optionsRemoved = $(selector).find('option[data-removed="true"]')
    manageableOptions = filter_manageable_options(optionsList) if selectManageable.length

    if optionsList.filter(':not(.selected)').length
      selectAll.text(get_label_select_all())
    else
      selectAll.text(get_label_deselect_all())

    if selectManageable.length
      if manageableOptions.filter(':not(.selected)').length
        selectManageable.text(get_label_select_manageable())
      else
        selectManageable.text(get_label_deselect_manageable())

    if optionsList.length == 0 or optionsRemoved.length == optionsList.length
      selectAll.hide()
      selectManageable.hide() if selectManageable.length
      searchNoResults.show()
    else
      selectAll.show()
      selectManageable.show() if selectManageable.length
      searchNoResults.hide()
