export default class CountFieldCharacters {
  handler_events () {
    this.handle_on_key_press_on_input_with_limit_lenght()
  }

  handle_on_key_press_on_input_with_limit_lenght () {
    const characters_count_containers_class = '.js-occurrence-character-count-container'
    
    $(document).on('keyup', characters_count_containers_class, (e) => {
      let characters_count_container = $(e.currentTarget)
      let input = characters_count_container.find('.js-occurrence-count-input')
      const count_element = characters_count_container.find('.js-occurrence-count-indicator')
      const length_to_display = input.val().length

      count_element.html(length_to_display)
    })
  }

  static reset_characters_count () {
    $('.js-occurrence-count-indicator').html('0')
  }
}