import AjaxLoader from '../../utils/ajax_loader.coffee'
import Error from '../../utils/error.coffee'
import * as I18n from '../../services/i18n'
import translations from '../../translations'

import {
  set_url_for_upload_images_options,
  show_static_modal_and_toggle_button_visibility,
  process_upload_queue,
  handle_done_modal_button_click
} from '../occurrences/helpers'

export default class OccurrenceActionFinish
  constructor: ->
    @save_button = $('.js_finish_occurrence_action_button')

  handler_events: ->
    handle_ajax_form_submit.call(@)

  handle_ajax_form_submit = ->
    self = @
    @save_button.on 'click', (e) ->
      action_form = $('.js_finish_occurrence_action_form:visible')
      
      form = action_form.get(0)
      e.preventDefault()

      form_data = new FormData(form)

      $.ajax {
        method: 'PUT',
        url: form.action,
        dataType: 'json',
        data: form_data,
        processData: false,
        contentType: false,
        success: (response) ->
          handle_success_finish(response)
        beforeSend: (xhr) ->
          before_send_data_submit(xhr, self)
        complete: (xhr) ->
          complete_data_submit(xhr.responseText, self)
      }

  handle_success_finish = (data) ->
    occurrence_action_id = data.id
    occurrence_action_form = $('.js-occurrence-action-photos-finish-dropzone')
    occurrence_action_files_lenght = occurrence_action_form.prop("dropzone").getQueuedFiles().length
    redirect_path = "/occurrence_actions/#{occurrence_action_id}/details?message_type=finish_success"
    
    return Turbolinks.visit(redirect_path) if !occurrence_action_files_lenght
    
    set_url_for_upload_images_options(occurrence_action_form, occurrence_action_id)

    show_static_modal_and_toggle_button_visibility('#js-dropzone-finish-occurrence-action-lightbox')
    process_upload_queue(occurrence_action_form)
    handle_done_modal_button_click(redirect_path)
  
  before_send_data_submit = (xhr, context) ->
    self = context
    self.save_button.attr('disabled', 'disabled')
    new AjaxLoader().show()
    Error.clear_global_errors()
    send_form = true
    form_fields = $('.js_finish_occurrence_action_description:visible')

    form_fields.each ->
      element = $(@)
      unless element.val() && element.val().length
        add_error_to_input(element)
        send_form = false
        new AjaxLoader().hide()
        self.save_button.attr('disabled', false)

    return xhr.abort() unless send_form

  add_error_to_input = (element) ->
    id_input = "#{element.attr('id')}:visible"
    message = I18n.t(translations(), 'errors.messages.blank')
    new Error(id_input, message).show()
    $(".#{id_input}").find('textarea').addClass('error-input')
  
  complete_data_submit = (response_text, self) ->
    unless response_text.substring(0, 10) == 'Turbolinks'
      self.save_button.attr('disabled', false)
      new AjaxLoader().hide()
