import moment from "moment"
import translations from "translations"
import * as I18n from "services/i18n"

export function handleFormValidations() {
  $(document).on('change', '#checkbox-all-period-excel-analytics', function() {
    if($(this).is(':checked')) {
      $('.date input').val('')
      $('.p-error').hide()
    }
  })

  $(document).on('click', '#excel-analytics-form .btn-search', function(event) {
    const allPeriodUnchecked = !$('#checkbox-all-period-excel-analytics').is(':checked')
    const reportType = $('#js-report-type-select');
    const beginAt = moment($('input[name="request[begin_at]"]').val(), "DD/MM/YYYY")
    const endAt = moment($('input[name="request[end_at]"]').val(), "DD/MM/YYYY")
    const invalidDates = beginAt > endAt

    $('.p-error').text('')

    if (reportType.val() == '') {
      preventDefaultAndScrollTop(event)

      $('.report-type-error').text(I18n.t(translations(), 'reports.excel_analytics.form.errors.invalid_empty_field')).show()
    } else {
      $('.report-type-error').hide();
    }

    if(allPeriodUnchecked && (!beginAt.isValid() || !endAt.isValid())) {
      preventDefaultAndScrollTop(event)

      $('.begin-at-error, .end-at-error').text(I18n.t(translations(), 'errors.messages.blank')).show()
    } else if(invalidDates){
      preventDefaultAndScrollTop(event)

      $('.begin-at-error').text(I18n.t(translations(), 'reports.excel_analytics.form.errors.invalid_dates')).show()
    } else {
      $('.begin-at-error, .end-at-error').hide()
    }
  })
}

function preventDefaultAndScrollTop(event) {
  $("html, body").animate({ scrollTop: 0 });
  event.preventDefault()
}
