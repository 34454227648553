export function set_tooltip_event(tooltip, tooltip_class) {
  let tooltip_el = $(tooltip_class)
  tooltip_el.show();

  if (!tooltip.body) {
    tooltip_el.hide()
    return;
  }

  tooltip_el.removeClass('above below');

  let tooltipText = tooltip.body[0].lines[0].split(':')
  tooltip_el.find(".label").html(`${tooltipText[0]}: ${tooltipText[1]}`)
  tooltip_el.css({
    opacity: 1,
    left: tooltip.x + 'px',
    top: tooltip.y + 'px',
    zIndex: 3
  });
}
