import translations from "translations"
import * as I18n from "services/i18n"

$(document).on('change', '#request_siff_list_filters_macro_ids', function(e) {
	const selected_options = $('#request_siff_list_filters_macro_ids > option:selected')
	const not_selected_options = $('#request_siff_list_filters_macro_ids > option:not(:selected)')
	changeGroupOptionsStatus(selected_options);
	changeGroupOptionsStatus(not_selected_options, false);
})

$(document).on('change', '#request_siff_list_filters_groupings_ids', function(e) {
	const selected_options = $('#request_siff_list_filters_groupings_ids > option:selected')
	const not_selected_options = $('#request_siff_list_filters_groupings_ids > option:not(:selected)')
	changeStepOptionsStatus(selected_options);
	changeStepOptionsStatus(not_selected_options, false);
})

$(document).on('click', '.ms-options-wrap :input[value="without_group"]', function() {
	$(`#request_siff_list_filters_steps_ids :not([data-step_group_id])`).each(function() {
		let option = $(`.ms-options-wrap :input[value=${$(this).val()}]`)
		let is_checked = option.prop('checked')
		let status = !is_checked ? option.trigger('click').attr('disabled', true) : option.attr('disabled', false).trigger('click')
	})
})


function changeGroupOptionsStatus(listOfOptions, is_enabled = true) {
	listOfOptions.each(function() {
		const optionsToChange = $(`#request_siff_list_filters_groupings_ids [data-group_parent_id=${$(this).val()}]`)
		optionsToChange.each(function() {
			let option = $(`.ms-options-wrap :input[value=${$(this).val()}]`)
			setTypeOfStatusAndChange(option, is_enabled)
		});
	});
}

function changeStepOptionsStatus(listOfOptions, is_enabled = true) {
	listOfOptions.each(function() {
		$(`#request_siff_list_filters_steps_ids [data-step_group_id=${$(this).val()}]`).each(function() {
			let option = $(`.ms-options-wrap :input[value=${$(this).val()}]`)
			setTypeOfStatusAndChange(option, is_enabled)
		});
	});
}


function setTypeOfStatusAndChange(option, is_enabled) {
	let is_checked = option.prop('checked')
	let input_disabled = option.attr('disabled') == 'disabled'

	if(is_checked && !input_disabled && !is_enabled){
		return false
	} 
	else if(is_checked && !input_disabled && is_enabled){
		option.attr('disabled', is_enabled)
	}
	else if (is_enabled && !is_checked) {
		option.trigger('click').attr('disabled', is_enabled)
	}
	else if (!is_enabled && is_checked) {
		option.attr('disabled', is_enabled).trigger('click')
	}
	else if (is_checked && !input_disabled){
		option.trigger('click')
		reselectStepOption(option) 
	}
}

function reselectStepOption(option){
	$(`#request_siff_list_filters_steps_ids [data-step_group_id=${option.val()}]`).each(function(i,el) {
		let input = $(`.ms-options-wrap :input[value=${$(el).val()}]`)
		input.trigger('click')
	});
}

function createMacroWithoutGroup() {
	const stepsWithoutGroups = $(`#request_siff_list_filters_steps_ids :not([data-step_group_id])`)
	if (stepsWithoutGroups.length) {
		var options = [{
			name: `${I18n.t(translations(), 'atividades.index.no_group')}`,
			value: 'without_group',
			checked: false,
		}];
		$('#request_siff_list_filters_macro_ids').multiselect('loadOptions', options, false)
		$('#request_siff_list_filters_macro_ids').append(`<option value="without_group">${I18n.t(translations(), 'atividades.index.no_group')}</option>`)
	}
}
jQuery(function() {
	createMacroWithoutGroup();
})
