import Quill from "quill"

export default class TextEditor {
  constructor(containerElement, options) {
    this.containerElement = document.querySelector(containerElement)
    this.options = Object.assign(defaultOptions, options)
    this.maxLength = this.options.charactersCounter.maxLength
  }

  render() {
    if (this.containerElement == null) return

    this.editor = new Quill(this.containerElement, this.options)
    this.configureEditor()

    this.editor.on('text-change', (delta, oldDelta, source) => {
      let editorTextLength = this.editor.getText().replace(/\n/g, '').length
      this.containerElement
        .querySelector(".characterscounter-current")
        .innerHTML = editorTextLength

      if (editorTextLength > this.maxLength) {
        this.editor.setContents(oldDelta)
        this.editor.focus()
      } else {
        this.containerElement
          .querySelector(".editor-input")
          .value = this.getInputHtml()
      }
    })

    if(this.options.disabled) this.disable()
  }

  configureEditor() {
    if (this.editor == undefined) return

    const charactersCounterHtml = buildCharactersCounterHtml(this.maxLength)
    this.containerElement.insertAdjacentHTML('beforeend', charactersCounterHtml)

    const inputHtml = buildInputHtml(this.containerElement.dataset.inputName)
    this.containerElement.insertAdjacentHTML('beforeend', inputHtml)

    this.containerElement.style = "height: 80px; max-height: 80px"
  }

  getInputHtml() {
    let editorData = this.containerElement
      .querySelector('.ql-editor').cloneNode(true)
    
    editorData.querySelectorAll('ol').forEach((listElement) => {
      listElement.style = "list-style-type: decimal !important; padding-left: 1em !important; margin-left: 1em;"

      formatHtmlList(listElement)
    })

    editorData.querySelectorAll('ul').forEach((listElement) => {
      listElement.style = "list-style-type: disc !important; padding-left: 1em !important; margin-left: 1em;"

      formatHtmlList(listElement)
    })

    return editorData.innerHTML
  }

  disable() {
    this.editor.disable()
    this.editor.blur()
    this.containerElement.classList.add("disabled-field")
    this.editor.deleteText(0, this.editor.getLength());
  }

  enable() {
    this.editor.enable()
    this.editor.focus()
    this.containerElement.classList.remove("disabled-field")
  }
}

const styleTypes = {
  "UL": ["disc"],
  "OL": ["lower-alpha", "lower-roman", "decimal"]
}

const findParentOf = (element, indentLevel, parentTag) => {
  let parent = element.parentNode

  while(parent.tagName === parentTag || parent.tagName === "LI") {
    let parentIndent = parseInt(parent.classList.value.match(/\d+/g)) || 0

    if(parent.tagName === parentTag && parentIndent === indentLevel) break

    parent = parent.parentNode
  }

  return parent
}

const formatHtmlList = (listElement) => {
  const list = Array.from(listElement.querySelectorAll("li"))
  const listTag = listElement.tagName
  let currentStyleType = 0

  for(const [index, elm] of list.entries()) {
    const nextElement = list[index+1]
    const nextElementClass = nextElement ? nextElement.classList.value : ""
    const nextElementIndent = parseInt(nextElementClass.match(/\d+/g)) || 0
    const currentElement = list[index]
    const currentElementClass = elm.classList.value
    const currentElementIndent = parseInt(currentElementClass.match(/\d+/g)) || 0
    
    if(index == 0 && currentElementIndent > 0) {
      let newList = null
      let deepestElement = null
      for(let i = 0; i < currentElementIndent; i++) {
        let innerList = document.createElement(listTag)
        innerList.style = `list-style-type: ${styleTypes[listTag][currentStyleType]} !important; padding-left: 1em !important; margin-left: 1em;`
        innerList.classList = i < 1 ? '' : `ql-indent-${i}`
        
        if(newList == null) {
          newList = innerList
        } else {
          deepestElement.appendChild(innerList)
        }

        deepestElement = innerList
        currentStyleType = styleTypes[listTag][currentStyleType + 1] ? currentStyleType + 1 : 0
      }

      deepestElement.appendChild(currentElement)
      listElement.prepend(newList)
    }

    if(!(/ql-indent-.*/.test(nextElementClass) && nextElement)) continue
    
    if(currentElementIndent <= nextElementIndent-1) {
      let listElement = null
      let deepestElement = null
      for(let i = currentElementIndent; i < nextElementIndent; i++) {
        let innerList = document.createElement(listTag)
        innerList.style = `list-style-type: ${styleTypes[listTag][currentStyleType]} !important; padding-left: 1em !important; margin-left: 1em;`
        innerList.classList = i < 1 ? currentElementClass : `ql-indent-${i}`
        
        if(listElement == null) {
          listElement = innerList
        } else {
          deepestElement.appendChild(innerList)
        }

        deepestElement = innerList
        currentStyleType = styleTypes[listTag][currentStyleType + 1] ? currentStyleType + 1 : 0
      }
      
      deepestElement.appendChild(nextElement)
      elm.appendChild(listElement)
    } else {
      let parent = findParentOf(elm, nextElementIndent-1, listTag)
      parent.appendChild(nextElement)
    }
  }
}

const defaultOptions = {
  charactersCounter: { maxLength: Number.POSITIVE_INFINITY },
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }]
    ]
  },
  theme: 'snow'
}

const buildCharactersCounterHtml = (maxLength) => {
  let maxLengthSpan = `<span> / ${maxLength}</span>`

  if (maxLength == Number.POSITIVE_INFINITY) maxLengthSpan = ""

  return `
    <div class="pull-left count-info">
      <span class="characterscounter-current">0</span>
      ${maxLengthSpan}
    </div>
  `
}

const buildInputHtml = (inputName) => {
  return `<input type="hidden" class="editor-input" name="${inputName}">`
}
