import HistoryBuilder from "../../utils/history_builder.coffee"
import translations from "../../translations"
import * as I18n from "../../services/i18n"
import moment from 'moment';

export default class ServicosHistory
  handler_events: ->
    handle_on_click_history_button()

  handle_on_click_history_button = ->
    $('.servicos-history-button').on 'click', ->
      histories = $(@).closest('[data-history]').data('history')
      html = new HistoryBuilder(histories, build_history_template).build_history_html()
      $('#layout_modal_ .layout-modal-content').html(html)
      $('#layout_modal_').modal('show')

  build_history_template = (history) ->
    value = history.valor

    """
      <div class="history-item">
        <h4> #{moment(history.data).format('DD/MM/YYYY')} </h4>
        <p> #{I18n.t(translations(), 'servicos.form.modal.titulo')}: #{history.titulo} </p>
        <p> #{I18n.t(translations(), 'servicos.form.modal.unidade_medida')}: #{history.unidade_medida} </p>
        <p> #{I18n.t(translations(), 'servicos.form.modal.tipo_do_servico')}: #{I18n.t(translations(), 'servicos.form.modal.' + history.tipo_servico)} </p>
        <p> #{I18n.t(translations(), 'servicos.form.modal.valor')}: #{StantFormat.converteFloatMoeda(value)} </p>
        <p> #{I18n.t(translations(), 'servicos.form.modal.usuario')}: #{history.usuario} </p>
        <hr>
      </div>
    """