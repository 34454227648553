import StantMasks from "../../utils/stant_masks.coffee"
import StantConfigureDecimal from '../../utils/stant_configure_decimal.coffee'

export default class CalculateValueFromServiceWithComposition
  total_service_input = '.servico-valor'
  total_composition = '.total-composition:visible'

  @handler_events: ->
    handle_value_input_change()
    handle_quantity_input_change()
    new StantMasks().init()

  handle_value_input_change = ->
    $('.value-composition').off().on 'change, keyup', ->
      prepare_values_to_calc(this)
      $(this).focus()

  handle_quantity_input_change = ->
    $('.quantity-composition').off().on 'change, keyup', ->
      prepare_values_to_calc(this)
      $(this).focus()

  get_current_total_input = (input_children) ->
    father = $(input_children).parents('.added_compositions_fields')
    father.children('.composition_total').val()

  prepare_values_to_calc = (input_children) ->
    father = $(input_children).parents('.added_compositions_fields')
    quantity_input = $(father).find('.quantity-composition')
    value_input = $(father).find('.value-composition')
    total_input = $(father).find('.total-composition')
    calc_each_line(quantity_input, value_input, total_input)

  calc_each_line = (quantity_input, value_input, total_input) ->
    current_line_total = $(total_input).maskMoney('unmasked')[0]
    subtracted_total_value = subtract_from_service_total(current_line_total)

    new_line_total = multiply_value_from_line(value_input, quantity_input)
    update_total_from_line(total_input, new_line_total)
    
    update_service_total(sum_compositions_total())

  multiply_value_from_line = (value_input, quantity_input) ->
    value = $(value_input).maskMoney('unmasked')[0]
    quantity = $(quantity_input).maskMoney('unmasked')[0]
    total = (value * quantity).toFixed(2)
    parseFloat(total)

  update_total_from_line = (total_input, total_value) ->
    total_value = StantConfigureDecimal.configure_decimal_places(total_value)
    total_input.val(total_value)
    new StantMasks().set_money_mask(total_input)
    total_input.focus()
    total_input.blur()

  update_service_total = (total) ->
    total = StantConfigureDecimal.configure_decimal_places(total)
    $(total_service_input).val(total)
    $(total_service_input).focus()
    $(total_service_input).blur()

  total_value = () ->
    $(total_service_input).maskMoney('unmasked')[0]

  subtract_from_service_total = (current_line_total) ->
    value = $(total_service_input).maskMoney('unmasked')[0] - current_line_total
    parseFloat(value.toFixed(2))

  sum_value_from_service_total = (value) ->
    $(total_service_input).maskMoney('unmasked')[0] + value

  sum_compositions_total = () ->
    total = 0
    $(total_composition).each ->
      total += $(@).maskMoney('unmasked')[0]
    
    Math.round((total + Number.EPSILON) * 100) / 100