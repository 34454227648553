import translations from "../translations"
import * as I18n from "../services/i18n"

export default class HistoryBuilder
  constructor: (histories, template) -> 
    @histories = histories
    @template = template

  build_history_html: ->
    html = ""
    for history in @histories
      html += @template(history) 
    
    return "<p> #{I18n.t(translations(), 'servicos.form.modal.empty')} </p>" if html == ""

    return html

