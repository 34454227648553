
import Error from "../../utils/error.coffee"
import translations from "../../translations"
import * as I18n from "../../services/i18n"

export default class MethodImageUploadHandler  {
  call() {
    if($('.method-ids').length == 0) return

    const methodIds = JSON.parse($('.method-ids').attr('method-ids'))
    methodIds.forEach((id) => {
      this.initializeDropzone(id)
    })
  }

  initializeDropzone(id) {
    const previewTemplate = $(`#preview-template-${id}`).removeAttr('id').get(0).outerHTML

    let dropzone = $(`#js-method-image-form-${id}`).dropzone({
      createImageThumbnails: true,
      clickable: `#js-method-upload-button-${id}`,
      previewsContainer: `#preview-container-${id}`,
      previewTemplate: previewTemplate,
      acceptedFiles: 'image/jpeg, image/pjpeg, image/png',
      thumbnailWidth: null
    })

    dropzone[0].dropzone.on('addedfile', (file) => {
      $(`#js-manage-method-images-${id}`).last().find('img').attr({width: 120, height: 120});
      $(`#js-manage-method-images-${id}`).find('.empty-message').hide()
      $(`#collapse-image-${id}`).find('.empty-message').hide()

      $(file.previewElement).find('.new-method-image').on('click', function(e){
        $(e.currentTarget).on('confirmed', (e) => {
          dropzone[0].dropzone.removeFile(file)
        })
      })  

      const valid_formats = ["image/jpeg", "image/png", "image/jpg"]
      if (!valid_formats.includes(file.type)) {
        $(file.previewTemplate).hide()
        new Error.show_alert_flash_msg(I18n.t(translations(), 'servicos.show.upload.image_error_message'))
      }

    })

    dropzone[0].dropzone.on('complete', (file) => {
      $(file.previewTemplate).find('.overlay').hide()
    })

    dropzone[0].dropzone.on('success', (file, response) => {
      const divHidden = $(`#${id}-hidden-input`);
      const itemsAttributesIndex = this.getIndexForAttributes(divHidden, 'items_attributes')
      const methodsAttributeIndex = this.getIndexForAttributes(divHidden, 'metodos_attributes')
      file.serverId = response.method_image_id
      
      this.appendImageThumbnail(divHidden, file)

      divHidden.append(`<input type='hidden'
                        id='hidden-${response.method_image_id}'
                        class="js-image-id-${response.method_image_id}"
                        name='servico_servico[fvses_attributes][0][items_attributes][${itemsAttributesIndex}][metodos_attributes][${methodsAttributeIndex}][image_ids][]' 
                        value='${response.method_image_id}'/>`)

    })

    dropzone[0].dropzone.on('removedfile', (file) => {
      if ($(`#js-manage-method-images-${id}`).find('.dz-image-preview').length == 0){
        $(`#js-manage-method-images-${id}`).find('.empty-message').show()
        $(`#collapse-image-${id}`).find('.empty-message').show()
      }
      $(`#${file.serverId}`).remove();
      $(`#hidden-${file.serverId}`).remove();
      $.ajax({
        type: "delete",
        url: `/method_images/${file.serverId}`
      });
    })

  }

  appendImageThumbnail(container, file) {
    const reader = new FileReader()
    reader.onload = function(event) {
      container.append(`<div class="method-image-preview" id="${file.serverId}"><img class="clickable method-image" src='${event.target.result}'></div>`)
    }
    reader.readAsDataURL(file)
  }

  getIndexForAttributes(element, key) {
    var attribute = element.parents('div.dd-content').children().children('div.dd-method-div').children('input').attr('name');
    var item_regex = new RegExp(`\\[${key}]\\[\\d+]`, 'g');
    var index_regex = /\d+/g;

    var result = item_regex.exec(attribute)
    var attribute_index = index_regex.exec(result)
    return attribute_index[0];
  }
}
