import AjaxLoader from '../../../../utils/ajax_loader.coffee'
import Error from '../../../../utils/error.coffee'
import translations from "../../../../translations"
import * as I18n from "../../../../services/i18n"

export default class PlanningValidation
  handler_events: ->
    handle_before_send_form('#validation-approve-form')
    handle_before_send_form('#validation-disaprove-form')

  handle_before_send_form = (form_id) ->
    form = $(form_id)
    form.off().on 'ajax:beforeSend', (e) ->
      [xhr, options] = e.detail
      plannings_checkeds = $('#validation_checked_plannings_form').serialize()
      step_checkeds = $('#validation_checked_step_services_validation_form').serialize()
      options.data += '&' + plannings_checkeds + '&' + step_checkeds
    form.off('ajax_error').on 'ajax:error.ajax_error', (e) ->
      [response, status]  = e.detail
      loader = new AjaxLoader()
      loader.hide_and_enable_button()
      if status = 403
        new Error.show_alert_flash_msg(I18n.t(translations(), 'flash.actions.create.notice'))
