window.StantTableDataMaker = class StantTableDataMaker
  constructor: (@element) ->
    clear_table(@)

  create_empty_table: (message) ->
    @element.html "<span><b>#{message}</b></span>"


  create_single_line_with_id: (data, field_names, line_id) ->
    td = field_names.map( (field_name) -> "<td>#{data[field_name]}</td>" ).join()
    @element.append "<tr id='#{line_id}'>#{td}</tr>"

  clear_table = (self) ->
    self.element.html ""