import {
  show_static_modal_and_toggle_button_visibility,
  process_upload_queue,
  format_redirect_path_and_add_listener_only_on_queuecomplete
} from './helpers'

export default class OccurrencePhotos {
  handler_events () {
    this.handle_click_form_submit()
  }
  
  handle_click_form_submit () {
    $('.js_occurrence_photos_button').on("click", (e) => {
      e.preventDefault()

      const photos_form = $(".js_occurrence_photos_form")
      const id = photos_form.data('id')
      
      process_upload_queue(photos_form)
      show_static_modal_and_toggle_button_visibility('#js-occurrence-dropzone-lightbox')
      
      const success_url = '?message_type=image_uploaded_success'
      const redirect_path = `/occurrences/${id}/details`

      format_redirect_path_and_add_listener_only_on_queuecomplete(
        photos_form,
        redirect_path,
        success_url
      )
    })
  }
}