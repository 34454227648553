import 'datatables/media/js/jquery.dataTables';

$(document).on('turbolinks:load', function() {
  $('.js-construction-sites-people-form').on('submit', function() {
    new AjaxLoader().show();
  });

  $(document).on('click', '#add_construction_site_select_all', function () {
    $(".checkboxConstructionSite").prop('checked', $(this).prop('checked'));
  });

  $(document).on('click', '#remove_construction_site_select_all', function () {
    $(".checkboxConstructionSite").prop('checked', $(this).prop('checked'));
  });

  $('.tab-add-construction-sites').on('click', function() {
    $('input[type=checkbox]').prop('checked', false);
  })

  $('#construction-sites-filter-form .btn-search').on('click', function() {
    $('input[type=checkbox]').prop('checked', false);
  })

  $('.js-clear-construction-sites-filter').on('click', function() {
    $('#construction-sites-filter-form #form-title').val('');
    $("select").select2('val', '');

    $('#construction-sites-filter-form .btn-search').click();
  });

  $('table.constructionsites-datatable').DataTable({
    ordering: false,
    destroy: true,
    paging: false,
    searching: false,
    info: false,
    "language": {
      'sEmptyTable': 'Nenhum registro',
    },
  }); 
});
