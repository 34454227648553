import FilterFinanceAndFisicalExcel from './filter_finance_and_fisical_excel'
import StantMultiselect from "../../../utils/stant_multiselect.coffee"

$(document).on 'turbolinks:load', ->
  new StantMultiselect('construction-multiselect').add_listener()

  $('#s2id_autogen1_search').prop('autocomplete', 'no-password')
  FilterFinanceAndFisicalExcel.handler_events()

  $('#obra_changer_select').on 'change', ->
    id = $(this).val()
    if id
      $.post('/session_obra.json', {id: id}).done (data) ->
        #if data.status == "in_progress" && data.is_multisite
        #  return Turbolinks.visit "/obras/#{id}/details"

        Turbolinks.visit "/dashboard?id=#{id}"

  copy_construction_site_inputs = $('#copy-construction-site-inputs')
      .add('#copy-construction-site-buttons')

  copy_construcion_site_confirm_buttons = $('#copy-construction-site-confirm-buttons')

  $(document).on 'shown.bs.modal', '#new-construction-site-modal', ->
    copy_construction_site_inputs.hide()
    copy_construcion_site_confirm_buttons.show()

  $('#copy_construction_site_yes_button').on 'click', ->
    copy_construction_site_inputs.show()
    copy_construcion_site_confirm_buttons.hide()

  $('#order').on 'change', ->
    $('#btn-filter-search').click()

  $(".search-on-homepage").keyup (e) ->
    if(e.which == 13)
      $('#btn-filter-search').click()
