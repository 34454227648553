import InspectionSaver from './services/inspection_saver'
import InspectionDestroyer from './services/inspection_destroyer'
import InspectionImageCreator from './services/inspection_image_creator'
import InspectionImageDestroyer from './services/inspection_image_destroyer'
import InspectionImageFormHandler from './inspection_image_form_handler'
import StantMultiselect from"../../utils/stant_multiselect.coffee"
import StantMasks from "../../utils/stant_masks.coffee"

import { buildImageModal } from './utils/html'
import { timeValue, loadingProgress, requestUpdateModal, defineFlashMessage } from './utils/helpers'

import translations from 'translations'
import * as I18n from 'services/i18n'

import swal from 'sweetalert2'
import { v4 as uuidv4, validate } from 'uuid';
import Compressor from 'compressorjs';

export default class InspectionFormHandler {
  constructor() {
    this.formElements = document.querySelectorAll('.inspection-form')
    this.dynamicFormElements = document.querySelectorAll('.inspection-dynamic-form')

    const token = document.querySelector('input[name="request[user_token]"]').value
    this.inspectionSaver = new InspectionSaver(token)
    this.inspectionDestroyer = new InspectionDestroyer(token)
    this.inspectionImageCreator = new InspectionImageCreator(token)
    this.inspectionImageDestroyer = new InspectionImageDestroyer(token)
  }

  init(formElements = this.formElements, dynamicForm = this.dynamicFormElements) {
    const allFormElements = Array.from(formElements).concat(Array.from(dynamicForm))

    allFormElements.forEach(form => {
      initlistenerEvents(this, form)
    })

    $('select.select-form').select2({
      minimumResultsForSearch: Infinity,
      dropdownCssClass: 'select2-drop-fvs-preenchimento'
    })

    new StantMultiselect('multiple-question').add_listener()
    new StantMasks().init()

    handleReworkTime()
  }
}

const setupInspectionDestroyAction = (context, form) => {
  const deleteButton = form.querySelector('.delete-inspection')

  if (!deleteButton) return

  deleteButton.addEventListener('click', () => {
    showConfirmationDialog(deleteButton).then(willDelete => {
      if (willDelete.value) {
        context.inspectionDestroyer.destroy(form)
          .then(() => {
            $(form.closest('.modal')).modal('hide')
            $(form.closest('.modal-scrollable')).remove()
            $('body').removeClass('swal2-shown')


            const itemAndMethodIndex = $(form).attr('data-method-index')
            requestUpdateModal(itemAndMethodIndex)
            new StantFlashMessages(defineFlashMessage('success', 'delete_inspection')).render_floating();
          })
          .catch( error => {
            new StantFlashMessages(defineFlashMessage('error', 'delete_inspection')).render_floating();
          })
      }
    })
  })
}

function validateReworkTime(form){
  const f = $(form)
  const action = f.attr('data-action')
  const buttonSubmit = f.find('.btn-submit')
  const inputRework = f.find(`.rework-time-value-${action}`)
  const inputReworkHour = f.find('.rework-time-input.hour')
  const inputReworkMin = f.find('.rework-time-input.min')
  
  if(inputRework.val() == '00:00' || (inputReworkHour.val() === '0' && inputReworkMin.val() === '0')){
    inputReworkHour.add(inputReworkMin).addClass('error-input')
    buttonSubmit.attr('disabled', false)
    return false
  }else {
    inputReworkHour.add(inputReworkMin).addClass('error-input')
    return true
  }
}

const setupInspectionSaveAction = (context, form) => {
  const inspectionImageFormHandler = new InspectionImageFormHandler(form)
  const modal = $(form.closest('.modal'))
  const buttonSubmit = $(form).find('.btn-submit')

  form.addEventListener('submit', event => {
    if(isFieldsFilledInvalid(form)) {
      new StantFlashMessages(defineFlashMessage('error', 'not_inspectable')).render_floating()
      return
    }

    if(isGeolocalizationInvalid(form)) {
      new StantFlashMessages(defineFlashMessage('error', 'geolocalization_invalid')).render_floating()
      return
    }

    buttonSubmit.attr('disabled', true)

    if(validateReworkTime(form)) {

      context.inspectionSaver.save(form)
      .then(inspectionResponse => {
        inspectionImageFormHandler.init()
        context.inspectionImageCreator.createMany(inspectionResponse.guid, form)
          .then(inspectionsImagesresponse => {
            loadingProgress(100)

            document.querySelectorAll('.new-image-form').forEach((el) => {
              el.classList.remove('new-image-form')
            })

            const itemAndMethodIndex = $(form).attr('data-method-index')
            requestUpdateModal(itemAndMethodIndex)

            buttonSubmit.attr('disabled', false)
            modal.modal('hide')

            const resource = $(form).attr('data-resource')

            new StantFlashMessages(defineFlashMessage('success', resource)).render_floating();
          })
          .catch(error => {
            loadingProgress(100)
            buttonSubmit.attr('disabled', false)
            new StantFlashMessages(defineFlashMessage('error', 'limit_exceded')).render_floating();
          })
      })
      .catch(error => {        
        const resource = $(form).attr('data-resource')
        new StantFlashMessages(defineFlashMessage('error', resource)).render_floating();
        buttonSubmit.attr('disabled', false)
      })

    }

    event.preventDefault()
    event.stopPropagation()
  })
}

const setupInspectionCancel = (form) => {
  const cancelButtons = Array.from(form.querySelectorAll('.btn-cancel-image-form'))
  const captions = Array.from(form.querySelectorAll('.caption-image'))

  let captionsValues = {}

  captions.forEach((caption) => {
    const captionElement = $(caption)
    captionsValues[captionElement.attr('id')] = captionElement.val()
  })

  cancelButtons.forEach((button) => {
    button.addEventListener('click', function () {
      const captionELement = $($(this.closest('.inspection-image-form')).find('.caption-image'))
      captionELement.val(captionsValues[captionELement.attr('id')])
    })
  })
}

const setupImagesUpload = (context, form) => {
  const uploadInput = form.querySelector('.upload-inspection-image-input')

  uploadInput.addEventListener('change', function () {
    Array.from(this.files).forEach((image, index) => {
      const imageGuid = uuidv4()
      const imagesPreviewContainer = form.querySelector('.inspection-images-preview')
      let imagesLength = imagesPreviewContainer.querySelectorAll('.image-container').length + index

      if (imagesLength <= 99) {
        previewImage(image).then((imageContent) => {
          const imagePreview = document.createElement('div')
          imagePreview.setAttribute('class', 'btn-link image-container')
          imagePreview.innerHTML = `
            <img src="${imageContent}" data-toggle="modal" data-target="#new-image-${imageGuid}" />

            <a class="remove-image" data-guid="${imageGuid}"><i class="icon icon-remove"> </i></a>
          `

          const imageModal = document.createElement('div')
          imageModal.innerHTML = buildImageModal(imageContent, imageGuid)

          imagesPreviewContainer.appendChild(imagePreview)
          form.appendChild(imageModal)

          form.querySelector('.images-info').classList.remove('hide')

          setupImagesDestroy(context, form)
          uploadInput.value = ''

          const cancelButton = imageModal.querySelector('.btn-cancel-image-form')

          cancelButton.addEventListener('click', function () { 
            const captionELement = $(this.closest('.new-image-form')).find('.caption-image')
            $(captionELement).val('')
           })
        })
      }else {
        new StantFlashMessages(defineFlashMessage('error', 'limit_exceded')).render_floating();
      }
    })
  })
}

const setupImagesDestroy = (context, form) => {
  Array.from(form.querySelectorAll('.remove-image')).forEach((element) => {

    element.addEventListener('click', () => {
      const { guid } = element.dataset

      const imageContainer = element.closest('.image-container')
      const previewModalId = imageContainer.querySelector(`img[data-target]`).dataset.target
      const previewModalElement = document.querySelector(previewModalId)

      previewModalElement ? previewModalElement.remove() : null
      imageContainer.remove()

      form.addEventListener('submit', () => context.inspectionImageDestroyer.destroy(guid));
    })
  })
}

const previewImage = (file) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 1,
      maxWidth: 850,
      maxHeight: 850,
      checkOrientation: true,
      success(result) {
        var reader = new FileReader()
        reader.onload = (event) => { resolve(event.target.result) }
        reader.readAsDataURL(result)
      },
      error(error) {
        reject(error)
      }
    })
  })
}

const showConfirmationDialog = (element) => {
  return swal({
    title: `${I18n.t(translations(), 'fvs_preenchimentos.components.delete_inspection.confirm_title')}?`,
    html: I18n.t(translations(), 'fvs_preenchimentos.components.delete_inspection.confirm_info'),
    type: 'warning',
    target: element.parentElement,
    showCancelButton: true,
    confirmButtonText: I18n.t(translations(), 'form.confirm_yes'),
    cancelButtonText: I18n.t(translations(), 'form.confirm_no'),
  })
}

const handleReworkTime = () => {
  $('.rework-time-input').removeAttr('name')

  $('.rework-time-input').on('change', function () {
    const containerReworkTimeReinspection = $(this.closest('.rework-time.update'))
    const containerReworkTimeInspection = $(this.closest('.rework-time.new'))

    const inputValueReinspection = containerReworkTimeReinspection.children('input')
    const inputValueInspection = containerReworkTimeInspection.children('input')

    const hourReinspection = timeValue(containerReworkTimeReinspection).hour
    const minReinspection = timeValue(containerReworkTimeReinspection).min

    inputValueReinspection.val(`${hourReinspection}:${minReinspection}`)

    const hourInspection = timeValue(containerReworkTimeInspection).hour
    const minInspection = timeValue(containerReworkTimeInspection).min

    inputValueInspection.val(`${hourInspection}:${minInspection}`)
  })
}

const isFieldsFilledInvalid = (form) => {
  const signatureField = form.querySelector('.signature-field')
  const { action } = form.dataset

  return (action === 'new' && signatureField)
}

const isGeolocalizationInvalid = (form) => {
  const { 0: latitude, 1: longitude } = $(form).find(
    '[data-dynamic-field-type="geolocalization"] input'
  )

  if(!latitude && !longitude) return

  const latitudeValue = latitude.value.trim()
  const longitudeValue = longitude.value.trim()

  const latitudeValidate = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,15}/g;
  const longitudeValidate = /^-?(([-+]?)([\d]{1,3})((\.)(\d+))?)/g;

  const latitudeIsValid = latitudeValue.match(latitudeValidate)
  const longitudeIsValid = longitudeValue.match(longitudeValidate)

  const isEqual = (regexValidation, coordinateValue) => {
    if(regexValidation) return regexValidation[0] === coordinateValue
  }

  return !(
    isEqual(latitudeIsValid, latitudeValue) &&
    isEqual(longitudeIsValid, longitudeValue)
  )
}

export const initlistenerEvents = (context, form) => {
  setupInspectionDestroyAction(context, form)
  setupInspectionSaveAction(context, form)
  setupImagesUpload(context, form)
  setupImagesDestroy(context, form)
  setupInspectionCancel(form)
}