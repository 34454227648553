import DropzoneAdapter from "../../utils/dropzone_adapter.coffee"
import AjaxLoader from "../../utils/ajax_loader.coffee"
import translations from "../../translations"
import * as I18n from "../../services/i18n"

export default class UploadPlanningTemplateHandler
  constructor: ->
    @dropzone_form              = $("#plannings-template-dropzone")
    @dropzone_modal             = $('#dropzone-lightbox')
    @dropzone_modal_upload_link = @dropzone_modal.find('#dropzone-upload-link')

  call: ->
    self = @

    return if self.dropzone_form.length == 0
    new DropzoneAdapter(self.dropzone_form, self.dropzone_modal, $('#dropzone-preview-template')).add_listeners
      maxFiles: 1

    self.dropzone_form.on 'addedFile', (event, data) ->
      self.dropzone_modal_upload_link.addClass('hide')

    self.dropzone_form.on 'startSending', (event, data) ->
      new AjaxLoader().show()

    self.dropzone_form.on 'uploadComplete', (event, data) ->
      new AjaxLoader().hide()

    self.dropzone_form.on 'uploadSuccess', (event, data, response) ->
      finish_button_html = """
        <a href="javascript:void(0)" class="btn btn-success" data-dismiss="modal">
          <i class="icon icon-upload"></i>
          <span>#{I18n.t(translations(), 'atividades.index.finish_upload')}</span>
        </a>
      """

      modal_footer = self.dropzone_modal.find('.modal-footer')
      modal_footer.append(finish_button_html)

      self.dropzone_modal.on 'hidden.bs.modal', ->
        window.location.replace(response.reload_url)

    self.dropzone_form.on 'uploadFailed', (event, data, response) ->
      self.dropzone_modal_upload_link.find('.text').text(I18n.t(translations(), 'atividades.index.reupload_file'))
      self.dropzone_modal_upload_link.removeClass('hide')