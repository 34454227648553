export default class StantFlashMessages
  constructor: (@html) ->

  render: ->
    $('.flash').html(@html)
    handle_close_click()

  render_floating_fixed: ->
    $('.flash').html(@html)
    $('.flash-messages').css({'position':'fixed', 'z-index': 5000})
    handle_close_click()

  render_floating: (timeout) ->
    timeout ||= 3000

    $('.flash').html(@html)
    $('.flash-messages').css({'position':'fixed', 'z-index': 5000})
    $('.alert-box').delay(timeout).fadeOut("slow")
    handle_close_click()

  handle_close_click = ->
    $('.alert-box .close').off().on 'click', ->
      $('.alert-box').hide()

window.StantFlashMessages = StantFlashMessages