$(document).on 'turbolinks:load', ->
  interceptor_delegate('.js-step-save', 'click')
  interceptor_delegate('.js-save-preplanning', 'click')
  interceptor_delegate('.js-save-planning', 'click')
  interceptor_delegate('.js-edit-planning', 'click')

interceptor_delegate = (element, event) ->
  $(element).on event, (e) ->
    new AjaxLoader().show()
    unless check_construction_site()
      e.preventDefault()
      e.target.disabled = true
      return

check_construction_site = ->
  construction_site_id = $('meta#js_general_construction_site_id').data('id')
  valid = null

  $.ajax {
    url: "/check_selected_construction_site/#{construction_site_id}",
    data: '',
    type: 'GET',
    dataType: 'script',
    async: false,
    success: ->
      valid = true
    error: ->
      valid = false
    done: ->
      new AjaxLoader().hide()
  }

  return valid
