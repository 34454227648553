import AjaxLoader from '../../../utils/ajax_loader.coffee'
import Error from '../../../utils/error.coffee'
import * as I18n from '../../../services/i18n'
import translations from '../../../translations'
import {
  set_url_for_upload_images_options,
  show_static_modal_and_toggle_button_visibility,
  process_upload_queue,
  handle_done_modal_button_click
} from '../../occurrences/helpers'

export default class OccurrenceActionCreate
  constructor: ->
    @form_fields = $('
      #js_occurrence_action_name,
      #js_occurrence_action_attitude,
      #js_occurrence_action_responsibles,
      #js_occurrence_action_deadline
    ')
    @save_button = $('.js_new_occurrence_action_button')

  handler_events: ->
    handle_ajax_form_submit.call(@)

  handle_ajax_form_submit = ->
    self = @
    @save_button.on 'click', (e) ->
      e.preventDefault()

      action_form = $('.js_new_occurrence_action_form')
      is_edit = action_form.hasClass('edit')

      form = action_form.get(0)
      form_data = new FormData(form)

      $.ajax {
        method: form.method,
        url: form.action,
        dataType: 'json',
        data: form_data,
        processData: false,
        contentType: false,
        success: (response) ->
          handle_success_create(response, is_edit)
        beforeSend: (xhr) ->
          before_send_data_submit(xhr, self)
        complete: (xhr) ->
          complete_data_submit(xhr.responseText, self)
      }

  handle_success_create = (data, edit) ->
    id = data.id
    occurrence_action_photos_form = $(".js-occurrence-action-photos-dropzone-modal")

    occurrence_action_files_lenght = 0
    occurrence_action_files_lenght = occurrence_action_photos_form.prop("dropzone").getQueuedFiles().length unless edit

    message_type = 'create_success'
    message_type = 'update_success' if edit

    redirect_path = "/occurrence_actions/#{id}/details?message_type=#{message_type}"

    return Turbolinks.visit(redirect_path) if !occurrence_action_files_lenght

    set_url_for_upload_images_options(occurrence_action_photos_form, id)
    show_static_modal_and_toggle_button_visibility('#js-occurrence-action-dropzone-lightbox-modal')
    process_upload_queue(occurrence_action_photos_form)
    handle_done_modal_button_click(redirect_path)

  before_send_data_submit = (xhr, context) ->
    self = context
    self.save_button.attr('disabled', 'disabled')
    new AjaxLoader().show()
    Error.clear_global_errors()
    send_form = true
    self.form_fields.each ->
      element = $(@)
      unless element.val() && element.val().length
        add_error_to_input(element)
        send_form = false
        new AjaxLoader().hide()
        self.save_button.attr('disabled', false)

    return xhr.abort() unless send_form

  add_error_to_input = (element) ->
    id_input = element.attr('id')
    message = I18n.t(translations(), 'errors.messages.blank')
    new Error(id_input, message).show()

  complete_data_submit = (response_text, self) ->
    unless response_text.substring(0, 10) == 'Turbolinks'
      self.save_button.attr('disabled', false)
      new AjaxLoader().hide()
