import { buildHeaders } from '../utils/http'

export default class VerifiedMethodUpdater {
  constructor(token) {
    this.headers = buildHeaders(token)
  }

  update(baseElement) {
    const { methodGuid, action } = baseElement.dataset

    return new Promise((resolve, reject) => {
      fetch(urlByAction(action), {
        method: 'POST',
        headers: this.headers,
        body: JSON.stringify(buildParams(action, methodGuid)) 
      }).then((response) => {
        response.json().then((data) => {
          if(response.ok) resolve(data)
          else reject(data)
        })
      }).catch(error => {
        reject(error)
      })
    })
  }
}

const urlByAction = (action) => {
  const urls = {
    not_applicable: '/api/v1/verified_methods/not_applicable_method',
    not_inspected: '/api/v1/verified_methods/not_inspected_method'
  }

  return urls[action]
}

const buildParams = (action, methodGuid) => {
  let params = {
    verified_method_guid: methodGuid
  }

  if (action == 'not_applicable')
    Object.assign(params, { not_applicable_status: 1 })

  return params
}
