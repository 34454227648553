import translations from "../../translations"
import * as I18n from "../../services/i18n"

export default class PermissionTrBuilder

  build_tr: (datable_id, object, is_new = false) ->
    remove_all_rows.call(@,datable_id)

    setting_attrs_as_new_user.call(@,object) if is_new
    setting_attrs_as_existent_user.call(@,object) unless is_new

    if is_new
      build_tr_html.call(@,'')
    else
      build_tr_html.call(@,object.id)

  remove_all_rows = (datable_id) ->
    $("#data-permission-lightbox-#{datable_id}").find('.selected-users tr').not('.select-all').remove()

  setting_attrs_as_new_user = (user) ->
    @tr_id = "new-permission-#{user.id}"
    @email = user.email
    @user_id = user.id
    @destroy_attrs = " href='javascript:void(0)' "
    @disabled = ""
    @checkboxes = {}

  setting_attrs_as_existent_user = (permission) ->
    @tr_id = "permission-#{permission.id}"
    @user_id = permission.usuarios_id
    @email = permission.user_email
    @disabled =  element_disabled.call(@,permission)
    @checkboxes = { show: has_permission.call(@,permission.permissions, 'show'), update: has_permission.call(@,permission.permissions, 'update'), destroy: has_permission.call(@,permission.permissions, 'destroy') }
    @destroy_url = "/destroy_data_permission?#{$.param(data_permission: permission)}"
    @destroy_attrs = " href='#{@destroy_url}' data-confirm='#{I18n.t(translations(), 'documents.manager.data_permission_form.confirm')}' data-remote='true' data-method='delete' "

  build_tr_html = (object_id)->
    tr_html = """
              <tr id="#{@tr_id}">
                <input type='hidden' name='data_permission[users][][data_permission_id]' value='#{object_id}' #{@disabled}>
                <td>#{@email}
                  <input type="hidden" name='data_permission[users][][id]' class='user_id' value='#{@user_id}' #{@disabled}>
                </td>

                #{build_td_checkbox.call(@,'show')}
                #{build_td_checkbox.call(@,'update')}
                #{build_td_checkbox.call(@,'destroy')}

                <td class="txt-center">
                  <a id="remove-link-#{@user_id}" #{@destroy_attrs}
                     class="btn btn-small btn-link btn-danger remove-new #{@disabled}">
                    <i class="icon icon-remove"></i>
                  </a>
                </td>
              </tr>
              """

    return tr_html

  build_td_checkbox = (action) ->
    checked = 'checked' if @checkboxes[action]
    td_html = """
              <td class='txt-center'>
                <input class='td-#{action}' type='checkbox' name='data_permission[users][][permissions][]' value='#{action}'
                         #{checked} #{@disabled}>
              </td>
              """

  has_permission = (list, permission) ->
    list.indexOf(permission) != -1

  element_disabled = (data_permission) ->
    'disabled' if data_permission.disabled
