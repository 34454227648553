import StantMultiselect from "../../../utils/stant_multiselect"
import AjaxLoader from "../../../utils/ajax_loader.coffee"

$(document).on('turbolinks:load', function() {
  new StantMultiselect('report-costs-and-trends-multiselect').add_listener()
  
  $('.checkbox-all-period').on('click', function() {
    $(`.${this.dataset.period}`).toggleClass('is-disabled')
  })

  $('#form-costs-and-trends').on('submit', function() {
    new AjaxLoader().show()
    $('.btn-submit').addClass('is-disabled')
  })
})