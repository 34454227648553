import { get, isString, isObject, reduce, Dictionary } from "lodash"

export function t(translations, path, interpolations) {
  var translation = get(translations, path);
  if (!isString(translation)) {
      if (process.env.DEBUG) {
          if (translation === undefined) {
              throw ("Unknown translation path: " + path);
          }
          throw ("Path " + path + " did not map to a string. Got " + translation);
      }
      return "";
  }
  return reduce(interpolations, function (result, value, key) { return result.replace("%{" + key + "}", value); }, translation);
}