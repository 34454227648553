import ContractProgressEvaluation from "./contract_progress_evaluation.coffee"

$(document).on('turbolinks:load', function(){

  var contract_progress_evaluation = new ContractProgressEvaluation();
  contract_progress_evaluation.start_handlers();
  contract_progress_evaluation.show_selecteds();
  $('#contract-progress-evaluation-form').submit(function (e) {
    e.preventDefault();
  });
});
