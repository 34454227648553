import AjaxLoader from "../../utils/ajax_loader.coffee"

export default class SavePlannings
  handler_events: ->
    handler_on_click()

  handler_on_click = ->
    $('form.simple_form.plannings').on 'submit', ->
      configure_ajax_loader_and_disable_input()

  configure_ajax_loader_and_disable_input = ->
    element = $('input[type=submit]:enabled')
    element.attr('disabled','disabled')
    new AjaxLoader().show()

  configure_ajax_loader_and_enable_input: ->
    $('input[type=submit]:disabled').attr('disabled', false)
    new AjaxLoader().hide()

window.SavePlannings = SavePlannings
