import "javascripts/jquery-multi-select/jquery.multi-select"
import translations from "../translations"
import * as I18n from "../services/i18n"

export default class StantMultiselectBox
  constructor: (@id, @class_name, @selectable_header, @selection_header) ->

  add_listener: ->
    $(".#{@class_name}").multiSelect
      selectableHeader: build_selectable_header.call(@)
      selectionHeader: build_selection_header.call(@)
      afterInit: (ms) ->
        that = undefined
        that = this
        build_after_init(that)
      afterSelect: ->
        @query_search_selectable.cache()
        @query_search_selected.cache()
      afterDeselect: ->
        @query_search_selectable.cache()
        @query_search_selected.cache()

    add_listener_for_select_all(@)
    add_listener_for_deselect_all(@)

  # ---- Private methods ----

  build_selectable_header = ->
    build_header @selectable_header, "ms-select-all", I18n.t(translations(), 'multi_select.select_all')

  build_selection_header = ->
    build_header @selection_header, "ms-deselect-all", I18n.t(translations(), 'multi_select.deselect_all')

  build_header = (obj_title, class_link, title_link) ->
    "<div class='input-group'>
      <span class='input-group-addon'>" + obj_title + "</span>
      <a href='javascript:void(0);' class='bt-link " + class_link + "'>" + title_link + "</a>
      <input type='text' class=' search-input form-control' autocomplete='off' placeholder='#{I18n.t(translations(), 'multi_select.placeholder')}'>
    </div>"

  build_after_init = (that) ->
    $selectableSearch            = undefined
    $selectionSearch             = undefined
    selectableSearchString       = undefined
    selectionSearchString        = undefined
    $selectableSearch            = that.$selectableUl.prev().children().next()
    $selectionSearch             = that.$selectionUl.prev().children().next()
    selectableSearchString       = "#" + that.$container.attr("id") + " .ms-elem-selectable:not(.ms-selected)"
    selectionSearchString        = "#" + that.$container.attr("id") + " .ms-elem-selection.ms-selected"
    add_label_for_no_search_results(that.$selectableContainer, that.$selectionContainer)
    that.query_search_selectable = $selectableSearch.quicksearch(selectableSearchString, noResults: '.ms-selectable-no-results').on("keydown", (e) ->
      if e.which is 40
        that.$selectableUl.focus()
        false
    )
    that.query_search_selected = $selectionSearch.quicksearch(selectionSearchString, noResults: '.ms-selection-no-results').on("keydown", (e) ->
      if e.which is 40
        that.$selectionUl.focus()
        false
    )

  add_listener_for_select_all = (self) ->
    $(".ms-select-all").on "click", ->
      $(".#{self.class_name}").multiSelect "select_all"

  add_listener_for_deselect_all = (self) ->
    $(".ms-deselect-all").on "click", ->
      $(".#{self.class_name}").multiSelect "deselect_all"

  add_label_for_no_search_results = (selectable, selection) ->
    selectableOptionsList = selectable.find('.ms-list')
    selectableOptionsList.append("<div class='ms-selectable-no-results center ms-elem-selectable' style='display: none;'>#{I18n.t(translations(), 'users.index.search_result_empty')}</div>")

    selectionOptionsList = selection.find('.ms-list')
    selectionOptionsList.append("<div class='ms-selection-no-results center ms-elem-selection' style='display: none;'>#{I18n.t(translations(), 'users.index.search_result_empty')}</div>")
