import AjaxLoader from "../../utils/ajax_loader.coffee";

export default class CopyStepPlannings {
  static handleEvents() {
    const checkPartialCopy = this.checkPartialCopy;
    const loadSteps = this.loadSteps;
    const checkIfFormIsValid = this.checkIfFormIsValid;

    $(".planning-checked").trigger("click");

    $("#js_planning_step_id").on("change", function (e) {
      checkIfFormIsValid();
    });

    $("#js_macro_select").on("change", function (e) {
      const option = $(e.target).find("option:selected");
      const stepGroupId = option.val();
      $("#js_planning_step_id").val("");

      if (stepGroupId === "0") {
        $(".js_planning_step_step_group_id").hide();
        loadSteps(stepGroupId);
      } else {
        $(".js_planning_step_step_group_id").show();
      }

      checkIfFormIsValid();
    });

    $("#js_planning_step_step_group_id").on("change", function (e) {
      $("#js_copy_submit_btn").prop("disabled", true);
      const option = $(e.target).find("option:selected");
      const stepGroupId = option.val();
      $("#js_planning_step_id").val("");

      if (stepGroupId) {
        loadSteps(stepGroupId);
      }

      checkIfFormIsValid();
    });

    $(document).on("change", "#expand-services-accordion", function (e) {
      if (e.target.checked) {
        $(".collapse").collapse("show");
      } else {
        $(".collapse").collapse("hide");
      }
    });

    $(document).on("change", ".copy-service-checkbox", function (e) {
      const checkbox = $(e.target);
      const serviceId = checkbox.data("service-id");
      const planningsCheckbox = $(".plannings-copy-checbox");
      const servicePlannings = planningsCheckbox.filter(
        `.plannings-copy-checbox-service-${serviceId}`
      );

      servicePlannings.prop("checked", e.target.checked);

      checkPartialCopy(planningsCheckbox);
      checkIfFormIsValid();
    });

    $(document).on("change", ".plannings-copy-checbox", function (e) {
      const checkbox = $(e.target);
      const serviceId = checkbox.data("service-id");

      const planningsCheckbox = $(".plannings-copy-checbox");
      const servicePlannings = planningsCheckbox.filter(
        `.plannings-copy-checbox-service-${serviceId}`
      );

      const checkService = !servicePlannings.not(":checked").length;

      checkPartialCopy(planningsCheckbox);

      const serviceCheckbox = $(".copy-service-checkbox").filter(
        `[data-service-id="${serviceId}"]`
      );

      serviceCheckbox.prop("checked", checkService);
      checkIfFormIsValid();
    });
  }

  static checkPartialCopy(planningsCheckboxes) {
    const isPartialCopy = !!planningsCheckboxes.not(":checked").length;

    if (isPartialCopy) {
      $("#partial-copy-warning").css("display", "flex");
    } else {
      $("#partial-copy-warning").hide();
    }
  }

  static loadSteps(stepGroupId) {
    new AjaxLoader("loader-step-group").show();

    $.ajax({
      type: "GET",
      dataType: "json",
      data: { id: stepGroupId },
      url: "/plannings/steps/search.json",
      success(steps) {
        const default_option = "Por favor selecione uma opção";
        let html = `<option value=''> ${default_option} </option>`;
        const optionsHtml = steps
          .map((step) => `<option value=${step.id}> ${step.name} </option>`)
          .join();
        html += optionsHtml;

        $("#js_planning_step_id").empty().append(html);

        new AjaxLoader("loader-step-group").hide();
      },
    });
  }

  static checkIfFormIsValid() {
    const stepInput = $("#js_planning_step_id");
    const planningsCheckbox = $(".plannings-copy-checbox").filter(":checked");
    const enableButton = stepInput.val() && planningsCheckbox.length;
    $("#js_copy_submit_btn").prop("disabled", !enableButton);
  }
}
