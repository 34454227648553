import MethodImageUploadHandler from './method_images_upload_handler'
import translations from "../../translations"
import * as I18n from "../../services/i18n"
import ManageMethodImagesModalBuilder from "./manage_method_images_modal_builder"

export default class BuildActionButtons {
  handler_events() {
    this.handle_click_add_dynamic_form_button();
    this.add_possible_value_button();
    this.handle_click_add_new_field();
    this.check_switch_checkbox_status();
  }

  check_switch_checkbox_status() {
    $('.switch-checkbox').each(function () {
      var methodId = $(this).data('method-id');
      if ($(this).attr('checked')) {
        $(`#collapseForm-${methodId}`).collapse('show');
      }
    });
  }

  add_possible_value_button() {
    $(document).off('change', '.dynamic-field-type').on('change', '.dynamic-field-type', function () {
      let element = this
      var value = $(element).attr('dynamic-field')
      if (element.value == 'selection') {
        $(`.add-possible-value-${value}`).removeClass("hide") 
        $(`.add-possible-value-${value}`).find('input').removeAttr('disabled');
      } else if (element.value == 'multiple') {
        $(`.add-possible-value-${value}`).removeClass("hide") 
        $(`.add-possible-value-${value}`).find('input').removeAttr('disabled');
      } else {
        $(`.add-possible-value-${value}`).addClass("hide")
        $(`.add-possible-value-${value}`).find('input').attr('disabled', true);
      }
    });
  }

  handle_click_add_dynamic_form_button() {
    var self = this;

    $(document)
      .off('click', '.switch-checkbox.required-method-field')
      .on('click', '.switch-checkbox.required-method-field', function (e) {
        var methodId = $(this).data('method-id');
        var statusButton = e.currentTarget;
        if (statusButton.checked) {
          $(`#required-method-icon-${methodId}`).removeClass('hide');
        } else {
          $(`#required-method-icon-${methodId}`).addClass('hide');
        }

      })

    $(document)
      .off('click', '.switch-checkbox.dynamic-form')
      .on('click', '.switch-checkbox.dynamic-form', function (e) {
        var methodId = $(this).data('method-id');
        var statusButton = e.currentTarget;
        if (statusButton.checked) {
          $(`#collapseForm-${methodId}`).collapse('show');
          $(`#collapseForm-${methodId}`).find('input').removeAttr('disabled');
          $(`#collapseForm-${methodId}`).find('.dynamic-field-type').removeAttr('disabled');
          $(`#dynamic-form-collapse-icon-${methodId}`).removeClass('hide');
        } else {
          $(`#collapseForm-${methodId}`).collapse('hide');
          $(`#collapseForm-${methodId}`).find('input').attr('disabled', true);
          $(`#collapseForm-${methodId}`).find('.dynamic-field-type').attr('disabled', true);
          $(`.dynamic-field-${methodId}`).remove();
          $(`#collapseForm-${methodId}`).find('input:hidden').remove();
          $(`#dynamic-form-collapse-icon-${methodId}`).addClass('hide');
        }
      });
  }

  handle_click_add_new_field() {
    var self = this
    $('#items-list').on('cocoon:after-insert', function (event, insertedItem) {
      if (insertedItem.hasClass('item-metodo')) {
        self.build_collapse_actions_buttons(event, insertedItem);
        event.stopPropagation()
      } else if (insertedItem.hasClass('dynamic-field-div')) {
        insertedItem.children().first().children('div.div-select').children('select').attr('dynamic-field', event.timeStamp)
        insertedItem.children().last().addClass(`add-possible-value-${event.timeStamp}`);
        self.add_order_value_in_dynamic_field(insertedItem);
        event.stopPropagation()
      } else if (insertedItem.hasClass('possible-value-div')) {
        self.add_order_value_in_possible_value(insertedItem);
        event.stopPropagation()
      }
    })
  }

  build_collapse_actions_buttons(event, insertedItem) {
    var self = this;
    insertedItem
      .find('i.icon.icon-critical-icon')
      .attr('id', `required-method-icon-${event.timeStamp}`);

    var divButtonAction = insertedItem.find('div.method-actions')
    divButtonAction.find('i.icon.icon-photo').attr('data-target', `#collapse-image-${event.timeStamp}`);
    divButtonAction.find('i.icon.icon-edit').attr('data-target', `#collapse-form-${event.timeStamp}`);
    divButtonAction.find('i.icon.icon-edit').attr('id', `dynamic-form-collapse-icon-${event.timeStamp}`);
    divButtonAction.find('i.icon.icon-config').attr('data-target', `#collapse-config-form-${event.timeStamp}`);
    self.build_div_collapse_image(event, insertedItem);
    self.build_div_collapse_dynamic_fields(self, event, insertedItem);
  }

  build_div_collapse_image(event, insertedItem) {
    const id = event.timeStamp

    insertedItem.find('div.collapse-image').attr('id', `collapse-image-${id}`)
    insertedItem.find('.js-add-item-method-images-')
      .removeClass('js-add-item-method-images-')
      .addClass(`js-add-item-method-images-${id}`)
      .attr('data-method-id', id)
      .attr('data-target', `#js-manage-method-images-${id}`)
    insertedItem.find('#-hidden-input').attr('id', `${id}-hidden-input`)

    ManageMethodImagesModalBuilder.build(id);

    new MethodImageUploadHandler().initializeDropzone(id)
  }

  build_div_collapse_dynamic_fields(self, event, insertedItem) {
    insertedItem.find('div.collapse-dynamic-field').attr('id', `collapseForm-${event.timeStamp}`);
    insertedItem.find('div.collapse-dynamic-form').attr('id', `collapse-form-${event.timeStamp}`);
    insertedItem.find('div.collapse-config-form').attr('id', `collapse-config-form-${event.timeStamp}`);
    self.build_checkbox_actions(event, insertedItem);
  }

  build_checkbox_actions(event, insertedItem) {
    insertedItem.find('.switch-checkbox').attr('data-method-id', event.timeStamp)

    insertedItem
      .find('.switch-checkbox.dynamic-form')
      .attr('id', `checkbox-dynamic-form-${event.timeStamp}`)

    insertedItem
      .find('.required-method-field')
      .attr('id', `checkbox-required-method-${event.timeStamp}`)

    insertedItem.
      find('label.switch-label.dynamic-form-switch')
      .attr('for', `checkbox-dynamic-form-${event.timeStamp}`)

    insertedItem.
      find('label.switch-label.required-method')
      .attr('for', `checkbox-required-method-${event.timeStamp}`)

    insertedItem.find('div.switch-target').attr('data-target', `#collapseForm-${event.timeStamp}`)

    insertedItem
      .find('label.switch-label.dynamic-form-switch')
      .attr('data-target', event.timeStamp)

    insertedItem
      .find('label.switch-label')
      .attr('data-method-id', event.timeStamp)
  }

  add_order_value_in_dynamic_field(insertedItem) {
    let dynamicFieldsElements = insertedItem.parent('.dynamic_fields').parent('.dynamic-form').find('input:hidden.order-dynamic-field')
    let max = 1;
    dynamicFieldsElements.each(function (index, element) {
      $(element).val(max);
      max = max + 1;
    });
  }

  add_order_value_in_possible_value(insertedItem) {
    let divParentElements = insertedItem.parent('.possible-values').find('input.possible-value-order');
    let possibleValuesElements = insertedItem.parent('.possible-values').parent('.col-md-5').find('input.possible-value-order')
    let max = 1

    possibleValuesElements.each(function (index, element) {
      $(element).val(max);
      max = max + 1;
    });
  }
}
