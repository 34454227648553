import { v4 as uuidv4 } from 'uuid';
import { httpMethodByAction, buildHeaders } from '../utils/http'
import { getFormattedDate, getAttributeValue, getDynamicFormParams } from '../utils/params'

export default class InspectionSaver {
  constructor(token) {
    this.headers = buildHeaders(token)
  }

  save(form) {
    const { resource, action } = form.dataset
    const methodGuid = getAttributeValue(form, resource, 'verified_method_guid')

    const guid = action === 'new' ? uuidv4() : getAttributeValue(form, resource, 'guid')
    const params = Object.assign({ guid }, paramsByResource(form, resource, action))

    console.log(params)

    return new Promise((resolve, reject) => {
      fetch(urlByResource(resource, action, { methodGuid, guid }), {
        method: httpMethodByAction(action),
        headers: this.headers,
        body: JSON.stringify(params)
      }).then((response) => {
        response.json().then((data) => {
          if(response.ok) resolve(data)
          else reject(data)
        })
      }).catch(error => {
        reject(error)
      })
    })
  }
}

const paramsByResource = (form, resource, action) => {
  const resourceParams = {
    simple_approved: () => simpleApprovedParams(form, resource, action),
    approved: () => approvedParams(form, resource, action),
    disapproved: () => disapprovedParams(form, resource, action)
  }

  return resourceParams[resource]()
}

const simpleApprovedParams = (form, resource, action) => {
  const { isDynamicForm } = form.dataset

  if(isDynamicForm) {
    return {
      ...baseParams(form, resource, action),
      dynamic_values: getDynamicFormParams(form),
    }
  }

  return baseParams(form, resource, action)
}

const approvedParams = (form, resource, action) => {
  const params = {
    inspection_parent_guid: getAttributeValue(form, resource, 'inspection_parent_guid'),
    rework_time: getAttributeValue(form, resource, 'rework_time'),
    attitude_effectiveness: getAttributeValue(form, resource, 'attitude_effectiveness')
  }

  return Object.assign(baseParams(form, resource, action), params)
}

const disapprovedParams = (form, resource, action) => {
  const params = {
    inspection_parent_guid: getAttributeValue(form, resource, 'inspection_parent_guid'),
    errors_count: getAttributeValue(form, resource, 'errors_count'),
    severity_level: getAttributeValue(form, resource, 'severity_level'),
    attitude: getAttributeValue(form, resource, 'attitude'),
    deadline_at: getFormattedDate(form, resource, 'deadline_at')
  }

  return Object.assign(baseParams(form, resource, action), params)
}

const baseParams = (form, resource, action) => {
  let params = {
    description: getAttributeValue(form, resource, 'description'),
    situation: getAttributeValue(form, resource, 'situation'),
    inspectable_at: getFormattedDate(form, resource, 'inspectable_at'),
    updated_at: new Date().toISOString()
  }

  if(action === 'new') {
    params = {
      ...params,
      created_at: new Date().toISOString(),
    }
  }

  return params
}

const urlByResource = (resource, action, params = {}) => {
  const { methodGuid, guid } = params
  const urls = {
    simple_approved: `/api/v3/verified_methods/${methodGuid}/simple_approve_inspection`,
    approved: `/api/v3/verified_methods/${methodGuid}/approve_inspection`,
    disapproved: `/api/v3/verified_methods/${methodGuid}/disapprove_inspection`
  }

  let url = urls[resource]
  if (action === 'update') url = `${url}/${guid}`

  return url
}
