import CountFieldCharacters from '../../../utils/count_field_characters'
import TextEditor from '../../../utils/text_editor'
import './utils/siff_filters_helper'
import StantMultiselect from "../../../utils/stant_multiselect";

$(document).on('turbolinks:load', function () {
  new StantMultiselect('report-generator-multiselect').add_listener();
  new StantMultiselect('report-generator-manageable-services-multiselect', true, true).add_listener();

  if($("#comments").length !== 0){
    const editorOptions = { disabled: true }
    const editor = new TextEditor("#reportsgenerator-comments", editorOptions)
    editor.render()

    $(document).on('change', '#comments', function() {
      if ($(this).is(':checked'))
        editor.enable()
      else
        editor.disable()
    })
  }
})


