import InteractiveTable from './interactive_table'
import translations from "translations"
import * as I18n from "services/i18n"

export default class ReportHeaderHandler {
  constructor(modules) {
    Object.assign(this, { modules })
    Object.freeze(this)
  }

  start() {
    this.modules.forEach((module) => {
      this.handleHeaderEditorFor(module)
    })
  }

  handleHeaderEditorFor(module) {
    const modalId = `edit-${module}-header`
    const modalElement = document.getElementById(modalId)
    if(!modalElement) return

    const constructionSiteId = document.getElementById('request_construction_site_id').value
    const token = document.querySelector('input[name="token"]').value
    const params = {
      construction_site_id: constructionSiteId,
      report_module: module,
      token: token
    }
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')

    fetch(`/reports/reports_generator/get_report_header_module.json?${queryString}`)
      .then((response) => {
        if(response.ok) {
          response.json().then((data) => {
            modalElement.dataset.headerlayout = JSON.stringify(data)

            new InteractiveTable({
              tableContainerSelector: `#${modalId} .header-table-container`,
              data: data
            }).init()

            handleHiddenHeader(data.hidden, modalId)
            handleReportHeaderUpdate(module)
            handleEditorReset(module)
          })
        } else console.log(`Error while loading report header for ${module}`, response)
      })
      .catch((error) => console.log(`Error while loading report header for ${module}`, error) )
  }
}

function handleHiddenHeader(initialState, modalId) {
  toggleHeaderEditing(initialState, modalId)

  const checkbox = document.querySelector('#hidden-header-checkbox')
  checkbox.addEventListener('change', ({target}) => {
    toggleHeaderEditing(target.checked, modalId)
  })
}

function handleReportHeaderUpdate(module) {
  const modalId = `edit-${module}-header`

  document.querySelector(`#${modalId} .update-reportheader`).addEventListener('click', () => {
    const modalElement = document.querySelector(`#${modalId}`)
    const headerLayoutData = JSON.parse(modalElement.dataset.headerlayout)
    const id = headerLayoutData.id
    const token = modalElement.querySelector('input[name="token"]').value
    const constructionSiteId = document.getElementById('request_construction_site_id').value

    const hiddenHeader = document.querySelector('#hidden-header-checkbox').checked
    const hiddenFooter = document.querySelector('#hidden-footer-checkbox').checked
    let headers = headerLayoutData.header.map((cellData, index) => {
      const columnIndex = cellData.row === 1 ? index : index - headerLayoutData.columns_per_row
      const selector = `.header-cell[data-row="${cellData.row}"][data-column="${columnIndex}"]`
      const cell = modalElement.querySelector(selector)
      let headerItem = cellData

      if(cell) {
        const title = cell.querySelector('input[name="title"]').value
        const field = cell.querySelector('select[name="field"]').value
        const column = parseInt(cell.dataset.column)
        let merged = Array(getCellColspan(cell) -1).fill(1)
        merged = merged.map((_, index) => column + index+1)

        headerItem = {
          title: title,
          field: field,
          total_columns: getCellColspan(cell),
          row: cellData.row,
          merged_columns: merged
        }
      } else {
        headerItem.total_columns = 0
      }

      return headerItem
    })

    const params = {
      hidden: hiddenHeader,
      hidden_footer: hiddenFooter,
      header: headers,
      token: token,
      construction_site_id: constructionSiteId
    }

    fetch(`/reports/reports_generator/update_report_header_module/${id}`, {
      method: 'PUT',
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
    }).then((response) => {
      if(response.ok) {
        response.json().then((data) => {
          modalElement.dataset.headerlayout = JSON.stringify(data)
          new StantFlashMessages(successFlashMessage()).render_floating();
          $(`#${modalId}`).modal('toggle')
        })
      } else console.log(`Error while loading report header ${module}`, response)
    }).catch((error) => {
      console.log(`Error while loading report header ${module}`, error)
    }).finally(() => {
      new AjaxLoader().hide()
      document.querySelector(`#${modalId} .update-reportheader`).removeAttribute('disabled')
    })
  })
}

function toggleHeaderEditing(state, modalId) {
  const checkbox = document.querySelector('#hidden-header-checkbox')
  checkbox.checked = state

  document.querySelector(`#${modalId} .headeraction.merge`).disabled = state
  document.querySelector(`#${modalId} .headeraction.split`).disabled = state

  const tableInputs = document.querySelectorAll(`#${modalId} .header-cell input, #${modalId} .header-cell select`)
  Array.from(tableInputs).forEach((element) => {
    element.disabled = state
    state ? element.setAttribute('readonly','readonly') : element.removeAttribute('readonly')
  })
}

function handleEditorReset(module) {
  const modalId = `edit-${module}-header`

  $(document).on('hidden.bs.modal', `#${modalId}`, function () {
    const headerLayoutData = JSON.parse(this.dataset.headerlayout)
    this.querySelector('table').remove()

    new InteractiveTable({
      tableContainerSelector: `#${modalId} .header-table-container`,
      data: headerLayoutData
    }).init()

    toggleHeaderEditing(headerLayoutData.hidden, modalId)
  })
}

function getCellColspan(cell) {
  return parseInt(cell.getAttribute('colspan')) || 1
}

function successFlashMessage() {
  return `
    <div class="col-md-4 offset-md-4 flash-messages">
      <div class="alert-box success animated bounceInRight">
        <p>
          ${I18n.t(translations(), 'reports.reports_generator.components.edit_header_modal.flash_success')}
          <a href="#" class="close" data-dismiss="alert" aria-label="close">×</a>
        </p>
      </div>
    </div>
  `
}
