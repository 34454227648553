import HistoryBuilder from "../../../../utils/history_builder.coffee"
import translations from "../../../../translations"
import * as I18n from "../../../../services/i18n"

export default class PlanningHistory
  handler_events: ->
    handle_on_click_history_button()

  handle_on_click_history_button = ->
    $('.planning-history-button').on 'click', ->
      histories = $(@).closest('[data-histories]').data('histories')
      html = new HistoryBuilder(histories, build_history_template).build_history_html()
      $('#layout_modal_ .layout-modal-content').html(html)
      $('#layout_modal_').modal('show')

  build_history_template = (history) ->
    value = history.historyable_json.valor
    quantity = history.historyable_json.quantidade.toFixed(2)

    """
      <div class="history-item">
        <h4> #{format_date(history.created_at)} </h4>
        <p> #{I18n.t(translations(), 'atividades.form.value')}: #{StantFormat.converteFloatMoeda(value)} </p>
        <p> #{I18n.t(translations(), 'atividades.form.quantity')}: #{quantity} </p>
        <p> #{I18n.t(translations(), 'atividades.form.total_value')}: #{StantFormat.converteFloatMoeda(value * quantity)} </p>
        <p> #{I18n.t(translations(), 'atividades.form.dth_start')}: #{format_date(history.historyable_json.dth_inicio)} </p>
        <p> #{I18n.t(translations(), 'atividades.form.dth_end')}: #{format_date(history.historyable_json.dth_fim)} </p>
        <p> #{I18n.t(translations(), 'atividades.form.user')}: #{history.person_name} </p>
        <hr>
      </div>
    """

  format_date = (date) ->
    dateObj = new Date(date)
    month = dateObj.getUTCMonth() + 1
    day = dateObj.getUTCDate()
    year = dateObj.getUTCFullYear()

    newdate = day + "/" + month + "/" + year
