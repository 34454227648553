import "javascripts/jquery-multiselect/jquery.multiselect"
import translations from "../translations"
import * as I18n from "../services/i18n"

export default class StantMultiSelectLevels
  constructor: (@class_name, @data_field=undefined, @showCheckbox=true) ->

  add_listener: ->
    $(".#{@class_name}").multiselect
      placeholder: I18n.t(translations(), 'multi_select.select_one_or_more_options')
      selectAll: false
      search: true
      showCheckbox: @showCheckbox
      searchOptions:
        default: I18n.t(translations(), 'multi_select.search_for')
      
    if @data_field != undefined
      build_levels_on_list(@, @data_field, @class_name)

    add_custom_links_and_listeners(@class_name)
    add_choose_children_button_listener(@class_name)

  reset: ->
    component_classname = @class_name
    el = $(".#{component_classname}")
    multiselect_ms_wrap = el.siblings('.ms-options-wrap')
    multiselect_inputs_selected = multiselect_ms_wrap.children('.ms-options').find('input')
    multiselect_inputs_selected.map (index, input_selected, class_name=component_classname) ->
      input = $(input_selected)
      child_sibling_choose_button = input.siblings(".#{class_name}-choose-child-button")
      
      if (input.prop('checked'))
        input.click()

      if (child_sibling_choose_button.length > 0)
        change_action_choose_child_button(child_sibling_choose_button, 'deselect')      

  # ---- Private methods ---- #
  build_levels_on_list = (that, data_field, component_classname) ->
    multiselect_select = $(".#{that.class_name}")
    multiselect_ms_options_labels = multiselect_select.siblings(".ms-options-wrap").find(".ms-options li label")

    multiselect_select.each (index, select) -> 
      select_options = $(select.options)

      select_options.map (index, option) ->
        option_component = $(option)
        option_data_level = option_component.attr('data-level') 

        if option_data_level == undefined
          option_new_data_level = 0
        else
          option_new_data_level = parseInt(option_data_level)

        option_component.attr('data-level', option_new_data_level) 
        option_component_value = option_component.val()

        options_selector_string = "option[#{data_field}='#{option_component_value}']"
        options_select_childs = $(multiselect_select).find(options_selector_string)

        if (options_select_childs.length > 0) 
          build_choose_children_button(multiselect_ms_options_labels, option_component_value, data_field, component_classname)

        options_select_childs.map (index, option_child, option_parent_level=option_new_data_level) ->
          option_child_component = $(option_child)
          option_child_level = option_parent_level + 1
          option_child_component.attr('data-level', option_child_level)

          option_child_selector_string = "input[value='#{option_child_component.val()}']"
          option_child_labels_input = $(multiselect_ms_options_labels).find(option_child_selector_string)

          option_child_labels_input.map (index, option_child_label_input, child_level=option_child_level) ->
            option_child_label_input_component = $(option_child_label_input)
            option_child_line_component = option_child_label_input_component.parents('li')

            option_child_line_style = "padding-left: #{(child_level * 10)}px;"
            option_child_line_component.attr("style", option_child_line_style)

  build_choose_children_button = (ms_options_component, parent_option_value, parent_data_field, component_classname) ->
    option_parent_selector_string = "input[value='#{parent_option_value}']"
    option_parent_labels_input = $(ms_options_component).find(option_parent_selector_string)
    option_child_label_component = option_parent_labels_input.parent()

    choose_children_button_label = "- #{I18n.t(translations(), 'multi_select.select_children')}"
    choose_children_button_style = "display: inline-block; border-radius: 5px; color: #ec7234; text-transform: lowercase; text-decoration: none; margin-left: 5px; font-size: 11px;"
    choose_children_button = "<a href='#' class='#{component_classname}-choose-child-button' style='#{choose_children_button_style}' data-action='select' data-option-value='#{parent_option_value}' data-option-field='#{parent_data_field}'>#{choose_children_button_label}</a>"

    option_child_label_component.append(choose_children_button)    

  add_choose_children_button_listener = (component_classname) ->
    $(".#{component_classname}-choose-child-button").on 'click', (e) ->
      e.preventDefault()

      el = $(e.currentTarget)
      option_value = el.attr('data-option-value')
      option_data_field = el.attr('data-option-field')
      option_action = el.attr('data-action')
      ms_options_component = el.parents('.ms-options')

      choose_children_action(ms_options_component, component_classname, option_data_field, option_value, option_action)
      change_action_choose_child_button(el, option_action)

  choose_children_action = (ms_options_component, component_classname, data_field, data_value, action) ->
    ms_options = $(ms_options_component)
    ms_options_wrap = ms_options.parents('.ms-options-wrap')
    options_select = $(ms_options_wrap).siblings(".select-multiselectlevels")
    selector_string = "option[#{data_field}='#{data_value}']"
    children_options = $(options_select).find(selector_string)

    children_options.map (index, child_option) ->
      option = $(child_option)
      option_value = option.val()
      ms_options = $(ms_options_component)

      child_input_selector_string = "input[value='#{option_value}']"
      child_input = $(ms_options_component).find(child_input_selector_string)
      child_input_checked = child_input.prop('checked')

      if (action == 'select' && !child_input_checked)
        child_input.click()
      else if (action == 'deselect' && child_input_checked)
        child_input.click()

      child_sibling_choose_button = child_input.siblings(".#{component_classname}-choose-child-button")

      if (child_sibling_choose_button.length > 0)
        change_action_choose_child_button(child_sibling_choose_button, action)

      option_has_child_selector_string = "option[#{data_field}='#{option_value}']"
      option_has_child = $(options_select).find(option_has_child_selector_string)

      if (option_has_child.length > 0)
        choose_children_action(ms_options, component_classname, data_field, option_value, action)
  
  change_action_choose_child_button = (button, action) ->
    el = $(button)
    if action == 'select'
      el.attr('data-action', 'deselect')
      el.text("- #{I18n.t(translations(), 'multi_select.deselect_children')}")
    else 
      el.attr('data-action', 'select')
      el.text("- #{I18n.t(translations(), 'multi_select.select_children')}")

  add_custom_links_and_listeners = (class_name) ->
    $(".#{class_name}").each (index, selector) ->
      add_link_select_all(selector)
      add_listener_click_select_all(selector)
      add_listener_click_selector_options(selector)

      add_initializer_listener(selector)
      initialize_labels(selector)

  add_link_select_all = (selector) ->
    optionsList = $(selector).siblings('.ms-options-wrap').find('.ms-options > ul')
    optionsList.before("<a href='#' class='ms-selectall'>#{get_label_select_all()}</a>")

  add_listener_click_select_all = (selector) ->
    $(selector).siblings('.ms-options-wrap').on 'click', '.ms-selectall', (event) ->
      event.preventDefault()

      optionsList = $(event.target).parents('.ms-options').find('ul > li:visible')

      if optionsList.filter(':not(.selected)').length
        optionsList.filter(':not(.selected)').find('input[type="checkbox"]').trigger('click')
      else
        optionsList.find('input[type="checkbox"]').trigger('click')

  add_listener_click_selector_options = (selector) ->
    optionsWrap = $(selector).siblings('.ms-options-wrap')
    optionsWrap.on 'click', 'input[type="checkbox"]', () ->
      selectedOption = $(this).parent().parent()
      update_label_select_all(optionsWrap)

  update_label_select_all = (optionsWrap) ->
    selectAll = optionsWrap.find('.ms-selectall')
    return unless selectAll.length

    optionsList = optionsWrap.find('.ms-options > ul > li')
    optionsVisible = optionsList.filter((index,element) ->
      $(element).css('display') != 'none'
    )

    if optionsVisible.filter(':not(.selected)').length
      selectAll.text(get_label_select_all())
    else
      selectAll.text(get_label_deselect_all())

  get_label_select_all = () ->
    return I18n.t(translations(), 'multi_select.select_all')

  get_label_deselect_all = () ->
    return I18n.t(translations(), 'multi_select.deselect_all')

  add_initializer_listener = (selector) ->
    $(selector).siblings('.ms-options-wrap').on 'initialize', () ->
      initialize_labels(selector)

  initialize_labels = (selector) ->
    optionsWrap = $(selector).siblings('.ms-options-wrap')
    selectAll = optionsWrap.find('.ms-selectall')
    optionsList = optionsWrap.find('.ms-options > ul > li')
    optionsRemoved = $(selector).find('option[data-removed="true"]')

    if optionsList.filter(':not(.selected)').length
      selectAll.text(get_label_select_all())
    else
      selectAll.text(get_label_deselect_all())

    if optionsList.length == 0 or optionsRemoved.length == optionsList.length
      selectAll.hide()
    else
      selectAll.show()