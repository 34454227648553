import { httpMethodByAction, buildHeaders } from '../utils/http'
import { getAttributeValue } from '../utils/params'

export default class InspectionImageUpdater {
  constructor(token) {
    this.headers = buildHeaders(token)
    this.resourceUrl = '/api/v1/service_inspections_form_filled/inspection_data_images'
  }

  update(form) {
    const { action, resource } = form.dataset
    const params = buildParams(form, resource)

    return new Promise((resolve, reject) => {
      fetch(`${this.resourceUrl}/${params.guid}`, {
        method: httpMethodByAction(action),
        headers: this.headers,
        body: JSON.stringify(params)
      }).then((response) => {
        response.json().then((data) => {
          if(response.ok) resolve(data)
          else reject(data)
        })
      }).catch(error => {
        reject(error)
      })
    })
  }
}

const buildParams = (form, resource) => {
  return {
    guid: getAttributeValue(form, resource, 'guid'),
    order: getAttributeValue(form, resource, 'order'),
    caption: getAttributeValue(form, resource, 'caption')
  }
}
