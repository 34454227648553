import translations from "../../translations"
import * as I18n from "../../services/i18n"
import OccurrenceChart from "./charts/occurrence_chart.js"
import FinancesHelpers from "../../utils/finances_helpers.coffee"
import StepProgressChart from "./charts/step_progress_chart.js"
import ServiceInspectionFormFilledChart from "./charts/service_inspection_form_filled_chart.coffee"

init_occurrence_chart = ->
  data = $("#occurrences-chart-container").data("occurrences")
  OccurrenceChart.handle_show_and_hide_tab_component("all")
  return if data.length == 0

  ctx3 = document.getElementById("occurrence-chart-canvas").getContext("2d")
  OccurrenceChart.render(ctx3, data, "all", { with_text_legend: true })

render_financial_graph = (container, data_type, callback) ->
  pure_selector_container = container
  container = $(container)
  labels = JSON.parse container.attr("data-#{data_type}")
  series = []
  executeds = JSON.parse container.attr('data-executeds-amount')
  expecteds = JSON.parse container.attr('data-expecteds-amount')
  series.push executeds
  series.push expecteds
  callback(pure_selector_container, labels, series, true)

init_build_progress_chart = ->
  callback = FinancesHelpers.draw_physical_graph
  render_financial_graph('#physic-chart-weekly', 'weekly', callback)

$(document).on 'turbolinks:load', ->
  if $('.dashboard-pdf')[0]
    init_occurrence_chart()
    init_build_progress_chart()
    StepProgressChart.render()
    # ServiceInspectionFormFilledChart.render()
    
