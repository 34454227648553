import "javascripts/chartist-plugin-tooltip/chartist-plugin-tooltip.min"

export default class FinancesHelpers
  @draw_financial_graph: (container, labels, series) ->
    new (Chartist.Bar)(container, {
      labels: labels,
      series: series
    },
      showLabel: false
      axisX:
        offset: 22
        position: 'end'
        showLabel: true
        showGrid: false
        scaleMinSpace: 30
        onlyInteger: false
      axisY:
        offset: 0
        position: 'start'
        showLabel: false
        showGrid: true
        scaleMinSpace: 30
        onlyInteger: false
      width: calc_width_graph(90, series)
      height: 340
      seriesBarDistance: 35
      plugins: [ Chartist.plugins.tooltip(currency: 'R$', class: 'class1 class2', appendToBody: true) ])

  @draw_physical_graph: (container, labels, series, fixed_width = false) ->
    chart_width = if fixed_width then '100%' else calc_width_graph(90, series)

    new (Chartist.Line)(container, {
      labels: labels,
      series: series
    },
    {
      showLabel: false
      axisX: {
        showLabel: true
        showGrid: false
      }
      axisY: {
        low: 1,
        high: 100
        showGrid: true,
        showLabel: false
      }
      chartPadding: {
        left: -30
      }
      width: chart_width
      height: 340
      plugins: [ Chartist.plugins.tooltip(class: '', appendToBody: false, textAnchor: 'middle') ]
    },
    [
      [
        'print',
        {
          height: 325
          axisX: {
            labelInterpolationFnc: (value) ->
              return value.replace('Semana', 'Sem.')
          }
        }
      ]
    ])

  calc_max_size_graph = (series) ->
    margin_discount = 10
    max_array_element = Math.max.apply(null, series[1])
    max_array_element + margin_discount

  calc_width_graph = (size_element, series) ->
    elements_length = series[1].length
    size_graph = size_element * elements_length
    Math.max(1000, size_graph)
