import StantMultiselect from "../../../utils/stant_multiselect.coffee"

$(document).on 'turbolinks:load', ->

  new StantMultiselect('empresas-multiselect').add_listener()

  $('#js-company-reports-report-type-select').on 'change', ->
    option = $(@).find('option:selected')

    formElement = $('#admin_reports_form');

    formElement.attr('action', option.val());

    if option.val() == '/admin_reports/users_report'
      $('#data').hide()
    else
      $('#data').show()

      # prevent event
  $('#admin_reports_form .btn-search').on 'click' , (e) ->
    unless $('#js-company-reports-report-type-select').val()
      e.preventDefault()
      alert('Selecione um tipo de relatório')
      return false

