import HistoryBuilder from "../../utils/history_builder.coffee"
import translations from "../../translations"
import * as I18n from "../../services/i18n"
import {v4 as uuidv4} from 'uuid'

export default class PlanningFormBuilder {
  build_form = function(planning, is_new) {
    is_new = is_new || false

    this.set_is_multisite()
    if(is_new) { this.set_attrs_for_new_planning() }
    if(!is_new) { this.set_attrs_for_existent_planning(planning.id) }

    return this.build_form_html(planning)
  }

  set_is_multisite = function() {
    this.is_multisite = $('#planning-general-form').attr("is_multisite") == 'true'
  }

  set_attrs_for_existent_planning = function(id) {
    let destroy_url = `/atividades/${id}`
    this.destroy_attrs = ` href='${destroy_url}' data-confirm='${I18n.t(translations(), 'atividades.form.confirm')}' data-remote='true' data-method='delete' `
    this.new_place_class = ''
    this.id_input = ` <input class='planning-id' type='hidden' name='plannings[places][][id]' value='${id}'> `
    this.history_button = `
      <a href="javascript:void(0)" class="planning-history-button btn btn-secondary btn-small">
        <i class="icon new icon-back-in-time"></i>
      </a>`
  }

  set_attrs_for_new_planning = function() {
    this.destroy_attrs = ' href="javascript:void(0)" '
    this.new_place_class = ' remove-new-place '
    this.id_input = ''
    this.history_button = ''
  }

  set_is_disabled_input_class_if_is_multisite = function(is_manageable) {
    return (this.is_multisite || is_manageable) ? 'is_disabled' : ''
  }

  set_readonly_if_is_multisite = function(is_manageable) {
    return (this.is_multisite || is_manageable) ? 'readonly onfocus="this.blur()"' : ''
  }

  build_form_html = function(planning) {
    let disabled_if_have_contracts = planning.has_contract ? 'disabled' : ''
    let raw_manageable_services = $('#js-manageable-services-ids').val() || "[]"

    let manageable_services = JSON.parse(raw_manageable_services)
    let current_service = $('#service-select').val() || -1
    let is_manageable = manageable_services.indexOf(parseInt(current_service)) != -1
    let is_quantity_required = ''

    if(this.is_multisite) {
      let is_manageable_multisite = $('#planning-service-type').attr('is_service_manageable') == 'true'
      is_quantity_required = is_manageable_multisite ? '' : '<abbr title="required">*</abbr>'
    } else {
      is_quantity_required = is_manageable ? '' : '<abbr title="required">*</abbr>'
    }

    let uuid_identifier = uuidv4();

    let html =  `
              <div data-histories='${JSON.stringify(planning.histories)}' class="place-row place-${planning.place_id} planning-${planning.id} p-t-20 p-r-15 p-b-20">
                ${this.id_input}
                <input type="hidden" name="plannings[places][][local_id]" value="${planning.place_id}">
                <input type="hidden" class="places_local_line_guid" name="plannings[places][][local_line_guid]" value="${uuid_identifier}">

                <div class="row planning-place-card" data-planning-place-id="${planning.id}">

                  <div class="col-md-9 planning-place-name">
                    ${planning.place_description}
                  </div>
                  <div class="col-md-2 planning-place-actions -buttons">
                    ${this.history_button}
                  </div>
                  <div class="col-md-1 planning-place-actions -close">
                    <a ${this.destroy_attrs} title="${I18n.t(translations(), 'atividades.form.remove')}" class="close remove-place ${this.new_place_class} m-r-5">
                      <i class="icon icon-close"></i>
                    </a>
                  </div>

                  <div class="col-md-2 input-cell">
                    <label class="label" for="planning_dt_start_${planning.id}">${I18n.t(translations(), 'atividades.form.dth_start')}</label>
                    <div class="input-group date" id="datetimepicker1">
                      <div class="input string required">
                        <input id="planning_dth_inicio_${planning.id}" tabindex=1 class="string dth_inicio required form-control mask-date-field" type='text' name='plannings[places][][dth_inicio]' value="${planning.dth_inicio}" data-value="${planning.dth_inicio}">
                      </div>

                      <a class="input-group-addon pickadate" href="javascript:void(0);">
                        <i class="icon icon-calendar"></i>
                      </a>
                    </div>
                  </div>

                  <div class="col-md-2 input-cell">
                    <label class="label" for="planning_dt_end_${planning.id}">${I18n.t(translations(), 'atividades.form.dth_end')}</label>
                    <div class="input-group date" id="datetimepicker2">
                      <div class="input string required">
                        <input id="planning_dth_fim_${planning.id}" tabindex=1 class="string dth_fim required form-control mask-date-field" type='text' name='plannings[places][][dth_fim]' value="${planning.dth_fim}" data-value="${planning.dth_fim}">
                      </div>

                      <a class="input-group-addon pickadate" href="javascript:void(0);">
                        <i class="icon icon-calendar"></i>
                      </a>
                    </div>
                  </div>

                  <div class="col-md-2 col-quantity input-cell">
                    <label class="label" for="planning_quantidade_${planning.id}">${I18n.t(translations(), 'atividades.form.quantity')} ${is_quantity_required}</label>
                    <div class="input-group">
                      <input id="planning_quantidade_${planning.id}" ${disabled_if_have_contracts} tabindex=1 type='text' name='plannings[places][][quantidade]' value="${planning.quantidade}" data-allow-zero="true" class="quantidade form-control mask-decimal">
                      <span class="input-group-addon measuring-unit"> ${planning.measuring_unit} </span>
                    </div>
                  </div>

                  <div class="col-md-3 input-cell">
                    <label class="label" for="planning_valor_${planning.id}">${I18n.t(translations(), 'atividades.form.unitary_value')} <abbr title="required">*</abbr></label>
                    <input id="planning_valor_${planning.id}" ${disabled_if_have_contracts} type='text' tabindex=1 name='plannings[places][][valor]' value="${planning.valor}" data-allow-zero="true" class="valor form-control mask-money ${this.set_is_disabled_input_class_if_is_multisite()} " ${this.set_readonly_if_is_multisite()}>
                  </div>

                  <div class="col-md-3 input-cell">
                    <label class="label" for="planning_total_value_${planning.id}">${I18n.t(translations(), 'atividades.form.total_value')}</label>
                    <input id="planning_total_value_${planning.id}" type='text' tabindex="-1" disabled class="total_value form-control mask-money">
                  </div>
                  <div class="place-card-errors">
                  </div>
                </div>
              </div>
            `
    return html
  }
}
