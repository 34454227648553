import { httpMethodByAction, buildHeaders } from '../utils/http'

export default class InspectionImageDestroyer {
  constructor(token) {
    this.headers = buildHeaders(token)
    this.resourceUrl = '/api/v1/service_inspections_form_filled/inspection_data_images'
  }

  destroy(guid) {
    return new Promise((resolve, reject) => {
      fetch(`${this.resourceUrl}/${guid}`, {
        method: httpMethodByAction('destroy'),
        headers: this.headers
      }).then(() => {
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}
