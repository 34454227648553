import Chart from "chart.js"
import { set_tooltip_event } from "./shared/chartjs_custom_tooltip"
import { resizeText } from './helpers/textHelper'
import { options } from "dropzone";

export default class OccurrenceChart {
  static occurrence_total = 0

  static render(canvas_element, data, type, options = { with_text_legend: false }) {
    let that = this;
    let  with_text_legend = $("#tab-1[data-ocorrencias]").data("with-text-legend")
    this.occurrence_total = this.calc_occurrence_total(data)
    $(`#js-occurrence-count-number-${type}`).html(this.occurrence_total)
    const tooltip_class = {
      seven: ".js-occurrence-tooltip-seven",
      thirty: ".js-occurrence-tooltip-thirty",
      all: ".js-occurrence-tooltip-all",
      expired: ".js-occurrence-tooltip-expired"
    }

    const chart = new Chart(canvas_element, {
      type: 'pie',
      data: this.pieData(data),
      options: {
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        legendCallback: function (chart) {
          return that.legend_html_template(chart.config.data, with_text_legend)
        },
        tooltips: {
          enabled: false,
          custom: function (tooltip) {
            set_tooltip_event(tooltip, tooltip_class[type]);
          }
        }
      }
    });
    $(`#js-occurrence-chart-legend-${type}`).prepend(chart.generateLegend())
  }

  static pieData(occurrences) {
    let that = this
    let pieDataOccurrence = {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: []
      }]
    }
    console.log(occurrences)
    occurrences = that.order_occurrences(occurrences)
    $.each(occurrences, function(index, occurrence) {
      let label = occurrence.dsc_tipo_opnc ? occurrence.dsc_tipo_opnc : occurrence.occurrence_type
      pieDataOccurrence['labels'].push(`${label}:${occurrence.percentage}%`)
      pieDataOccurrence['datasets'][0]['data'].push(occurrence.total)
      pieDataOccurrence['datasets'][0]['backgroundColor'].push(that.get_color(index))
    })
    return pieDataOccurrence
  }

  static order_occurrences(occurrences){
    return occurrences.sort((a, b) => b.total - a.total)
  }

  static get_random_color() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  static get_color(index) {
    if (index === 0) return "#800000"
    if (index === 1) return "#800080"
    if (index === 2) return "#808000"
    if (index === 3) return "#C0C0C0"
    if (index === 4) return "#4b2ecc"
    if (index === 5) return "#00FFFF"
    return this.get_random_color()
  }

  static legend_html_template(data, show_subtitle) {
    let that = this
    const countOcurrences = data['labels'].length
    const limit_labels = 6
    let class_type = show_subtitle === 1 ? 'style="display: flex;"' : ''

    let htmlArray = []
    htmlArray.push(`<ul class="pie-legend occurrencechart-legend" ${class_type}>`)
    for (let i = 0; i < Math.min(countOcurrences, limit_labels); i++) {
      let labelArray = data['labels'][i].split(':')
      let occurrence_total = data['datasets'][0]['data'][i]
      let backgroundColor = `background-color: ${data['datasets'][0]['backgroundColor'][i]}`

      htmlArray.push(`<li class="legenditem">`)
      htmlArray.push(`${show_subtitle === 1 ?
        `<span class="radius-element" style="${backgroundColor}"></span>
          ${occurrence_total} / ${labelArray[1]} ${resizeText(labelArray[0])}`
        :
        `<span class="radius-element" style="${backgroundColor}"></span>
          (${labelArray[1]})`}`
      )
      htmlArray.push("</li>")
    }
    htmlArray.push("</ul>")
    return htmlArray.join(' ')
  }

  static handle_show_and_hide_tab_component(type) {
    $(`#js-occurrent-tab-item-${type} a`).off('.click-occurrent-tab-item').on("click.click-occurrent-tab-item", () => {
      const chart_legend_container = $(`#js-occurrence-chart-legend-${type}`)

      $(".js-occurrence-chart-legend").addClass('hide')

      if (chart_legend_container.children(".occurrencechart-legend").length) {
        chart_legend_container.removeClass('hide')
      }
    })
  }

  static calc_occurrence_total(data) {
    let total = 0
    for (let occurrence_data of data) {
      total += occurrence_data.total
    }
    return total
  }
}
