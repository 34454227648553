import translations from "../../../translations"
import * as I18n from "../../../services/i18n"
import moment from "moment"

export default class GanttChart {
  constructor(data_table, default_values, realized_values) {
    this.data_table       = data_table
    this.default_values   = default_values
    this.realized_values  = realized_values
  }

  add_rows = function() {
    this.add_row_by(this.default_values)
    this.add_row_by(this.realized_values)
  }

  add_row_by = function(schedule_responses) {
    schedule_responses.forEach(schedule_response => {
      if(schedule_response.type != '' && schedule_response.begin_at < schedule_response.end_at) {
        this.data_table.addRows([[
          schedule_response.type,
          schedule_response.description,
          this.create_html_tooltip(schedule_response),
          new Date(schedule_response.begin_at),
          new Date(schedule_response.end_at)
        ]]);
      }
    });      
  }
  
  create_html_tooltip = function(schedule_response) {
    let begin_at        = moment(schedule_response.begin_at)
    let end_at          = moment(schedule_response.end_at)
    let duration        = moment.duration(end_at.diff(begin_at))._data

    let begin_at_month  = moment.monthsShort(begin_at.month())
    let end_at_month    = moment.monthsShort(end_at.month())
    let begin_at_year   = begin_at.year() < end_at.year() ? ` ${begin_at.year()}` : ''

    let day_s           = I18n.t(translations(), "datetime.prompts.day")
    let month_s         = I18n.t(translations(), "datetime.prompts.month")
    let month_plural_s  = I18n.t(translations(), "datetime.prompts.months")
    let year_s          = I18n.t(translations(), "datetime.prompts.year")

    let days = ''
    let months = ''
    let years = ''

    if(duration.days > 0) {
      let prefix = duration.months > 0 || duration.years > 0 ? ', ' : ''
      days = `${prefix}${duration.days} ${day_s}${duration.days > 1 ? 's' : ''}`
    }

    if(duration.months > 0) {
      let prefix = duration.years > 0 ? ', ' : ''
      months = `${prefix}${duration.months} ${duration.months > 1 ? month_plural_s : month_s}` 
    }

    if(duration.years > 0) {
      years = `${duration.years} ${year_s}${duration.years > 1 ? 's' : ''}`
    }

    return  `<ul class="google-visualization-tooltip-item-list">
              <li class="google-visualization-tooltip-item">
                <span style="font-family: Arial; font-size: 12px;
                color: rgb(0,0,0); margin: 0px; text-decoration: none;
                font-weight: bold;">
                  ${schedule_response.description}
                </span>
              </li>
            </ul>
            <div class="google-visualization-tooltip-separator"></div>
            <ul class="google-visualization-tooltip-item-list">
              <li class="google-visualization-tooltip-action">
                <span style="font-family: Arial; font-size: 12px;
                color: rgb(0,0,0); margin: 0px; text-decoration: none;
                font-weight: bold;">
                  ${schedule_response.type}: 
                </span>
                <span style="font-family: Arial; font-size: 12px;
                color: rgb(0,0,0); margin: 0px; text-decoration: none;"> 
                  ${begin_at_month}${begin_at_year} - ${end_at_month} ${end_at.year()}
                </span>
              </li>
              <li class="google-visualization-tooltip-action">
                <span style="font-family: Arial; font-size: 12px;
                color: rgb(0,0,0); margin: 0px; text-decoration: none;
                font-weight: bold;">
                  ${I18n.t(translations(), "schedules.index.gantt.duration")}: </span>
                <span style="font-family: Arial; font-size: 12px;
                color: rgb(0,0,0); margin: 0px; text-decoration: none; text-transform: lowercase;"> 
                  ${years}${months}${days}
                </span>
              </li>
            </ul>`
  }
}