window.NewGroup = class NewGroup
  build_user_group_list: (group_id, users_names) ->
    html = 
    """
      <ul class="list-users" id="list-users-#{group_id}">
        #{build_item(users_names)}
      </ul>
    """

    $(".list-users").css('display','none')
    $('.list_userscontainer').append(html)

  build_item = (data) ->
    html = ""
    for i in [0...data.length]
      html += """<li class='item'>#{data[i]}</li>"""

    return html
