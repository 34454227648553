import StantMultiselectBox from "../../utils/stant_multiselect_box.coffee"
import translations from "../../translations"
import * as I18n from "../../services/i18n"

$(document).on "turbolinks:load", ->
  $('#perfil_perfil_permissao_ids').multiSelect()
  selectable_header   = I18n.t(translations(), 'users.form.profiles')
  selection_header    = I18n.t(translations(), 'multi_select.selected_items', { item: I18n.t(translations(), 'users.form.profiles') })
  custom_multi_select = new StantMultiselectBox('profile_ids', 'user-profiles-multi-select', selectable_header, selection_header)
  custom_multi_select.add_listener()
