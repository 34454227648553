import StantMultiselect from "../../utils/stant_multiselect.coffee"
import StantMasks from "../../utils/stant_masks.coffee"
import ServiceFormBuilder from "./service_form_builder"
import AjaxLoader from "../../utils/ajax_loader.coffee"
import translations from "../../translations"
import * as I18n from "../../services/i18n"

export default class PrePlanning
  @handle_on_update_total_calculation: ->
    $('.js-services-form').on 'update_total_calculation', change_total_general = ->
      total = 0
      $('.js-preplanning-total').each ->
        total += StantFormat.convertCurrencyToFloat($(this).val())
      total = total.toFixed(2)

      $('#js_preplannings_total').text(StantFormat.converteFloatMoeda(total))

  @add_listeners: ->
    new StantMultiselect('preplanning-select-multiselect').add_listener()

    services = $('.services-form').data('services')

    build_new_form(services)

    handle_onclick_remove_preplanning()

    handle_on_change_preplanning_value_item()
    handle_on_change_preplanning_quantity_item()

  build_new_form = (services) ->
    handler_onclick_add_services_button(services)

  handler_onclick_add_services_button = (services) ->
    $("#add-services").off().on 'click', ->
      Error.clear_global_errors();

      selected_services = $('.preplanning-select-multiselect option:selected').map(->
        {id: @value, name: @text}
      ).get()

      services_to_generate = []
      ids = 0
      count = selected_services.length
      if count == 0
        new Error('services-select-errors', I18n.t(translations(), 'pre_plannings.form.errors.empty_services')).show();

      while ids < count

        for service in services
          if service.id == parseInt(selected_services[ids].id)
            service =
              value:                   service.value
              quantity:                ''
              service_description:     service.service_description
              symbol_unit_measurement: service.symbol_unit_measurement
              id:                      service.id

            services_to_generate.push service

        ids++

      generate_planning_rows(services_to_generate)
      handler_onclick_remove_service_button()
      reload_services_select()

  generate_planning_rows = (services) ->
    element = $('.services-form')
    for item in services
      service_form_builder = new ServiceFormBuilder(item)
      service_form_builder.append_on(element)

    new StantMasks().init()

  handler_onclick_remove_service_button = ->
    $('.remove-new-service').off().on 'click', ->
      service_preplanning_row = $(this).parents('.js-services-preplanning-row')

      Error.clear_global_errors();

      service_preplanning_row.fadeOut ->
        $(this).remove()
        $('.js-services-form').trigger('update_total_calculation')

  reload_services_select = ->
    $('.preplanning-select-multiselect option:selected').each ->
      $(this).prop('selected', false)

    $('.preplanning-select-multiselect').multiselect('reload')
    new StantMultiselect('preplanning-select-multiselect').add_listener()

  handle_onclick_remove_preplanning = ->
    pre_planning_link = $('.js-destroy-preplanning-link')

    pre_planning_link.on "ajax:beforeSend", () ->
      this.parentElement.classList.add('link-disabled')

    pre_planning_link.on 'ajax:success', (event) ->
      [data, status, xhr] = event.detail
      id = data.id
      msg = data.message

      Error.show_success_flash_msg(msg)
      $("#js_pre_planning_item_#{id}").fadeOut ->
        $(this).remove()

    pre_planning_link.on 'ajax:error', (event) ->
      this['_ujsData']['ujs:enable-with'] = '<i class="icon icon-remove"></i>'
      [data, status, xhr] = event.detail
      msg = data.error_message
      Error.show_alert_flash_msg(msg)

    pre_planning_link.on 'ajax:complete', () ->
      $('.link-disabled').removeClass('link-disabled')

  handle_on_change_preplanning_quantity_item = ->
    $('.js-services-form').on 'change', '.js-preplanning-quantity', change_input_handle

  handle_on_change_preplanning_value_item = ->
    $('.js-services-form').on 'change', '.js-preplanning-value', change_input_handle

  change_input_handle = ->
    preplanning_row_element = $(this).parents('.js-services-preplanning-row')

    value = preplanning_row_element.find('.js-preplanning-value').val()
    quantity = preplanning_row_element.find('.js-preplanning-quantity').val()
    total_element = preplanning_row_element.find('.js-preplanning-total')

    total = calculate_total(quantity, value)

    total_element.val(total)

    $('.js-services-form').trigger('update_total_calculation')

  calculate_total = (quantity_str, value_str) ->
    quantity = StantFormat.convertCurrencyToFloat(quantity_str)
    value = StantFormat.convertCurrencyToFloat(value_str.replace('R$', ''))
    total = (quantity * value).toFixed(2)
    StantFormat.converteFloatMoeda(total)

  @handler_events: ->
    handler_on_click()

  handler_on_click = ->
    $('form.simple_form.plannings').on 'submit', ->
      configure_ajax_loader_and_disable_input()

  configure_ajax_loader_and_disable_input = ->
    element = $('input[type=submit]:enabled')
    element.attr('disabled','disabled')
    new AjaxLoader().show()
