import moment from "moment"
import translations from "translations"
import * as I18n from "services/i18n"

export function handleFormValidations() {
  $(document).on('click', '#company_reports_form .btn-search', function(event) {
    const reportType = $('#js-company-reports-report-type-select');

    if (reportType.val() == '') {
      preventDefaultAndScrollTop(event)

      $('.report-type-error').text(I18n.t(translations(), 'reports.excel_analytics.form.errors.invalid_empty_field')).show()
    } else {
      $('.report-type-error').hide();
    }

    const obras = $('.construction-multiselect');

    if (!obras.val() || obras.val().length == 0) {
        preventDefaultAndScrollTop(event)

        $('.obras-error').text(I18n.t(translations(), 'reports.excel_analytics.form.errors.invalid_empty_field')).show()
    } else {
        $('.obras-error').hide();
    }

  })
}

function preventDefaultAndScrollTop(event) {
  $("html, body").animate({ scrollTop: 0 });
  event.preventDefault()
}
