import InspectionImageUpdater from './services/inspection_image_updater'

export default class InspectionImageFormHandler {
  constructor(form) {
    this.formElements = $(form).find('.inspection-image-form')
    const token = document.querySelector('input[name="request[user_token]"]').value
    this.inspectionImageUpdater = new InspectionImageUpdater(token)
  }

  init() {
    Array.from(this.formElements).forEach(form => {
      this.inspectionImageUpdater.update(form)
        .then((response) => {

        }).catch(error => {
          
        })
    })
  }
}
