import "datatables.net-responsive-dt"

export default class DataTable
  add: ->
    table = $('.js-occurrence-action-datatable').DataTable {
      responsive: true,
      columnDefs: [
        { "orderable": false, "targets": [3,4] },
      ],
      destroy: true,
      paging: false,
      searching: false,
      info: false,
      "language": {
        'sEmptyTable': 'Nenhum registro!',
      },
    }
