export default class FixedCostContract
  constructor: ->
    @start_handlers()

  values: () ->
    fixed_costs = []
    $('.fixed-cost-selected').each ->
      id = $(this).find('.idd')[0].value
      value = $(this).find('.contract_value')[0].value
      fixed_costs.push {
        name: "construction_site_progress_evaluation_contract_progress_evaluation_contract[fixed_costs][][id]",
        value: id }
      fixed_costs.push {
        name: "construction_site_progress_evaluation_contract_progress_evaluation_contract[fixed_costs][][contract_value]",
        value: value }
    fixed_costs

  start_handlers: () ->
    @fixed_cost_click()

  fixed_cost_click: () ->
    that = @
    $('.fixed-cost-father').off().click ->
      selected = $(this)
      selected.toggleClass('fixed-cost-selected')
