import translations from "../../translations"
import * as I18n from "../../services/i18n"

export default class ManageMethodImagesModalBuilder {

  static build(method_id) {
    var csrf_token =  $('meta[name=csrf-token]').attr('content');
    
    const method_image_modal = `
      <div class="modal fade" id="js-manage-method-images-${method_id}" data-width="600px" data-height="400px" aria-hidden="true" tabindex="-1">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">${ I18n.t(translations(), 'servicos.show.method_images.manage_files_modal.title') }</h5>
            <button type="button" class="close" data-dismiss="modal"><span>×</span></button>
          </div>

          <div class="modal-body">
            <div class="row">
              <div id="preview-container-${method_id}"></div>

              <form id="js-method-image-form-${method_id}" class="simple_form method_image" action="/method_images/${method_id}" accept-charset="UTF-8" data-remote="true" method="put">
                <input type="hidden" name="authenticity_token" value="${csrf_token}">
                <input type="hidden" name="_method" value="put">

                <div class="hide">
                  <div id="preview-template-${method_id}" class="col-md-12">
                    <div class="row">
                      <div class="col-md-2 centered-image-preview -small">
                        <div class="overlay"></div>
                        <img data-dz-thumbnail="">
                      </div>

                      <div class="col-md-8">
                        <strong class="file-name" data-dz-name=""></strong>
                        <span class="txt-grey file-size" data-dz-size=""></span>
                      </div>

                      <div class="col-md-2" data-dz-remove>
                        <a class="btn link-remove form-action-icon pull-right"
                           title="${ I18n.t(translations(), 'form.remove') }"
                           data-id="${method_id}"
                           href="javascript:void(0)">
                          <i class="icon icon-remove"></i>
                        </a>                  
                      </div>
                    </div>
                    <hr>
                  </div>
                </div>
              </form>        
            </div>

            <div class="txt-center empty-message">
              <p>${ I18n.t(translations(), 'servicos.show.method_images.empty') }</p>
            </div>
          </div>

          <div class="modal-footer center-align">
            <div class="col-md-8">
              <div class="pull-right">
                <a id="js-method-upload-button-${method_id}" class="btn btn-primary dz-clickable" href="javascript:void(0)">
                  <i class="icon icon-upload"></i>   
                  <span class="text upload-service-button">${ I18n.t(translations(), 'servicos.show.method_images.manage_files_modal.add_images') }</span>
                </a>

                <small class="block m-t-5">${ I18n.t(translations(), 'servicos.show.method_images.manage_files_modal.supported_files') }</small>  
              </div>
            </div>

            <div class="col-md-4">
              <button name="button" class="btn btn-success" data-dismiss="modal">
                <i class="icon new icon-check"></i>
                <span class="text">${ I18n.t(translations(), 'form.conclude') }</span>
              </button>  
            </div>
          </div>
        </div>
      </div>
    `

    $('body').append(method_image_modal);
  }
}
