import StantFormat from "../../../utils/stant_formt.coffee"

export default class NewEntry
  add_listener: ->
    self = @
    $("#js_entry_value, #js_entry_quantity").on 'change', ->
      quantity_element = $("#js_entry_quantity").val()
      value_element    = $("#js_entry_value").val()
      total =  self.calculate_total(quantity_element, value_element)
      $("#js_total_element").val(total)

  calculate_total: (quantity_element, value_element) ->
    quantity = StantFormat.converteMoedaFloat(quantity_element)
    value    = StantFormat.converteMoedaFloat(value_element.replace('R$', ''))
    total = (quantity * value).toFixed(2)
    StantFormat.converteFloatMoeda(total)