import * as Dropzone from "javascripts/dropzone/dropzone"

export default class DropzoneAdapter
  constructor: (@form_element, @dropzone_modal, @preview_template) ->

  add_listeners: ->
    self = @

    self.form_element.addClass('dropzone')

    return unless self.preview_template.get(0)

    preview_node          = self.preview_template.removeAttr('id')
    preview_template_html = preview_node.get(0).outerHTML

    self.form_element.dropzone
      createImageThumbnails: false,
      clickable: '#dropzone-upload-link',
      saveUploads: '#dropzone-save-uploads',
      previewsContainer: "#dropzone-preview-container",
      previewTemplate: preview_template_html

      addedfile: (file) ->
        self.form_element.trigger('addedFile', file)

        self.dropzone_modal.modal('show')
        
        if @previewsContainer
          file.previewElement = Dropzone.createElement @options.previewTemplate.trim()

          $(@previewsContainer).prepend($(file.previewElement))
          node.textContent = file.name for node in file.previewElement.querySelectorAll("[data-dz-name]")
          node.innerHTML = @filesize file.size for node in file.previewElement.querySelectorAll("[data-dz-size]")
          $(node).parent().find('.file-percent').text("0%")

      sending: (file) ->
        self.form_element.trigger('startSending', file)

      uploadprogress: (file, progress, bytesSent) ->
        self.form_element.trigger('uploadProgress', [file, progress, bytesSent])

        if file.previewElement
          for node in file.previewElement.querySelectorAll("[data-dz-uploadprogress]")
            $(node).parent().find('.file-percent').text("#{progress.toFixed(0)}%")

            if node.nodeName is 'PROGRESS'
              node.value = progress
            else
              node.style.width = "#{progress}%"

      complete: (file) ->
        self.form_element.trigger('uploadComplete', file)

      success: (file, response) ->
        self.form_element.trigger('uploadSuccess', [file, response])

        $(file.previewElement).find('.progress').removeClass('progress-bar-primary')
        $(file.previewElement).find('.progress').addClass('progress-bar-success')

      error: (file, response) ->
        self.form_element.trigger('uploadFailed', [file, response])

        $(file.previewElement).find('.progress').removeClass('progress-bar-primary')
        $(file.previewElement).find('.progress').addClass('progress-bar-danger')
        $(file.previewElement).find('.file-percent').text('0%')