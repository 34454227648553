$(document).on('turbolinks:load', () => {
  $('a[data-disable-with], a[data-disable]').on('click', function(e) {
    Rails.setData(e.target, 'ujs:enable-with-backup', e.target.innerHTML)
  })

  $('a[data-disable-with], a[data-disable]').on('ajax:complete', function() {
    Rails.setData(this, 'ujs:enable-with', Rails.getData(this, 'ujs:enable-with-backup'))
  })

  $('a[data-disable-with], a[data-disable]').on('ajax:stopped', function() {
    Rails.setData(this, 'ujs:enable-with', Rails.getData(this, 'ujs:enable-with-backup'))
  })
})