import Error from "../../utils/error.coffee"
import translations from "../../translations"
import * as I18n from "../../services/i18n"

selectCreatedCargo = (cargo) ->
  queryForCargosByEmpresa(cargo)

queryForCargosByEmpresa = (saved_object) ->
  empresas_id = $("#cargo_empresa_id").val()
  $.getJSON('/cargos.json', {empresas_id: empresas_id, limit: 10000}, (data) ->
    $("#cargo_select").html('')
    for key of data
      cargo = data[key].cargo
      option = '<option value="' + cargo.id + '">' +
        cargo.titulo + '</option>'
      $('#cargo_select').append(option)
      $('#cargo_select').select2('val', cargo.id)
  )
  .done ->
    $("#cargo_select").select2('val', saved_object.id)

$(document).on 'turbolinks:load', ->
  $('#cargo_lightbox').on 'hidden', ->
    Error.clear_global_errors()

  $("#new_pessoa_cargo").on 'ajax:success', (e) ->
    [data, status, xhr] = event.detail
    $(".cargo").modal('hide')
    selectCreatedCargo(data)

  $("#new_pessoa_cargo").on 'ajax:error', (e, data) ->
    for key of data.responseJSON
      idClass = "#pessoa_cargo_#{key}"
      msg  = data.responseJSON[key][0]
      $('.pessoa_cargo_'+key).append '<span class="error form-error">' + msg + '<span>'


  $("#cargo_lightbox").on 'ajax:error', (e) ->
    msg = I18n.t(translations(), 'errors.messages.blank')
    $('.pessoa_cargo_titulo_error').empty()
    $('.pessoa_cargo_titulo_error').append '<span class="error form-error">' + msg + '<span>'
    new Error.show_alert_flash_msg(I18n.t(translations(), 'flash.actions.create.alert', 
      resource_name: I18n.t(translations(), 'activerecord.models.cargo.one')))
  