export default class DateInputError
  add_listener: ->
    add_error('begin_date_datepicker')
    add_error('end_date_datepicker')

  add_error = (element) ->
    date_div = $(".#{element}")
    date_input = date_div.find('.input')

    if date_input.hasClass('field_with_errors')
      error = date_div.find('span')
      error_text = error.text()
      error.remove()
      new Error(element, error_text).show_using_class(element)
