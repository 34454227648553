//habilita input data fim fvs
function enable_input_dt_fim_fvs() {
  if(document.getElementById("q_dth_inicio_gteq").value != "")
  {
    $("#q_dth_fim_lteq").prop('disabled', false);
    $("#pickadate_dt_fim_fvs").prop('disabled', false); 
  }
}
//habilita pickadate data fim fvs
function enable_pickadate_dt_fim_fvs() {
  if(document.getElementById("pickadate_dt_fim_fvs").value != "")
  {
    $("#q_dth_fim_lteq").prop('disabled', false);
    $("#pickadate_dt_fim_fvs").prop('disabled', false);   
  }
}

//habilita input data fim opnc
function enable_input_dt_fim_opnc() {
  if(document.getElementById("q_dth_opnc_gteq").value != "")
  {
    $("#q_dth_prazo_lteq").prop('disabled', false);
    $("#pickadate_dt_fim_opnc").prop('disabled', false); 
  }
}
//habilita pickadate data fim opnc
function enable_pickadate_dt_fim_opnc() {
  if(document.getElementById("pickadate_dt_fim_opnc").value != "")
  {
    $("#q_dth_prazo_lteq").prop('disabled', false);
    $("#pickadate_dt_fim_opnc").prop('disabled', false);   
  }
}

//habilita input data fim servicos
function enable_input_dt_fim_serv() {
  if(document.getElementById("q_atividades_dth_inicio_gteq").value != "")
  {
    $("#q_atividades_dth_fim_lteq").prop('disabled', false);
    $("#pickadate_dt_fim_serv").prop('disabled', false); 
  }
}
//habilita pickadate data fim servicos
window.enable_pickadate_dt_fim_serv = function enable_pickadate_dt_fim_serv() {
  if(document.getElementById("pickadate_dt_fim_serv").value != "")
  {
    $("#q_atividades_dth_fim_lteq").prop('disabled', false);
    $("#pickadate_dt_fim_serv").prop('disabled', false);   
  }
}