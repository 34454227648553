import AjaxLoader from "../../../utils/ajax_loader.coffee"

autosearch = ->
  search = ""
  location.search.substr(1).split('&').forEach (item) ->
    tmp = item.split('=')
    if tmp[0] == 'autosearch'
      search = decodeURIComponent(tmp[1])
    return
  if search == 'true'
    $('.btn-submit').trigger 'click'
  return

$(document).on 'turbolinks:load', ->

  $('#request_form').on 'submit', ->
    new AjaxLoader().show()
    $('.btn-submit').addClass 'is-disabled'

  autosearch()
