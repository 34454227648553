import * as I18n from '../../services/i18n'
import translations from '../../translations'
import CountFieldCharacters from './count_field_characters'

export default class Comment
  constructor: ->
    @occurrence_component = $('.js-occurrence-component')
    update_scroll.call(@)
  handler_events: ->
    handle_submit_form_success.call(@)
    handle_onclick_answer_button.call(@)
    handle_beforesend_form_submit.call(@)
    handle_textarea_new_comment_enter_key_press.call(@)

  handle_submit_form_success = ->
    @occurrence_component.on 'ajax:success', '.js-comment-ocurrence-form', ->
      $(this).find('.js-text-area-new-comment').val('')
      CountFieldCharacters.reset_characters_count()
      $('.js-comment-ocurrence-section').addClass('hide-important')

  handle_onclick_answer_button = ->
    @occurrence_component.on 'click', '.js-answer-occurrence-comment', ->
      $($(this).data('target')).toggleClass('hide-important').find('.js-text-area-new-comment').focus()
      return false

  handle_beforesend_form_submit = () ->
    @occurrence_component.on 'ajax:beforeSend', '.js-comment-ocurrence-form', validate_fields = ->
      Error.clear_global_errors()
      textarea_field = $(this).find('#js-text-area-new-comment-validate')

      unless textarea_field.val().length
        add_error_to_input textarea_field
        return false

  add_error_to_input = (field) ->
    message = I18n.t(translations(), 'errors.messages.blank')
    html = "<p class='p-error'>#{message}</p>"
    
    field.parent().prepend html
    field.addClass "error-input"

  handle_textarea_new_comment_enter_key_press = ->
    @occurrence_component.on 'keypress', '.js-text-area-new-comment', (e) ->
      if e.key == 'Enter'
        Rails.fire(@.form, 'submit')
        return false

  update_scroll = ->
    element = document.getElementById('occurrence_commentsection')
    element.scrollTop = element.scrollHeight if element