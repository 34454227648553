import translations from "../translations"
import * as I18n from "../services/i18n"
export default class CountFieldCharacters {
  handler_events (containerClass, inputClass, indicatorClass, setErrorMessage=false) {
    this.handle_on_key_press_on_input_with_limit_lenght(containerClass, inputClass, indicatorClass, setErrorMessage)
  }

  handle_on_key_press_on_input_with_limit_lenght (containerClass, inputClass, indicatorClass, setErrorMessage) {
    const characters_count_containers_class = containerClass

    $(document).on('keyup', characters_count_containers_class, (e) => {
      let characters_count_container = $(e.currentTarget)
      let input = characters_count_container.find(inputClass)

      const count_element = characters_count_container.find(indicatorClass)

      const length_to_display = input.val().length

      if(setErrorMessage) putErrorMessage(count_element, length_to_display)
      
      count_element.html(length_to_display)
    })
  }

  static reset_characters_count (indicatorClass) {
    $(indicatorClass).html('0')
  }
}

function putErrorMessage (countElement, lengthToDisplay){
  let amountAllowed = parseInt(countElement.siblings().text())
  const errorMessage = I18n.t(translations(), 'fvs_preenchimentos.components.dynamic_forms.errors.char_limit_exceeded')

  if(lengthToDisplay > amountAllowed){
    let errorMessageIntoSpan = countElement.parent().find('.character-limit-error')
    if(errorMessageIntoSpan.length == 0)
      countElement.parent().append(`<span class='p-error character-limit-error'> ${errorMessage}</span>`);
  }

  if(lengthToDisplay <= amountAllowed) 
    countElement.parent().find('.character-limit-error').remove();
}