$(document).on 'turbolinks:load', ->
  $('.inputfile').each ->
    $input = $(this)
    $label = $input.next('label')
    labelVal = $label.html()
    $input.on 'change', (e) ->
      fileName = ''
      if @files and @files.length > 1
        fileName = (@getAttribute('data-multiple-caption') or '').replace('{count}', @files.length)
      else if e.target.value
        fileName = e.target.value.split('\\').pop()
      if fileName
        $label.find('span').html fileName
      else
        $label.html labelVal
      return
    # Firefox bug fix
    $input.on('focus', ->
      $input.addClass 'has-focus'
      return
    ).on 'blur', ->
      $input.removeClass 'has-focus'
      return
    return
  return
