import Error from "../../../utils/error.coffee"
import AjaxLoader from "../../../utils/ajax_loader.coffee"
import translations from "../../../translations"
import * as I18n from "../../../services/i18n"

export default class StepGroupsCreation
  handler_events: ->
    handle_create_macro_click()
    handle_create_group_click()
    handle_step_group_save_click()
    handler_macro_select_change()

  handle_create_macro_click = ->
    $('#js_create_macro').on 'click', ->
      clear_input()
      Error.clear_global_errors()
      $(@).data('clicked', true)
      $('#js_create_group').data('clicked', false)
      $('#atividade_step_group_group_parent_id').attr('disabled', true)
      $('.step_group_group_name').hide()

      modal_title_text = I18n.t(translations(), 'plannings.steps.form.create_macro')

      container = $('#js_create_step_group')
      container.find('.modal-title').text(modal_title_text)
      container.modal('toggle')

  handle_create_group_click = ->
    $('#js_create_group').on 'click', ->
      clear_input()
      Error.clear_global_errors()
      $(@).data('clicked', true)
      $('#js_create_macro').data('clicked', false)
      $('#atividade_step_group_group_parent_id').attr('disabled', false)
      $('.step_group_group_name').show()

      modal_title_text = I18n.t(translations(), 'plannings.steps.form.create_micro')
      container = $('#js_create_step_group')
      container.find('.modal-title').text(modal_title_text)
      container.modal('toggle')

  handle_step_group_save_click = ->
    $('#js_step_group_save').on 'click', ->
      new AjaxLoader('step-group-save').show()
      form = $('form.js-step-groups')

      $.ajax {
        type: form[0].method,
        dataType: 'json',
        data: form.serialize(),
        url: form[0].action,
        success: (group) -> add_options_to_group_select(group)
        error: (xhr, status, type) -> show_errors(xhr.responseJSON)
      }

  add_options_to_group_select = (group) ->
    if $('#js_create_macro').data('clicked') 
      resource_name = 'plannings.steps.form.macro' 
    else
      resource_name = 'activerecord.models.step_group.one'

    $('#js_group_select').empty()
    new AjaxLoader('step-group-save').hide()
    Error.clear_global_errors()
    success_msg = I18n.t(translations(), 'flash.actions.create.notice',
                              resource_name: I18n.t(translations(), resource_name))
    Error.show_success_flash_msg(success_msg)
    html = "<option selected value=#{group.id}> #{group.name} </option>"

    $('.js_macro_select').append(html) if $('#js_create_macro').data('clicked')
    $('.js_group_select').append(html) if $('#js_create_group').data('clicked')
    $('#js_create_step_group').modal('toggle')
    $('#atividade_step_group_group_parent_id').val(group.id)
    $('#js_step_group_name').val(group.name)
    toggle_create_micro()

  toggle_create_micro = ->
    $('#js_create_group').hide() unless $('.js_macro_select').val()
    $('#js_create_group').show() if $('.js_macro_select').val()

  show_errors = (errors) ->
    new AjaxLoader('step-group-save').hide()
    Error.clear_global_errors()
    fail_msg = I18n.t(translations(), 'flash.actions.create.alert',
                              resource_name: I18n.t(translations(), 'activerecord.models.step_group.one'))

    if errors.check_construction_site
      fail_msg = I18n.t(translations(), 'flash.construction_site_changed')
      Error.show_alert_flash_msg_fixed(fail_msg)
    else    
      Error.show_alert_flash_msg(fail_msg)

    for key of errors
      idClass= "atividade_step_group_#{key}"
      msg = errors[key][0]
      new Error(idClass, msg).show()

  handler_macro_select_change = ->
    $('.js_macro_select').on 'change', (e, group_id_for_select) ->
      option = $(@).find('option:selected')
      unless option.val()
        $('.js_planning_step_step_group_id abbr').hide()
        change_options_from_group_selection([], group_id_for_select)
        return

      config_load_select_show()
      $('.js_planning_step_step_group_id abbr').show() if option.get(0)
      $('#atividade_step_group_group_parent_id').val(option.val())
      $('#js_step_group_name').val(option.html())

      $.ajax {
        type: 'GET',
        dataType: 'json',
        data: { id: option.val() },
        url: '/step_groups/search.json',
        success: (groups) -> change_options_from_group_selection(groups, group_id_for_select)
      }

  change_options_from_group_selection = (groups, group_id_for_select) ->
    config_load_select_hide()
    default_option = I18n.t(translations(), 'helpers.select.prompt')
    html = "<option value=''> #{default_option} </option>"
    for group in groups
      html += "<option value=#{group.id}> #{group.name} </option>"

    $('.js_group_select').empty().append(html)
    $('.js_group_select').val(group_id_for_select) if group_id_for_select
    toggle_create_micro()

  toggle_create_micro = ->
    $('#js_create_group').show() if $('.js_macro_select').val()
    $('#js_create_group').hide() unless $('.js_macro_select').val()

  config_load_select_show = ->
    time_for_resize_width_run_on_browser = 200
    $('.js_macro_select').css('width', '328px')
    $('.js_group_select').attr('disabled', 'disabled')
    setTimeout ( ->
      new AjaxLoader().show()
    ), time_for_resize_width_run_on_browser

  config_load_select_hide = ->
    time_for_smoth_animation = 300
    setTimeout ( ->
      new AjaxLoader().hide()
      $('.js_macro_select').css('width', '100%')
      $('.js_group_select').attr('disabled', false)
    ), time_for_smoth_animation

  #------------------helpers ----#
  clear_input = ->
    $('#atividade_step_group_name').val('')


