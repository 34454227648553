import Error from "../../../utils/error.coffee"
import translations from "../../../translations"
import * as I18n from "../../../services/i18n"

export default class ChangeMultisiteDate
  constructor: ->
    @picker = $('.js-finish-at-pickedate-multisite .js-finish-at-pickedate-open') 
      .pickadate('picker')

  handle_events: ->
    return unless @picker

    handler_on_confirm_click.call(@)
    handler_on_change_reason_select()
    disable_finish_at_date_picker.call(@)
  
  handler_on_confirm_click = ->
    self = @
    $('.js-change-finish-at-confirm').on 'click', ->
      Error.clear_global_errors()
      input_id = $('#js_reason_change_date_id')
      input_other = $('#js_reason_change_date_other')

      input_id_valid = 
        input_id.val() && !selected_options_is_last(input_id.get(0))
      input_other_valid = 
        input_other.val() && selected_options_is_last(input_id.get(0))

      if input_id_valid || input_other_valid
        enable_finish_at_date_picker.call(self)
        $('#change_finish_at').modal('toggle')
      else
        disable_finish_at_date_picker.call(self)
        msg = I18n.t(translations(), 'obras.components.modal_change_multisite_finish_at.not_blank')
        
        new Error('js-reason-change-date-id-container', msg)
          .show() if !input_id.val()
        
        new Error('js-reason-change-date-other-container', msg)
          .show() if !input_other.val() && selected_options_is_last(input_id.get(0))

  handler_on_change_reason_select = ->
    $('#js_reason_change_date_id').on 'change', ->
      Error.clear_global_errors()
      if selected_options_is_last(@)
        $('#js_reason_change_date_other').val("")
        $('#js_reason_change_date_other').attr('readonly', false)
      else
        description = @selectedOptions[0].dataset.description
        $('#js_reason_change_date_other').val(description)
        $('#js_reason_change_date_other').attr('readonly', true)

  selected_options_is_last = (select) ->
    select.options.selectedIndex == select.options.length - 1

  disable_finish_at_date_picker = ->
    $('.js-input-finish-at').attr('readonly', true)
    @picker.stop()
    
  enable_finish_at_date_picker = ->
    $('.js-input-finish-at').attr('readonly', false)
    @picker.start()
