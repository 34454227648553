import Chart from "chart.js"
import { set_tooltip_event } from "./shared/chartjs_custom_tooltip"
import translations from "../../../translations"
import * as I18n from "../../../services/i18n"

export default class ServiceInspectionFormFilledChart
  @render: ->
    $.get "/service_inspection_form_filled_chart", (data) ->
      $("#js_siff_container_chart").html data
      
      siff_data = $("#js_siff_content_chart")
        .data("all-siff")

      return unless siff_data.length
      
      siff_chart_area = document.querySelector("#js_siff_chart_area").getContext("2d")
      chart = new Chart(siff_chart_area, {
        type: 'pie',
        data: pie_data(siff_data),
        options: {
          maintainAspectRatio: false,
          legend: {display: false},
          responsive: true,
          layout: {
            padding: {
              left: -25,
              top: 0,
              bottom: 0
            }
          },
          legendCallback: (chart) -> legend_html_template(chart.config.data),
          tooltips: {
            enabled: false,
            custom: (tooltip) ->
              set_tooltip_event(tooltip, ".js-service-inspection-form-filled-tooltip")
          }
        }
      })

      $('#js-siff-chart-legend').prepend(chart.generateLegend())

  pie_data = (siffs) ->
    pie_data_siff = {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: []
      }]
    }
    for siff in siffs
      pie_data_siff['labels'].push("#{siff.status}: #{siff.total} / #{siff.percentage}%")
      pie_data_siff['datasets'][0]['data'].push(siff.total)
      pie_data_siff['datasets'][0]['backgroundColor'].push(chart_color(siff.status))
    pie_data_siff

  chart_color = (status) ->
    return "#9B9B9B" if status == I18n.t(translations(), 'activerecord.attributes.preenchimento/fvs_preenchimento.andamento')
    return "#FDD03C" if status == I18n.t(translations(), 'activerecord.attributes.preenchimento/fvs_preenchimento.finalizada')
    return "#2ECC71" if status == I18n.t(translations(), 'activerecord.attributes.preenchimento/fvs_preenchimento.aprovada')
    return "#4C84FF" if status == I18n.t(translations(), 'activerecord.attributes.preenchimento/fvs_preenchimento.submitted')
    return "#4b2ecc"

  legend_html_template = (data) ->  
    htmlArray = []
    htmlArray.push("<ul class=\"siffchart-legend\"> ")
    for label in data.labels
      labelArray = label.split(':')
      htmlArray.push("<li class=\"legenditem\">")
      htmlArray.push("<span class=\"radius-element\" style=\"background-color:#{chart_color(labelArray[0])}\"></span> #{labelArray[1]} #{with_legend(labelArray[0])}")
      htmlArray.push("</li>")
    htmlArray.push("</ul>")

    htmlArray.join(' ')

  with_legend = (label) ->
    text_with_legend = $("#js_siff_content_chart").data('with-text-legend')
    if text_with_legend == 1 then "#{label}" else ""