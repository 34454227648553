export default class Locals
  handler_events: ->
    handle_click_save_order_button()

  handle_click_save_order_button = ->
    $('#js_save_local_order').on 'click', ->
      $(@).prop("disabled", true)
      locals_array = $('#js_sortable_list').sortableListsToArray()

      construction_site_id = $('meta#js_general_construction_site_id').data('id')

      new_orders = []
      for item in locals_array
        parent = if item.parentId == undefined then null else item.parentId

        new_orders.push({ id: item.id, local_id: parent, ordenacao: item.order })
      valid_id = new_orders[0].id
      $.ajax
        type: 'PUT'
        data: { obra_local: { new_orders: new_orders, obras_id: construction_site_id } }

        url: "#{$(@).data('action')}/#{valid_id}.js"

