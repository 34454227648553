export default class ContractProgressEvaluation
  start_handlers: () ->
    item_handler_over()
    item_handler_click()
    item_handler_hover()
    save()

  show_selecteds: () ->
    items = $('.itens_selecteds').map( ->
      type = $(this).data('type')
      return $.map($(this).data('items'), (n) ->
          n.type = type
          return n
        )
    ).get()
    $.each(items, (index, item) ->
      item_clicked = $('.progress-evaluation-list-container.type-'+item.type).filter('[data-id='+item.id+']')
      item_clicked.addClass('evaluation-main-hover-color-class');
      item_clicked.find('.progress-evaluation-circle-to-select').addClass('progress-evaluation-circle-selected');
      add_item(item.id, item.type)
    )
  save = () ->
    $('#add-contract-progress-evaluation').click ->
      form = $('form').serializeArray()
      $.ajax {
            type: "POST",
            data: form,
            url: $('#contract-progress-evaluation-form').attr('action')
      }


  item_handler_hover = () ->
    $(".btn-approve-progress-evaluation-item").hover(
      -> $(this).parent().addClass('approve-item-hover'),
      -> $(this).parent().removeClass('approve-item-hover')
    )



  item_handler_over = () ->
    $(".progress-evaluation-list-container").mouseover ->
      $(this).find('.progress-evaluation-circle-to-select').addClass('progress-evaluation-circle-hovered');
      $(".progress-evaluation-list-container").mouseout ->
        $(this).find('.progress-evaluation-circle-to-select').removeClass('progress-evaluation-circle-hovered');

 item_handler_click = () ->
   $('.progress-evaluation-list-container').click ->
    item_clicked = $(this)
    item_id      = item_clicked.data 'id'
    item_type    = item_clicked.data 'type'
    if (item_clicked.hasClass('evaluation-main-hover-color-class'))
      item_clicked.removeClass('evaluation-main-hover-color-class');
      item_clicked.find('.progress-evaluation-circle-to-select').removeClass('progress-evaluation-circle-selected');
      remove_item(item_id)
    else
      item_clicked.addClass('evaluation-main-hover-color-class');
      item_clicked.find('.progress-evaluation-circle-to-select').addClass('progress-evaluation-circle-selected');
      add_item(item_id, item_type)

  add_item = (id, type) ->

    input = $('<input type= "hidden" data-id="'+id+'" name= "construction_site_progress_evaluation_contract_progress_evaluation_contract_progress_evaluation['+type+'][]" />')
    input.val(id)
    $('form').append(input)

  remove_item = (id) ->
    $('input[data-id="'+id+'"]').remove()

