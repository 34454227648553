export default class StantMenuTooltip
  constructor: ->
    @collapse_menu = ".collapse-menu .menu a"
    @menu_titles   = '.menu *[title]'

  enable: ->
    $(@collapse_menu).tooltip('enable')

  disable: ->
    $(@menu_titles).tooltip('disable')

  configure_tooltip_when_click_on_link: (selector) ->
    $(selector).tooltip('dispose')
    $(selector).tooltip('enable')
