import { handleFormValidations } from './utils/form_validations'
$(document).on('turbolinks:load', function () {
    handleFormValidations()

    $(document).on('change', '#js-company-reports-report-type-select', function() {
        const reportTypeElement = $(this);
        const formElement = $('#company_reports_form');

        formElement.attr('action', reportTypeElement.val() || '/company_reports_form/');
    })

})



