import Rails from "rails-ujs"
import swal from "sweetalert2"
import translations from "translations"
import * as I18n from "services/i18n"

var handleConfirm = function(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element)
  }
}

var handleConfirmReverse = function(element) {
  var confirmText = this.getAttribute('data-confirm-reverse')

  if(confirmText) {
    this.setAttribute('data-confirm', confirmText)
    this.removeAttribute('data-confirm-reverse')
  }
}

var allowAction = element => {
  if (element.getAttribute('data-confirm') === null) {
    return true
  }

  showConfirmationDialog(element)
  return false
}

var showConfirmationDialog = element => {
  var message = element.getAttribute('data-confirm')
  var messageHeadline = element.getAttribute('data-confirm-headline')

  swal({
    title: messageHeadline || `${I18n.t(translations(), 'form.confirmar')}?`,
    html: message,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: I18n.t(translations(), 'form.confirm_yes'),
    cancelButtonText: I18n.t(translations(), 'form.confirm_no'),
  }).then((willDelete) => {
    if (willDelete.value) {
      confirmed(element)
    }
  });
}

var confirmed = (element) => {
  var confirmText = element.getAttribute('data-confirm')

  element.removeAttribute('data-confirm')
  element.dispatchEvent(new Event('confirmed'))
  element.click()

  if (element.classList.contains('switch-checkbox')) {
    element.setAttribute('data-confirm-reverse', confirmText)
  }

}

Rails.delegate(document, 'a[data-confirm]', 'click', handleConfirm)
Rails.delegate(document, 'button[data-confirm]', 'click', handleConfirm)
Rails.delegate(document, 'input[data-confirm]', 'click', handleConfirm)
Rails.delegate(document, 'input[data-confirm-reverse]', 'click', handleConfirmReverse)
