import Error from "../../utils/error.coffee"
import StantDatepicker from "../../utils/stant_datepicker.coffee"

export default class DocumentsTable

  @add_listeners: (document_session, folder_session) ->
    row_select()
    folders_listeners(folder_session)
    documents_listeners(document_session)
    document_details_listeners()

  row_select = ->
    $('.documents .table tr').off().on 'click', (event) ->
      if event.target.type != 'checkbox'
        $(':checkbox', this).trigger 'click'
      StantDatepicker.init()



    $('.documents .table').off().on 'change', '.checkbox', (e) ->
      $('.checkbox').not($(this)).prop 'checked', false
      $('tr').not($(this).parents('tr')).removeClass 'row_selected'

      if $(this).is(':checked')
        $(this).closest('tr').addClass 'row_selected'
      else
        $(this).closest('tr').removeClass 'row_selected'

  folders_listeners = (folder_session) ->
    $('.link-edit').on 'click', ->
      Error.clear_global_errors()
      folder_id = $(this).data('id')

      $(".expiration-date-form").hide()
      $("#folder-field-#{folder_id}").toggle()
      $("#folder-input-#{folder_id}").toggle()
      $("#folder-input-#{folder_id} input").focus()

    $('.table .folder').on 'change', '.checkbox', (e) ->
      id = $(this).attr('id')

      $('.folder-field').not($("#folder-field-#{id}")).show()
      $('.folder-input').not($("#folder-input-#{id}")).hide()

      $('.documents-details').hide()
      $('.folder-options').children().not($("#folder-options-#{id}")).hide()
      if $(this).is(':checked')
        if folder_session
          permissions_html = sessionStorage.getItem("permissions-#{id}")
          $('.permissions-content').append $(permissions_html)
          sessionStorage.removeItem "permissions-#{id}"

          new DocumentsDataPermissions.add_listeners()

        $("#folder-options-#{id}").show()
      else
        $(this).prop 'checked', false
        $(this).parents('tr').removeClass 'row_selected'
        $("#folder-options-#{id}").hide()

  documents_listeners = (document_session) ->
    $('.table .document').on 'change', '.checkbox', (e) ->
      id = $(this).attr('id')

      $(".expiration-date-form").hide()
      $('.folder-options').children().hide()
      $('.folder-field').show()
      $('.folder-input').hide()
      $('.documents-details').not($("#details-#{id}")).hide()
      if $(this).is(':checked')
        if document_session
          details_html = sessionStorage.getItem("details-#{id}")
          document_element_count = $('.details-content').find("#details-#{id}").length
          if document_element_count == 0
            $('.details-content').append $(details_html)
            document_details_listeners()

            permissions_html = sessionStorage.getItem("permissions-#{id}")
            $('.permissions-content').append $(permissions_html)

        new DocumentsDataPermissions.add_listeners()

        $(".details-content #details-#{id}").removeClass('hide')
        $(".details-content #details-#{id}").show()
      else
        $(this).prop 'checked', false
        $(this).parents('tr').removeClass 'row_selected'
        $(".details-content #details-#{id}").hide()
    return

  document_details_listeners = ->
    $('.documents-details').off().perfectScrollbar()

    $('.details-content').off().on 'click', '.link-close', (e) ->
      $('.documents-details').hide()
      $('.checkbox').prop('checked', false)
      $('.document').removeClass('row_selected')
      $(".expiration-date-form").hide()

    $('.open-expiration-date').off().on 'click', (e) ->
      id = $(this).data('id')

      if $("#expiration-date-form-#{id}").is(":visible")
        $("#expiration-date-form-#{id}").hide()
        $('.documents-details').animate { scrollTop: $(".details-header").offset().top }, 400
      else
        Error.clear_global_errors()
        $("#expiration-date-form-#{id}").show()
        $("#expiration-date-form-#{id}").removeClass('hide')
        $('.documents-details').animate { scrollTop: $("#expiration-date-form-#{id}").offset().top }, 800

    $('.remove-expiration-date').off().on 'click', ->
      id = $(this).data('id')
      form = $("#expiration-date-form-#{id}").find('form')
      button = $("#expiration-date-form-#{id}").find('button')

      form.find("#expiration_date_#{id}").val("")
      form.find(":selected").prop('selected', false)
      $("#tr-#{id}").find('.tr-expiration-date').text("")

      button.click()

window.DocumentsTable = DocumentsTable