import StantMultiselect from"../../../utils/stant_multiselect.coffee"
import AjaxLoader from"../../../utils/ajax_loader.coffee"
import FvsServiceVerificationRequestSessionStorage from "./fvs_service_verification_request_session_storage.coffee"
import SelectManageableServices from "./select_manageable_services.coffee"
import Swal from "sweetalert2"
import translations from "translations"
import * as I18n from "services/i18n"

$(document).on 'turbolinks:load', ->
  new StantMultiselect('report-fvs-multiselect').add_listener()
  new StantMultiselect('report-fvs-manageable-services-multiselect', true, true).add_listener()
  new SelectManageableServices().add_handlers()
  new FvsServiceVerificationRequestSessionStorage().save_request()

  situation_select = $('.fvs-situation-select2')
  situation_select.select2(placeholder: I18n.t(translations(), 'multi_select.select_one_or_more_options'), formatNoMatches: I18n.t(translations(), 'select2.search_no_results'))

  finisher_select = $('.finisher-select')
  approver_select = $('.approver-select')

  disapproved = '1'
  approved    = '2'

  situation_select.on 'change', (event) ->
    contains_disapproved = disapproved in event.val
    contains_approved    = approved in event.val

    finisher_select.addClass 'is-disabled'
    approver_select.addClass 'is-disabled'

    finisher_select.removeClass 'is-disabled' if contains_approved or contains_disapproved
    approver_select.removeClass 'is-disabled' if contains_approved

  $('.checkbox-all-fvs-period').on 'click', ->
    el = $(".#{this.dataset.period}")
    el.toggleClass 'is-disabled'
    $('#request_fvs_begin_at, #request_fvs_end_at').attr('disabled', el.hasClass('is-disabled'))

  $('.btn-search').on 'click', ->
    input_page = $('#page')
    input_page.attr 'value', '1'
    new FvsServiceVerificationRequestSessionStorage().save_request()

  $('#request_form_fvs_service_verification').on 'submit', ->
    new AjaxLoader().show()
    $('.btn-submit').addClass 'is-disabled'

  $('#js-build-pdf').on 'click', 'a', (e) ->
    e.preventDefault()

    params = $('#request_form_fvs_service_verification').serialize()
    url = "/reports/fvs_service_verifications/report?#{params}"
    window.open(url, '_blank')

  $("#js-build-consolidated-pdf").on "click", (e) ->
    e.preventDefault()

    Swal.fire(
      title: 'Deseja continuar?'
      text: 'O relatório pode demorar para ser exibido.'
      type: 'warning'
      showCancelButton: true
      confirmButtonText: 'Sim'
      cancelButtonText: 'Não').then (result) ->
      if result.value
        params = $('#request_form_fvs_service_verification').serialize()
        url = "/reports/consolidated_reports/report_pdf?#{params}"
        window.open(url, '_blank')
      return

  $('.fvs-service-verification-table-result').on 'click', '.js-build-item-pdf', (e) ->
    e.preventDefault()

    images_mode_list = $('#request_form_fvs_service_verification')
      .serializeArray()
      .filter((obj) -> obj.name == 'request[images_mode]')
    images_mode = images_mode_list[images_mode_list.length-1]

    params = $(this).data('params')
    if images_mode != undefined
      url = "/reports/fvs_service_verifications/item_report_pdf?#{$.param(params)}&images_mode=#{images_mode.value}"
    else 
      url = "/reports/fvs_service_verifications/item_report_pdf?#{$.param(params)}&images_mode=standard"
    window.open(url, '_blank')

$(document).ready ->
  $('.fvs-situation-select2.filter-input .select2-input').css("color", "#aaa")
