import translations from "../../translations"
import * as I18n from "../../services/i18n"
import StantFormat from "../../utils/stant_formt.coffee"

export default class ServiceFormBuilder {
  constructor(service) {
    this.service = service
  }

  append_on = function(element) {
    this.set_attrs_for_service(this.service.id)
    let html = this.build_form_html(this.service)
    element.append(html)
  }

  set_attrs_for_service = function(service_id) {
    this.destroy_attrs = " href='javascript:void(0)' "
    this.new_place_class = ' remove-new-service '
    this.service_id_input = ` <input type='hidden' name='preplanning[services][][service_id]' value='${service_id}'> `
    this.is_service_manageable = !!$(`#js-manageable-${service_id}`).length
    this.service_type = $('.manageable').filter( function(self) { self.value == service_id } )
    this.view_id_input = ` <input type='hidden' name='preplanning[services][][view_id]' value='${this.generate_view_id(service_id)}'> `
  }
  
  generate_view_id = function(id) {
    Date.now() + id
  }

  build_form_html = function(service) {
    let service_values = {
            'disabled': {true: 'disabled', false: ''},
            'service_type_class': {true: 'manageable', false: 'standard'}
          }

    let disabled = service_values['disabled'][this.is_service_manageable]
    let service_type = service_values['service_type_class'][this.is_service_manageable]
    let quantity_obligatory_indicator = this.is_service_manageable ? '' : '<abbr title="required">*</abbr>'

    let html =
      `
        <div class="js-services-preplanning-row services-preplanning-row row">
          ${this.service_id_input}
          ${this.view_id_input}
          <p class="title">${service.service_description}</p>
          <div class="col-md-4 preplanning_quantity_${service.id}">
            <label class="label" for="planning_quantidade_${service.id}">
              ${I18n.t(translations(), 'atividades.form.quantity')} ${quantity_obligatory_indicator}
            </label>
            <input id="preplanning_quantity_${service.id}"
              tabindex=1 type='text' name='preplanning[services][][quantity]'
              value="${service.quantity}"
              class="js-preplanning-quantity quantidade form-control mask-decimal">
          </div>

          <div class="col-md-4 preplanning_value_${service.id}">
            <label class="label" for="planning_valor_${service.id}">
              ${I18n.t(translations(), 'atividades.form.unitary_value')} <abbr title="required">*</abbr>
              (${service.symbol_unit_measurement})
            </label>
            <input id="preplanning_value_${service.id}" type='text' tabindex=1
              name='preplanning[services][][value]'
              value="${service.value}" class="js-preplanning-value valor form-control mask-money" value="R$ 0,00" ${disabled}>
          </div>

          <div class="col-md-2">
            <label class="label" for="total_#{service.id}">Total</label>
            <input id="preplanning_total_#{service.id}" disabled type='text' value="R$ 0.0"
            class="js-preplanning-total valor form-control mask-money">
          </div>

          <input type="hidden" value="#{service_type}" name=preplanning[services][][service_type] />

          <div class="col-md-2">
            <a ${this.destroy_attrs} title="${I18n.t(translations(), 'atividades.form.remove')}" class="remove-service ${this.new_place_class} p-t-30">
              <i class="icon icon-close"></i>
            </a>
          </div>
        </div>
      `

    return html
  }
}