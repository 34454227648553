import translations from "translations"
import * as I18n from "services/i18n"

export default class InteractiveTable {
  constructor(options) {
    this.tableContainerSelector = options.tableContainerSelector
    this.tableContainer = document.querySelector(options.tableContainerSelector)
    this.data = options.data
    this.rowSuffixes = ['A', 'B']
    this.baseMaxLength = 25
  }

  init() {
    this.buildTable()
    this.addButtonActionsListeners()
  }

  buildTable() {
    const table = document.createElement('table')
    table.appendChild(buildHiddenRowHtml(this.data.columns_per_row))

    this.rowSuffixes.forEach((rowSufix, rowIndex) => {
      const tr = document.createElement('tr')
      tr.setAttribute('class', 'header-row')

      this.data.header.filter((x) => x.row == rowIndex + 1).forEach((cell, columnIndex) => {
        const td = this.buildTdHtml(cell, columnIndex)
        if(cell.total_columns != 0) tr.appendChild(td)
      })

      table.appendChild(tr)
    })

    this.tableContainer.appendChild(table)
  }

  merge() {
    const rows = document.querySelectorAll(`${this.tableContainerSelector} .header-row:not(.-hidden)`)

    rows.forEach((row, rowIndex) => {
      const cells = Array.from(row.querySelectorAll('.header-cell'))

      cells.forEach((cell, cellIndex) => {
        const nextCell = cells[cellIndex + 1]

        if(isCellChecked(cell) && isCellChecked(nextCell)) {
          const colspan = getColspan(cell) + getColspan(nextCell)
          const labelEnd = parseInt(nextCell.dataset.column) + getColspan(nextCell)

          cell.setAttribute('colspan', colspan)
          cell.querySelector('.celllabelend').innerText = labelEnd
          cell.querySelector('.celllabelseparator').classList.remove('hide')
          cell.querySelector('input[name="title"]').setAttribute('maxlength', this.baseMaxLength * colspan)
          nextCell.remove()

          cells[cellIndex + 1] = cell
        } else {
          cell.querySelector('.cellcheckbox').checked = false
        }
      })
    })
  }

  split() {
    const rows = document.querySelectorAll(`${this.tableContainerSelector} .header-row:not(.-hidden)`)

    rows.forEach((row, rowIndex) => {
      const cells = row.querySelectorAll('.header-cell')
      const originalCellsData = this.data.header.filter((x) => x.row == rowIndex+1)

      cells.forEach((cell, cellIndex) => {
        const cellColspan = getColspan(cell)

        if(isCellChecked(cell) && cellColspan > 1) {
          const cellColumn = parseInt(cell.dataset.column)

          cell.setAttribute('colspan', 1)
          cell.querySelector('.celllabelend').innerText = ''
          cell.querySelector('.celllabelseparator').classList.add('hide')
          cell.querySelector('input[name="title"]').setAttribute('maxlength', this.baseMaxLength)

          const splitedCellsData = originalCellsData.slice(cellColumn + 1, cellColumn + cellColspan)

          let last = cell
          splitedCellsData.forEach((splitedCell, columnIndex) => {
            const td = this.buildTdHtml(splitedCell, cellColumn + 1 + columnIndex)
            row.insertBefore(td, last.nextSibling)
            last = td
          })
        }

        cell.querySelector('.cellcheckbox').checked = false
      })
    })
  }

  addButtonActionsListeners() {
    document.querySelector(`${this.tableContainerSelector} .headeraction.merge`).addEventListener('click', () => {
      this.merge()
    })

    document.querySelector(`${this.tableContainerSelector} .headeraction.split`).addEventListener('click', () => {
      this.split()
    })
  }

  buildTdHtml(cell, index) {
    const td = document.createElement('td')
    td.setAttribute('class', 'header-cell')
    td.setAttribute('colspan', cell.total_columns)
    td.dataset.column = index
    td.dataset.row = cell.row

    const possibleFieldsSelectOptions = this.data.possible_fields.map((field) => {
      return `<option value="${field.name}" ${(field.name == cell.field) ? 'selected' : ''}>
        ${field.label}
      </option>`
    })
    const mergedColumnsLength = cell.merged_columns.length
    const labelEnd = mergedColumnsLength === 0 ? '' : index + 1 + mergedColumnsLength
    const labelSeparatorVisibility = mergedColumnsLength === 0 ? 'hide' : ''

    td.innerHTML = `
      <label class="celllabel">
        <input class="cellcheckbox" type="checkbox">
        ${I18n.t(translations(), 'reports.reports_generator.components.edit_header_modal.cell')}
        ${index + 1}
        <span class="celllabelseparator ${labelSeparatorVisibility}"> - </span>
        <span class="celllabelend">${labelEnd}</span>
        ${this.rowSuffixes[cell.row - 1]}
      </label>

      <div class="p-t-5">
        <label class="label">${I18n.t(translations(), 'reports.reports_generator.components.edit_header_modal.title_input')}</label>

        <input type="text" class="cellinput" name="title" value="${cell.title === null ? '' : cell.title}" maxlength="${this.baseMaxLength * cell.total_columns}" />
      </div>

      <div class="p-t-5">
        <label class="label">${I18n.t(translations(), 'reports.reports_generator.components.edit_header_modal.session_select')}</label>
        <select class="cellinput" name="field">
          <option value="">${I18n.t(translations(), 'reports.reports_generator.components.edit_header_modal.session_select_prompt')}</option>
          ${possibleFieldsSelectOptions}
        </select>
      </div>
    `

    return td
  }
}

const buildHiddenRowHtml = (numberOfColumns) => {
  const tr = document.createElement('tr')
  tr.setAttribute('class', 'header-row -hidden')

  Array(numberOfColumns).forEach(() => {
    const td = document.createElement('td')
    td.setAttribute('class', 'header-cell')
    tr.appendChild(td)
  })

  return tr
}

const isCellChecked = (cell) => {
  if(cell == null) return

  return cell.querySelector('.cellcheckbox').checked
}

const getColspan = (cell) => parseInt(cell.getAttribute('colspan')) || 1
