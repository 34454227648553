import StantFormat from "../../../../utils/stant_formt.coffee"

export default class EditPrePlanning
  handler_events: ->
    handle_on_change_input()
    calc_value_total_from_pre_planning()

  handle_on_change_input = ->
    $('#preplanning_quantity, #preplanning_value').on 'change', ->
      calc_value_total_from_pre_planning()

  calc_value_total_from_pre_planning = ->
    quantity = $('#preplanning_quantity').maskMoney('unmasked')[0]
    value = $('#preplanning_value').maskMoney('unmasked')[0]

    total = quantity * value
    total = StantFormat.converteFloatMoeda(total)

    $('#pre_planning_edit_total').val(total)
