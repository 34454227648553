import translations from 'translations'
import * as I18n from 'services/i18n'

export const buildImageModal = (imageContent, guid) => {
  return `
    <div id="new-image-${guid}" class="modal fade in new-image-form" data-width="600" data-height="620" tabindex="-1">
      <input type="hidden" name="inspection_data_image[guid]" value="${guid}" />
      <input type="hidden" name="inspection_data_image[image]" value="${imageContent}" />

      <div class="modal-header">
        <h5 class="modal-title"> ${I18n.t(translations(), 'fvs_preenchimentos.components.inspection_image_form.title')} </h5>

        <div class="close" data-dismiss="modal" aria-hidden="true">
          <i class="icon icon-cancel"></i>
        </div>
      </div>

      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-md-12 centered-image-preview -big">
              <img src="${imageContent}">
            </div>

            <div class="col-md-12 txt-center">
              <a class="btn btn-small btn-link" download="image" href="${imageContent}">
                <span>${I18n.t(translations(), 'fvs_preenchimentos.components.inspection_image_form.download_image')}</span>
              </a>
            </div>
          </div>

          <div class="row m-t-15">
            <div class="col-md-12">
              <label class="label">${I18n.t(translations(), 'fvs_preenchimentos.components.inspection_image_form.caption')}:</label>

              <div class="input-group">
                <textarea maxlength="30" class="form-control caption-image" rows="2" name="inspection_data_image[caption]"></textarea>
              </div>
            </div>       
          </div>
        </div>
      </div>

      <div class="modal-footer center-align">
        <a class="btn btn-link btn-danger btn-cancel-image-form" data-dismiss="modal" href="javascript:void(0)">
          ${I18n.t(translations(), 'form.cancelar')}
        </a>

        <button type="button" class="btn btn-submit btn-success" data-dismiss="modal">
          ${I18n.t(translations(), 'form.salvar')}
        </button>
      </div>
    </div>`
}
