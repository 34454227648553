export default ImagePreview = ->
  self = this

  @init = ->
    $('.image-upload').each ->
      $(this).on 'change', ->
        read_url(this)



    $('.image-upload-entity').on 'change', ->
      read_url_entity(this)

  read_url = (input) ->
    if input.files and input.files[0]
      reader = new FileReader

      reader.onload = (e) ->
        $(input).parent().find('.image-preview').attr 'src', e.target.result
        return

      reader.readAsDataURL input.files[0]

  read_url_entity = (input) ->
    if input.files and input.files[0]
      reader = new FileReader

      reader.onload = (e) ->
        $('.image-preview-entity').attr 'src', e.target.result
        return

      reader.readAsDataURL input.files[0]
  return
