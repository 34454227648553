export default class Error
  constructor: (@idClass, @msg) ->

  show: ->
    html = "<p class='p-error'>#{@msg}</p>"
    $(".#{@idClass}").append html
    $("##{@idClass}").addClass "error-input"

  show_using_class: (class_name) ->
    html = "<p class='p-error'>#{@msg}</p>"
    $(".#{class_name}").append html
    $("##{@idClass}").addClass "error-input"

  @clear_global_errors: ->
    $('.stant-error').remove()
    $('.form-error').remove()
    $('.field').css('border-color','#cfcfcf')
    $('.p-error').remove()
    $('.error-input').removeClass('error-input')
    $('.errors-descriptions').hide()

  @show_alert_flash_msg: (msg, timeout) ->
    timeout ||= 3000

    html = '<div class="alert-box error stant-error animated bounceInRight"> ' +
              '<p> ' + msg +
                '<a href="#" class="close" data-dismiss="alert" aria-label="close">' +
                '  &times;' +
                '</a>' +
              '</p>' +
           '</div>'
    $('.flash-messages').html html
    $('.flash-messages').css({'position':'fixed', 'z-index':9999})
    $('.alert-box').delay(timeout).fadeOut("slow")
    handle_close_click()

  @show_success_flash_msg: (msg, timeout) ->
    timeout ||= 3000

    html = '<div class="alert-box success animated bounceInRight"> ' +
              '<p> ' + msg +
                '<a href="#" class="close" data-dismiss="alert" aria-label="close">' +
                '  &times;' +
                '</a>' +
              '</p>' +
           '</div>'
    $('.flash-messages').html html
    $('.flash-messages').css({'position':'fixed', 'z-index':9999})
    $('.alert-box').delay(timeout).fadeOut("slow")
    handle_close_click()
  
  @show_alert_flash_msg_fixed: (msg) ->
    html = '<div class="alert-box error stant-error animated bounceInRight"> ' +
              '<p> ' + msg +
                '<a href="#" class="close" data-dismiss="alert" aria-label="close">' +
                '  &times;' +
                '</a>' +
              '</p>' +
           '</div>'
    $('.flash-messages').html html
    $('.flash-messages').css({'position':'fixed', 'z-index':9999})

  @fade_out_alert_flash_msg: () ->
    setTimeout ( ->
      $('.alert-box.success').fadeOut(500)
    ), 2000

  handle_close_click = () ->
    $('.alert-box .close').off().on 'click', ->
      $('.alert-box').hide()

window.Error = Error
