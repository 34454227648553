import StantSortableLists from "./stant_sortable_lists.coffee"

export default class StantSortableListsComponent
  # --- Static Public Methods --- #

  @decrease_total_children: (parent_id) ->
    if parent_id
      @.change_total_children(parent_id, -1)

  @increase_total_children: (parent_id) ->
    if parent_id
      @.change_total_children(parent_id, 1)

  # --- Static Private Methods --- #

  @change_total_children = (parent_id, value) ->
    item_children_number = $("#item-children-number-#{parent_id}")
    current_value_qtd_item_parent = parseInt(item_children_number.text())
    operation_result = current_value_qtd_item_parent + value
    item_children_number.text(operation_result)

  # --- Instance Public Methods --- #

  add_listeners: ->
    $('.js-item-options').hide()
    stant_sortable_lists = new StantSortableLists('#js_sortable_list', @)
    stant_sortable_lists.add_listener()
    new_item_listeners()
    edit_item_listeners()

  # --- StantSortable implementation --- #

  on_sortable_change: (element_list, element) ->
    $('.js_save_order').data('id', element.attr('id'))
    return

  on_sortable_complete: (element) ->
    $('.js_save_order').data('id', element.attr('id'))
    return

  on_is_allowed: (currEl, hint, target) ->
    can_move_child = currEl.data('parent_id') != "" and target.get(0) != undefined
    can_move_parent = !currEl.data('parent_id') and !target.get(0)

    if can_move_child or can_move_parent
      hint.css('border', '1px dashed #22bb5c')
      return true
    else
      hint.css('border', '1px dashed #ff9999')
      return false

  # --- Instance Private Methods --- #

  new_item_listeners = ->
    $('.js-add-subitem').on 'click', '.js-add-item', ->
      $('#new').remove() if $('#new')

      new_item = $('.js-new-item').find('.dd-item').clone()
      parent_id = $(this).closest('li').attr('id')

      new_item.find('#js_item_parent_id').val(parent_id)
      new_item.attr('id', 'new')
      $(this).closest('.js-add-subitem').before($(new_item))

      if window.location.href.includes('step_groups')
        new_item.find('.dd-content').addClass('js-remove-sortable-listener-opener')
        new_item.find('.remove-item').addClass('remove-stepgroup')
        new_item.find('.sortableListsOpener').remove()
        new_item.find('.child-qnt').remove()

      new_item.find('#js_item_description').focus()

      new_item.find('.remove-item').on 'click',  ->
        new_item.remove()

      toggle_item_options(new_item.find('.js-description'))
      new_item.find('.js-description').on 'click', ->
        toggle_item_options($(this))

    $('#js-add-item').on 'click', '.js-add-item', ->
      $('#new').remove() if $('#new')

      new_item = $('.js-new-item').find('.dd-item').clone()
      new_item.attr('id', 'new')
      $('#js_sortable_list').append($(new_item))

      new_item.find('#js_item_description').focus()
      new_item.find('.remove-item').addClass('remove-macro')

      new_item.find('.remove-item').on 'click', (e) ->
        new_item.remove()

      toggle_item_options(new_item.find('.js-description'))
      new_item.find('.js-description').on 'click', ->
        toggle_item_options($(this))

  edit_item_listeners = ->
    $('.places-nesteble .js-description').on 'click', ->
      toggle_item_options($(this))

  toggle_item_options = (base) ->
    id = base.data('id')
    description = base.data('description')

    $('.js-item-options').hide()
    $('.with-focus').removeClass('with-focus')

    base.find('#js_item_description').addClass('with-focus')

    base.closest('.row').find('.js-item-options').show()

    base.closest('.row').find('.link-cancel').off().on 'click', ->
      Error.clear_global_errors()
      base.closest('.js-item-options').hide()
      base.find('#js_item_description').val(description)
      base.find('#js_item_description').removeClass('with-focus')

      base.closest('.row').find('.js-item-options').hide()

window.StantSortableListsComponent = StantSortableListsComponent