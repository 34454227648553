
import translations from "../../../translations"
import * as I18n from "../../../services/i18n"

export default class SelectManageableServices
  constructor: ->
    @select_all_text = "#{I18n.t(translations(), 'fvs_service_verification.index.select_all')}"
    @unselect_all_text = "#{I18n.t(translations(), 'fvs_service_verification.index.unselect_all')}"

    @select_all_link = $('#js-fvs-service-multiselect-div').find('.ms-selectall')

    @select_all_manageable_services = $('<div>').append(
        $('<a>', {href: '#', id: 'js-select-all-manageable-services', class: 'select-all-manageable-services add'})
        .html("#{I18n.t(translations(), 'fvs_service_verification.index.select_all_manageable')}")
    )

    @unselect_all_manageable_services = $('<div>', {class: 'hide'}).append(
        $('<a>', {href: '#', id: 'js-unselect-all-manageable-services', class: 'select-all-manageable-services remove'})
        .html("#{I18n.t(translations(), 'fvs_service_verification.index.unselect_all_manageable')}")
    )

    @multiselect_div = $('#js-fvs-service-multiselect-div')
    @select_service_inputs = @multiselect_div.find('input[type=checkbox]')
    @select_button = @multiselect_div.find('.ms-options-wrap').find('button')

    unless @multiselect_div.hasClass('no-manageable-services')
      @select_all_link
        .after(@select_all_manageable_services)
        .after(@unselect_all_manageable_services)


    @select_all_manageable_link = @select_all_manageable_services.find('a')
    @unselect_all_manageable_link = @unselect_all_manageable_services.find('a')

  add_handlers: ->
    select_all_click_handle.call(@)
    select_all_manageable_click_handler.call(@)

  select_all_click_handle = ->
    self = @
    self.select_all_link.click ->
      on_click_handler(self)
      return false

  on_click_handler = (self) ->
    text = {true: self.select_all_text, false: self.unselect_all_text}
    already_selected = self.select_all_link.hasClass('already-selected')
    self.select_all_manageable_services.toggle(already_selected)
    self.unselect_all_manageable_services.toggle(!already_selected)
    self.select_all_link
          .toggleClass('already-selected')
          .text(text[already_selected])
    self.select_service_inputs.prop('checked', !already_selected)
    set_select_box_text(self.select_service_inputs, self)
    set_services_for_search(self)

  filter_manageable = (service_inputs) ->
    service_inputs.filter ->
      manageable_services_ids = JSON.parse($('#js-manageable-services-ids').val())
      service_id = parseInt($(this).val())
      manageable_services_ids.lastIndexOf(service_id) != -1

  set_select_box_text = (inputs, self) ->
    inputs_size = inputs.filter(':checked').length
    selected_text = "#{inputs_size} #{I18n.t(translations(), 'fvs_service_verification.index.selected')}"
    text = if inputs_size == 1 then inputs.val() else selected_text
    text = "#{I18n.t(translations(), 'fvs_service_verification.index.default_select_text')}" if inputs_size == 0
    self.select_button.text(text)

  select_all_manageable_click_handler = ->
    self = @
    self.select_all_manageable_link.add(self.unselect_all_manageable_link).click (event) ->
      event.preventDefault()

      manageable_service_inputs = filter_manageable(self.select_service_inputs)
      manageable_service_inputs.prop('checked', $(this).hasClass('add'))

      set_select_box_text(self.select_service_inputs, self)
      self.select_all_manageable_services.toggle()
      self.unselect_all_manageable_services.toggle()
      set_services_for_search(self)

  set_services_for_search = (self) ->
    service_options = self.multiselect_div.find("option")
    service_options.prop('selected', false)

    self.select_service_inputs.filter(':checked').each ->
      id = parseInt($(this).val())
      service_options.filter("""[value="#{id}"]""").prop('selected', true)
