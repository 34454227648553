$(document).on 'turbolinks:load', ->
  $(".notifications .dropdown-link").on 'click', ->
    event.stopPropagation()
    $(".notifications").toggleClass("active")

  $(".profile .dropdown-link").on 'click', ->
    event.stopPropagation()
    $(".profile").toggleClass("active")

  $(document).on 'click', ->
    $(".dropdown").removeClass("active")

  $('.flash').on 'click', '.close', ->
    $(".alert-box").fadeOut ->
      $(this).remove()
