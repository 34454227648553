import AjaxLoader from "../../../utils/ajax_loader.coffee"

window.InfiniteCardsFvsServiceVerification = class InfiniteCardsFvsServiceVerification
  constructor: (@page, @cards, @record_span, @stop_scrolling) ->
    @fvs_table_result   = $('.fvs-service-verification-table-result')

  append_result: ->
    @fvs_table_result.html '' if @page == '2'
    @fvs_table_result.html @fvs_table_result.html() + @cards

    update_input_current_page(@page)
    update_footer_records.call(@)
    add_scroll_listener.call(@)

  update_input_current_page = (page) ->
    input_current_page = $('#page')
    input_current_page.attr 'value', page

  update_footer_records = ->
    footer_records = $('.footer-records')
    records        = $('.records')

    footer_records.addClass 'results-footer'
    records.html @record_span

  add_scroll_listener = ->
    new AjaxLoader('loader-bar-static').show()
    new AjaxLoader('loader-bar').hide()

    $(window).off().on 'scroll', ->
      if need_call_ajax()
        new AjaxLoader('loader-bar-static').hide()
        new AjaxLoader('loader-bar').show()
        request = FvsServiceVerificationRequestSessionStorage.get_request()

        call_ajax(@page, request)
        $(window).off 'scroll'

    if @stop_scrolling == 'true'
      $(window).off 'scroll'
      new AjaxLoader('loader-bar-static').hide()

  need_call_ajax = ->
    $(window).scrollTop() > $(document).height() - $(window).height() - 10

  call_ajax = (page, request) ->
    url = '/reports/fvs_service_verifications/search'
    not_is_report = $('#index-fvs-preenchimento').data('no-report')
    url = '/fvs_preenchimentos/search' if not_is_report
    $.ajax
      type: 'post'
      url: url
      dataType: "script"
      data:
        page: page
        request: request
