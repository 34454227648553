import translations from "../../../../translations"
import * as I18n from "../../../../services/i18n"

export default class MultisitesSteps
  constructor: ->
    @form = $('#form_step_lightbox form')[0]

  handler_events: ->
    handle_onclick_edit_button.call(@)

  handle_onclick_edit_button = ->
    form = @form
    $('.multisites-edit-step').on 'click', ->
      Error.clear_global_errors()
      step_data = $(@).data('step')
      modal_title = $('#form_step_lightbox .modal-title')[0]
      modal_title.textContent = I18n.t(translations(), 'plannings.steps.form.edit_title')
      form.action = "/plannings/steps/#{$(@).data('id')}"
      form.method = "PUT"
      $('#js_planning_step_name').val(step_data.name)
      $('#js_planning_step_start_at').val(step_data.start_at)
      $('#js_planning_step_finish_at').val(step_data.finish_at)
      $('#js_planning_step_observation').val(step_data.observation)

  save_step: (id, name) ->
    $('#edit_step_lightbox').modal('toggle')
    $(".multisites-step-title-#{id}").html(name)
    $(".multisites-step-start_at-#{id}").html('10/09/2020')
    $(".multisites-step-finish_at-#{id}").html('10/08/2036')
    $(".multisites-step-observation-#{id}").html('Ta ruim')
