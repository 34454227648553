import "select2"
import ImagePreview from "./utils/image_preview.coffee"
import StantDatepicker from "./utils/stant_datepicker.coffee"
import StantTreeview from "./utils/stant_treeview.coffee"
import StantMenu from "./utils/stant_menu.coffee"
import StantSteps from "./utils/stant_steps.coffee"
import StantMasks from "./utils/stant_masks.coffee"
import AjaxLoader from "./utils/ajax_loader.coffee"

import translations from "translations"
import * as I18n from "services/i18n"

import StantSortableListsComponent from "./utils/sortable-list/stant-sortable-lists-component.coffee"
import Error from "./utils/error.coffee"

$(document).on 'turbolinks:load', ->
  $('.select-with-select2').select2
    language: 'pt-BR',
    placeholder: I18n.t(translations(), 'multi_select.select_one_option'),
    allowClear: true

  $('.select2-without-searchbox').select2
    language: 'pt-BR',
    placeholder: I18n.t(translations(), 'multi_select.select_one_option'),
    allowClear: true,
    minimumResultsForSearch: Infinity

  # Search
  $('#amount_page').on "change", ->
    quantity = $('#amount_page').val()
    url = window.location.pathname
    window.location.href = url + '?limit=' + quantity
  
  $('#person_type').on "change", ->
    type = $('#person_type').val()
    url = window.location.pathname
    window.location.href = url + '?type=' + type

  # Image Preview
  new ImagePreview().init()

  # Datepicker
  StantDatepicker.init()

  # Menu
  new StantMenu().init()

  # Steps
  new StantSteps().init()

  # Masks
  new StantMasks().init()

  Error.fade_out_alert_flash_msg()
  new AjaxLoader().show_and_build_loader()

  #SortableList
  sortable_list = new StantSortableListsComponent()
  sortable_list.add_listeners()

$(document).on 'turbolinks:before-cache', ->
  $('.select-with-select2').select2('destroy')
  #Remove pace if some error occurred
  $('.pace.pace-active').hide()
