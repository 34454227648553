export default class FixedCostCreation
  handler_events: ->
    handle_create_fixed_cost()

  handle_create_fixed_cost = ->
    $('#form_fixed_cost_lightbox').on 'hide', ->
      $('#js_fixed_cost_name').val('')
      $('#js_fixed_cost_description').val('')
      $('#js_entry_description').val('')
      $('#js_entry_movement_at').val('')
      $('input[type=radio]').prop('checked', false)
      $('#js_entry_value').val('')
      $('#js_entry_quantity').val('')
      $('#js_total_element').val('')
      $('#js_entry_type').val('')