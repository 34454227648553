export default class PrintChart
  constructor: () ->
    window.activeBeforePrint = true
    @elementsToHideOnPrint = $('aside, header.top, .not-show-in-pdf, .flash')
    @chartDiv = $('.gantt-charts')
    add_print_events(@elementsToHideOnPrint, @chartDiv)

  add_print_events = (elementsToHideOnPrint, chartDiv) ->
    before_print = ->
      if window.activeBeforePrint
        elementsToHideOnPrint.hide()
        @defaultHeight = chartDiv.css('height')
        @defaultWidth = chartDiv.css('width')

        chartDiv.css('height','2000px').css('width','1500px')

        setTimeout ->
          if gantt_report then gantt_report.draw_charts()
        , 120
      window.activeBeforePrint = true
    
    after_print = ->
      elementsToHideOnPrint.show()
      chartDiv.css('height', @defaultHeight).css('width', @defaultWidth)
      setTimeout ->
        if gantt_report then gantt_report.draw_charts()
      , 600
    
    window.onbeforeprint = before_print
    window.onafterprint = after_print

  activate: ->
    elementsToHideOnPrint = @elementsToHideOnPrint
    $('.container').on 'click', '#print-gantt', (e)->
      e.preventDefault()
      window.onbeforeprint()

      setTimeout ->
        @activeBeforePrint = false
        window.print()
      , 200
