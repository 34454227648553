import ptBR from '../../app/assets/images/pt-BR.svg'
import en from '../../app/assets/images/en.svg'
import es from '../../app/assets/images/es.svg'

const icons = {
  'pt-BR': ptBR,
  en,
  es
}

function isLanguageSelected(selectedLanguage, languageOption) {
  return selectedLanguage === languageOption ? 'select2-result-checked' : null
}

export function formatSelection(option) {

  return `
  <div class='option-select-language'>
    <div>${option.text}</div>
  </div>
`}

export function formatResult(option) {
  const selectedLanguage = $('.select-language').select2('data').id

  return `
  <div class='option-select-language ${isLanguageSelected(selectedLanguage, option.id)}'>
    <div>${option.text}</div>
    <div class="checkbox"></div>
  </div>
`}