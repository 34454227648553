import Error from './error.coffee'
import translations from 'translations'
import * as I18n from 'services/i18n'
import L from 'leaflet'

export default class StantCepSearch
  constructor: (@cep_input, @endereco_input, @bairro_input, @cidade_input, @estado_input, @number_input) ->
    @cep_input        ||= '#cep_input_id'
    @endereco_input   ||= '#endereco_id'
    @bairro_input     ||= '#bairro_id'
    @cidade_input     ||= '#cidade_id'
    @estado_input     ||= '#estado_id'
    @latitude_input   ||= '#latitude_id'
    @longitude_input  ||= '#longitude_id'
    @number_input     ||= '#obra_obra_numero'

  add_input_listener: (input, button) ->
    self = @
    $(input).on 'keyup', ->
      cep_text = $(input).val()
      if (cep_text.indexOf('_') == -1 ) 
        $(button).removeClass("disabled")
      else
        $(button).addClass('disabled')
    $(input).on 'change', ->
      cep_text = $(input).val()
      $(input).prop('value', cep_text)
      if (cep_text == '') 
        $(button).addClass('disabled')
      else if (cep_text.indexOf('_') == -1 ) 
        $(button).removeClass('disabled')
    $(input).on 'blur', ->
      cep_text = $(input).val()
      if (cep_text == '') 
        $(button).addClass('disabled')
    return this

  add_listener: (listener, render_map=false) ->
    self = @
    $(listener).on 'click', ->
      $cepInput = $(self.cep_input)
      if $cepInput.length
        cep = $cepInput.val()
        number = $(self.number_input).val()
        url = $(@).data("url")
        $.getJSON(url, {cep: cep, number: number})
          .done((data) ->
            Error.clear_global_errors()
            $(self.endereco_input).val data.logradouro
            $(self.bairro_input).val data.bairro
            $(self.cidade_input).val data.cidade
            $(self.estado_input).val data.uf
            $(self.latitude_input).val data.latitude
            $(self.longitude_input).val data.longitude
            if render_map
              makeMap(data.latitude, data.longitude)
          )
          .fail((jqxhr, textStatus, error) ->
            Error.clear_global_errors()
            if (jqxhr.status == 500) 
              new Error("cep", I18n.t(translations(), 'activerecord.errors.obra.invalid_zip_code')).show()
             else 
               new Error("cep", jqxhr.responseJSON.error).show()
          )
    return this

  makeMap = (latitude, longitude) ->
    container = L.DomUtil.get('map')
    if container != null
      container._leaflet_id = null
    map = L.map('map').setView({ lon: longitude, lat: latitude }, 16)

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
    ).addTo(map)

    delete L.Icon.Default.prototype._getIconUrl

    L.Icon.Default.mergeOptions(
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png')
      iconUrl: require('leaflet/dist/images/marker-icon.png')
      shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    )

    L.control.scale().addTo(map)
    L.marker({ lon: longitude, lat: latitude }).addTo(map)