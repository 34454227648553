import DropzoneAdapter from "../../utils/dropzone_adapter.coffee"
import AjaxLoader from "../../utils/ajax_loader.coffee"
import translations from "../../translations"
import * as I18n from "../../services/i18n"


$(document).on 'turbolinks:load', ->
  dropzone_form   = $('#contracts-dropzone')
  dropzone_modal  = $('#dropzone-lightbox')

  contractStatus = $(".cc-card-contract .label-status")
  labelFinalized = I18n.t(translations(), 'contracts.index.status_enum.finished')

  for status in contractStatus
    if $(status).text() == labelFinalized
      $(status).closest('.cc-card-contract').css("background-color", "#e6e6e6");

  return if dropzone_form.length == 0
  new DropzoneAdapter(dropzone_form, dropzone_modal, $('#dropzone-preview-template'))
    .add_listeners()
  
  dropzone_form.on 'startSending', (event, data) ->
    new AjaxLoader().show()

  dropzone_form.on 'uploadComplete', (event, data) ->
    new AjaxLoader().hide()
    $('#current-uploads').text I18n.t(translations(), 'documents.documents.index.finished_uploads')

  dropzone_form.on 'uploadSuccess', (event, data, response) ->
    $.getScript(response.reload_url)

  dropzone_form.on 'uploadFailed', (event, data, response) ->
    if response.check_construction_site
      fail_msg = I18n.t(translations(), 'flash.construction_site_changed')
      Error.show_alert_flash_msg_fixed(fail_msg)