export default class EntitySection

  constructor: () ->
    @container_entity = '.container_entity'
    @selected_entity  = '.entity_select'
  

  # --- Public Methods ---

  add_listener: ->
    that = @
    $(@selected_entity).change ->
      verify_selected_value.call(that)

  # --- Private Methods ---

  get_selected_value = ->
    $(@selected_entity).val()

  verify_selected_value = ->
    if selected_value_is_empty.call(@)
      hide_entity.call(@)
    else
      show_entity.call(@)

  hide_entity = () ->
    $(@container_entity).hide()
    $('.generated-employers-div').hide()

  show_entity = () ->
    $(@container_entity).show()
    $('.generated-employers-div').show()

  selected_value_is_empty = ->
    get_selected_value.call(@) == ''

