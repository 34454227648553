import "../../../utils/ajax_loader.coffee"

window.MainCardListener = class MainCardListener
  constructor: ->
    @main_card_collapse = $('.main-card-collapse')

  add_listener: ->
    @main_card_collapse.off().on 'click', ->
      panel_collapse    = $(this).next '.collapse'
      sub_card_rendered = panel_collapse.data 'sub-card-rendered'
      call_ajax(panel_collapse) if sub_card_rendered == false

  call_ajax = (panel_collapse) ->
    new AjaxLoader().show()
    panel_collapse.data 'sub-card-rendered', true
    construction_site_id = panel_collapse.data 'construction-site-id'
    items_fvs_responses  = panel_collapse.data 'items-fvs-responses'
    siff_id              = panel_collapse.data 'siff-id'
    index                = panel_collapse.data 'index'

    $.ajax
      type: 'post'
      url: '/reports/fvs_service_verifications/build_sub_cards_of_items_fvs_responses'
      dataType: "script"
      data:
        siff_id: siff_id
        items_fvs_responses: items_fvs_responses,
        construction_site_id: construction_site_id,
        index: index

