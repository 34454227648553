import "javascripts/jquery-mask/jquery.mask.min";
import "javascripts/jquery-input-mask/jquery.inputmask"
import "javascripts/jquery-input-mask/jquery.inputmask.extensions"
import "javascripts/jquery-input-mask/jquery.inputmask.numeric.extensions"
import "javascripts/jquery-input-mask/jquery.inputmask.date.extensions"
import "jquery-maskmoney/src/jquery.maskMoney"
import translations from "../translations"
import * as I18n from "../services/i18n"

export default class StantMasks
  init: ->
    self = @

    $('.mask-integer').mask "0000000000000"

    $('.mask-money').each ->
      input = $(this)
      input.maskMoney(money_options())

    $('.mask-decimal').each ->
      input = $(this)
      input.maskMoney(decimal_options())

    $('.mask-decimal-three').each ->
      input = $(this)
      input.maskMoney(decimal_options(3))

    $('.mask-cep').each ->
      input = $(this)
      input.inputmask '99999-999'

    $('.mask-cpf').each ->
      input = $(this)
      input.inputmask '999.999.999-99'

    $('.mask-cnpj').each ->
      input = $(this)
      input.inputmask '99.999.999/9999-99'

    $('.mask-date-field').each ->
      input = $(this)
      input.inputmask 'date'

    phone_options = onKeyPress: (val, e, field, options) ->
      field.mask phone_mask.apply({}, arguments), options
      return

    $('.mask-phone').each ->
      input = $(this)
      input.mask phone_mask, phone_options

  phone_mask = (val) ->
    if val.replace(/\D/g, '').length == 11 then '(00) 0 0000-0000' else '(00) 0000-00009'

  set_money_mask: (input, allowZero=false) ->
    moneyOptions = money_options()
    moneyOptions.allowZero = allowZero

    input.maskMoney(moneyOptions)

  money_options = () ->
    delimiter = I18n.t(translations(), 'number.currency.format.delimiter')
    separator = I18n.t(translations(), 'number.currency.format.separator')
    unit      = I18n.t(translations(), 'number.currency.format.unit')

    options =
      prefix: unit,
      thousands: delimiter,
      decimal: separator

    mask_options options

  decimal_options = (precision = 2) ->
    delimiter = I18n.t(translations(), 'number.format.delimiter')
    separator = I18n.t(translations(), 'number.format.separator')

    options =
      thousands: delimiter,
      decimal: separator,
      precision: precision

    mask_options options

  mask_options = (options = {}) ->
    prefix:    options.prefix,
    thousands: options.thousands,
    decimal:   options.decimal,
    precision: options.precision

window.StantMasks = StantMasks